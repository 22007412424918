import { Tooltip } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Points from "../config/Points";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import UserContext from "../context/UserContext";
import ShoppingCartContext from "../context/ShoppingCartContext";
import { Logo } from "../atom/Logo";
import StoreSearchBar from "./StoreSearchBar";
import visibilityOpen from "../assets/icons/visibilityOpen.svg";
import visibilityClosed from "../assets/icons/visibilityClosed.svg";
import truck from "../assets/icons/truck.svg";
import cart from "../assets/icons/cart.svg";


const StoreLogo = styled(Logo)`
  margin: 1rem 0;

  @media ${Breakpoints.md} {
    margin: 1.25rem 0;
  }
`

const StorePoints = ({className}) => {
  const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext);
  const [visibility, setVisibility] = useState(shoppingCart.showPoints);

  const toggleVisibility = () => {
    setVisibility(!visibility);
    setShoppingCart({...shoppingCart, showPoints: !visibility});
  }

  const points = Points().filter((item) => {
    return (item.id === "Point" || item.id === "Vmc")
  })

  const PointsFormatUtil = (points) => {
    if(typeof points == "string") {
        points = parseInt(points);
    }
      points = points.toLocaleString("pt-BR", {maximumFractionDigits:2});

      return points;
  }

  const PointItem = styled.span`
    display: inline-flex;
    align-items: center;
    gap: .25rem;
    min-height: 1.5rem;

    &:before {
      display: inline-block;
      content: '';
      width: 1px;
      height: .75rem;
      background: var(--color-lightGray);
      margin-right: .75rem;
    }

    &:first-child:before {
      display: none;
    }

    strong {
      display: inline-block;
      min-width: 3.5rem;
    }
  `
  const Container = styled.div`
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    font-size: var(--font-size-sm);

    img {
      cursor: pointer;
    }
  `

  const pointsRender = () => {
    return points.map((item, index) => {
      return (
        <PointItem key={"pointArea_"+index}>
          {visibility && (
            <strong>{PointsFormatUtil(item.value)}</strong>
          )}
          {!visibility && (
            <strong>••••••</strong>
          )}
          <span>{item.name}</span>
        </PointItem>
      )
    })
  }

  return (
    <Container>
      {pointsRender()}
      <img src={(visibility ? visibilityOpen : visibilityClosed)} style={{verticalAlign: "middle"}} alt="" onClick={toggleVisibility} />
    </Container>
  )

  
}

const StoreUserBlock = ({className}) => {
  const {user} = useContext(UserContext);

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--color-black);

    & > div {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }

    .nameArea {
      font-size: var(--font-size-sm);
      gap: .25rem;

      strong {
        font-size: var(--font-size-md);
        color: var(--color-brand);
      }
    }

    .pointArea {
      align-items: center;
    }
  `

  return (
    <Container className={className}>
      <div className="nameArea">
        Olá, <strong>{user.member.Name}</strong>
      </div>
      <div className="pointArea">
        <StorePoints />
      </div>
    </Container>
  )
}

const StoreNav = ({className}) => {
  const { shoppingCart } = useContext(ShoppingCartContext);

  const NavBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0;
    align-self: stretch;
    align-items: stretch;
  `

  const NavLink = styled(Link)`
    transition: all .3s ease;
    display: inline-flex;
    align-items: center;
    border-left: 1px solid var(--color-lightGray);
    padding: 0 1rem;
    position: relative;

    span {
      display: inline-block;
      position: absolute;
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-bold);
      text-decoration: none;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
      background: var(--color-brand);
      border-radius: 99px;
      color: var(--color-white);
      border: 2px solid var(--color-white);
      transform: translate(.75rem, -.75rem);
    }

    &:hover {
      background-color: rgba(0,178,107,.1);
    }

    @media ${Breakpoints.md} {
      padding: 0 1.25rem;
    }
  `

  return (
    <NavBlock>
        <Tooltip title="Pedidos">
          <NavLink to="/loja-de-marketing/pedidos">
            <img src={truck} alt="" />
          </NavLink>
        </Tooltip>
        <Tooltip title="Carrinho">
          <NavLink to="/loja-de-marketing/carrinho">
            <img src={cart} alt="" />
            {(shoppingCart.quantity > 0) && (
              <span>{shoppingCart.quantity}</span>
            )}
          </NavLink>
        </Tooltip>
    </NavBlock> 
  )
}

const StoreHeader = () => {
  let StoreHeaderContainer = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 1.5rem;
    border-bottom: 1px solid var(--color-lightGray);
    align-items: center;
    gap: 1rem;

    .spacedChildren {
      margin: 1rem 0;
    }

    @media ${Breakpoints.max.xs} {
      .hideMobile {
        display: none;
      }
    }

    @media ${Breakpoints.md} {
      padding: 0 3rem;

      .spacedChildren {
        margin: 1.25rem 0;
      }
    }
  `

  return (
    <StoreHeaderContainer>
      <StoreLogo link="/loja-de-marketing" className="spacedChildren" />
      <StoreSearchBar className="spacedChildren hideMobile" />
      <StoreUserBlock className="spacedChildren hideMobile" />
      <StoreNav />
    </StoreHeaderContainer>
  );
};

export default StoreHeader;
