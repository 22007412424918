import { ThemeProvider, createTheme } from "@mui/material";
import styled from "styled-components";

const MuiDiv = styled.div`
    .acceptCheckbox {
        .MuiFormControlLabel-label {
            font-weight: var(--font-weight-bold);
        }
        .MuiCheckbox-colorPrimary {
            color: var(--color-white);
        }
    }

    .acceptButton {
        transition: all .3s ease;
        display: inline-flex;
        padding: 0.75rem 3.75rem;
        border-radius: 0.25rem;
        background: var(--color-white);
        color: var(--color-black);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bold);
        font-style: normal;
        line-height: normal;
        text-transform: capitalize;

        &:hover {
            background: var(--color-xLightGray);
        }

        &[disabled] {
            opacity: .3;
        }
    }

    .button {
       text-transform: none;
       font-weight: var(--font-weight-bold); 
    }
`

const theme = createTheme({
    typography: {
        fontFamily: "var(--font-family)",
    },
    palette: {
        primary: {
            main: "#00B26B"
        }
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: ".85rem",
                    backgroundColor: "rgba(0,0,0,.9)"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    "&.MuiChip-medium": {
                        height: "1.75rem"
                    },
                    "&.MuiChip-filled": {
                        backgroundColor: "var(--color-brand)",
                        color: "var(--color-white) !important",
                        borderRadius: "99px"
                    },
                    "&.MuiChip-filled > .MuiChip-label": {
                        color: "var(--color-white)",
                        fontWeight: "var(--font-weight-bold)"
                    },
                    "&.MuiChip-filled > .MuiChip-deleteIcon": {
                        fill: "var(--color-white)",
                    }
                }
                
            }
        }
    }
});

const MuiBlock = ({
    className, children, style
}) => {
    return(
        <MuiDiv className={className} style={style}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </MuiDiv>
    )
}

export default MuiBlock;