const PhoneNumberValidate = (value) => {
    let phoneFormat = new RegExp(/^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}\.[0-9]{4}$/);

    return phoneFormat.test(value);
}

const NumberExport = (value) => {
    return value.replace(/[^0-9]/g,'');
}

export { PhoneNumberValidate, NumberExport };