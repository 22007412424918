import { Box, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography, MenuItem, Alert, Snackbar, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PrepareFetch from "../helpers/PrepareFetch";
import UserContext from "../context/UserContext";
import PageContainer from "../components/PageContainer";
import Breakpoints from "../config/Breakpoints";
import StyledButton from "../atom/Button";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import Social from "../atom/Social";
import MuiBlock from "../atom/MuiCustoms";
import { ContactItem } from "../atom/ContactBlocks";
import ninaAvatar from "../assets/images/ninaAvatar.png";
import arrowRight from "../assets/icons/linkArrowRightWhite.svg";
import checkMark from "../assets/icons/checkmark.svg";

// TODO -> Adicionar link para politica de privacidade
// TODO -> Trocar cor da letra quando disabled

const FeedbackContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  color: var(--color-blueGrayDark);
  font-size: var(--font-size-sm);

  strong {
    font-size: var(--font-size-xl);
    color: var(--color-black);
  }

  img {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }
`

const TalkToUsForm = () => {
  const {user} = useContext(UserContext);
  const {register, control, formState: { errors }, handleSubmit} = useForm({mode: "onBlur"});
  const [otherSubject, setOtherSubject] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .message {
      p {
        margin: 0 0 .5rem;
      }
    }
  `

  // Trocar cor da letra quando disable
  const toggleSendButton = () => {
    if(disabled) {
      setDisabled(false);  
    } else {
      setDisabled(true);
    }
    
  }

  const subjectList = () => {
    return [
      {
        name: "Selecione",
        value: "",
        disabled: true,
        selected: true,
      },
      {
        name: "Pontuação",
        value: "Pontuação",
        disabled: false,
        selected: false,
      },
      {
        name: "Resgate",
        value: "Resgate",
        disabled: false,
        selected: false,
      },
      {
        name: "Cadastro",
        value: "Cadastro",
        disabled: false,
        selected: false,
      },
      {
        name: "Outros",
        value: "Outros",
        disabled: false,
        selected: false,
      },
    ]
  }

  const subjectListRender = () => {
    return subjectList().map((item, index) => 
      <MenuItem key={"t2u_option_" + index} value={item.value} selected={item.selected} disabled={item.disabled}>{item.name}</MenuItem>
    )
  }

  const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

  const sendEmail = (data) => {
    let sendData = {
      name: data.name,
      mailFrom: data.mailFrom,
      message: data.message,
      subject: data.subjectList === "Outros" ? data.subjectOther : data.subjectList
    };

    const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/contactUs","POST",{
      "Content-Type": "application/json"
    },sendData);

    setLoading(true);
    setError(false);
    
    try {
        fetch(fetchData.url, fetchData.options)
        .then(response => {
            if(response.status === 200) {
              setLoading(false);
              setSuccess(true);
            }

            throw new Error(response);
        })
        .catch(error => {
            setLoading(false);
            setError(true);

            console.log("Erro ao enviar mensagem");
            if(process.env.VERCEL_ENV !== "production") {
                console.log(error);
            }
        });
    } catch (error) {
      setLoading(false);
      setError(true);

      console.log("Erro ao enviar mensagem");
      if(process.env.VERCEL_ENV !== "production") {
          console.log(error);
      }
    }
  };

  return(
    <MuiBlock>
      <form onSubmit={handleSubmit(sendEmail)}>
        <FormContainer>
          <div className="message">
            <p>Entre em contato se você tem alguma dúvida, sugestão ou reclamação sobre o Intelbras Pontua.</p>
            <p><strong>Nossa equipe lhe retornará em até 3 dias úteis.</strong></p>
          </div>

          <Controller
            name="subjectList"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value} }) => (
              <TextField
                label="Assunto"
                value={value}
                onChange={onChange}
                onBlur={(e) => {
                  setOtherSubject((e.target.value === "Outros"))
                  onBlur(e.target.value)
                }}
                fullWidth
                variant="outlined"
                error={!!errors.subjectList}
                select
              >
                {subjectListRender()}
              </TextField>
            )}
            defaultValue={""}
          />

          {otherSubject && (
            <TextField
              name="subjectOther"
              {...register('subjectOther', {required: true})}
              label="Digite o assunto"
              fullWidth
              variant="outlined"
              error={!!errors.subjectOther}
            />
          )}

          <TextField
            name="name"
            {...register('name', {required: true})}
            label="Seu nome completo"
            fullWidth
            variant="outlined"
            error={!!errors.name}
            defaultValue={user.member?.Name}
          />
          
          <TextField
            name="mailFrom"
            {...register('mailFrom', {required: true, validate: (value) => isValidEmail(value)})}
            label="Seu e-mail"
            type="email"
            fullWidth
            variant="outlined"
            error={!!errors.mailFrom}
          />

          <TextField
            name="message"
            {...register('message', {required: true})}
            label="Mensagem"
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            error={!!errors.message}
          />
        </FormContainer>

        <FormGroup sx={{
          marginTop: "1.5rem"
        }}>
          <FormControlLabel
            sx={{
              alignItems: "flex-start"
            }}
            control={<Checkbox onChange={toggleSendButton} />}
            label={
              <Typography
                color="var(--color-black)"
                fontSize="0.875rem"
                marginTop=".5rem"
              >
                Concordo que a Intelbras use meus dados pessoais, de contato e minhas interações para me mandar comunicações de marketing por e-mail, SMS, WhatsApp e telefone. 
              </Typography>
            }
          />
        </FormGroup>

        <Stack direction="row" paddingLeft="2rem" marginTop=".5rem">
          <Typography
            color="var(--color-gray)"
            fontSize="var(--font-size-xs)"
            lineHeight="var(--font-size-lg)"
          >
            Seu consentimento para este fim é voluntário e você é livre para retirá-lo a qualquer momento. Mais informações disponíveis na <Link to="/politica-de-privacidade" style={{color: "var(--color-brand", fontWeight: "var(--font-weight-bold)", textDecoration: "none"}}>Política de Privacidade</Link>.
          </Typography>
        </Stack>

        <Box position="relative" display="inline-block" alignItems="center" paddingTop="1.5rem" spacing="0.5rem">
          { !loading && (
            <StyledButton 
              disabled={disabled}
              type="submit"
            >
              Enviar mensagem
              <img src={arrowRight} alt="" />
            </StyledButton>
          )}
          { loading && (
              <StyledButton 
                disabled={true}
                type="submit"
              >
                Enviando
                <CircularProgress
                  size={24}
                  sx={{
                      color: "var(--color-white)",
                  }}
              />
              </StyledButton>
          )}
          <Dialog
            open={success}
            onClose={() => setSuccess(false)}
          >
            <FeedbackContent>
              <img src={checkMark} alt="" />
              <strong>Mensagem enviada com sucesso!</strong>
              <p>Nossa equipe lhe retornará em até 3 dias úteis.</p>
              <StyledButton className="small" sx={{marginTop: "1rem"}} onClick={() => setSuccess(false)}>
                Fechar
              </StyledButton>
            </FeedbackContent>
          </Dialog>
          { error && (
              <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                  <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                      Houve um problema ao enviar mensagem.
                  </Alert>
              </Snackbar>
          )}
        </Box>
      </form>
    </MuiBlock>
  );
};

const TalkToUsContactItem = styled(ContactItem)`
  padding: 2.5rem 0 !important;
  align-items: center !important;
  background: var(--color-xLightGray);
  border-radius: .5rem;
  border-bottom: 0 !important;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  flex-grow: 1;

  a {
    width: fit-content;
  }
`

const TalkToUsSocial = styled(Social)`
  padding: 2.5rem 0 !important;
  flex-direction: column !important;
  background: var(--color-xLightGray);
  align-items: center;
  color: var(--color-black) !important;
  font-size: var(--font-size-md);
  width: 100%;

  img {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
`

const TalkToUsAside = styled.div`
  @media ${Breakpoints.md} {
    margin-top: 6.25rem;
  }
`

const TalkToUs = () => {
  return (
    <PageContainer pageTitle={"Fale conosco | "+ process.env.REACT_APP_SITE_NAME}>
      <PageHeader>
        <span className="onlyDesktop">Fale conosco</span>
        <span className="onlyMobile">
          <small>Intelbras Pontua</small>
          Entre em contato conosco
        </span>
      </PageHeader>
      <PageContent>
        <div className="contentArea">
          <TalkToUsForm />
        </div>
        <TalkToUsAside className="asideArea">
          <TalkToUsContactItem toggleChatbot={true} image={ninaAvatar} headline="Fale com a Nina,">
              a assistente virtual da Intelbras
          </TalkToUsContactItem>
          <TalkToUsSocial />
        </TalkToUsAside>
      </PageContent>
    </PageContainer>
  );
};

export default TalkToUs;
