import { useContext } from "react";
import UserContext from "../context/UserContext";

const CheckPermission = (permission) => {
    const {user} = useContext(UserContext);
    
    if(typeof permission === "object" && permission.length > 0) {
        let permissionSet = new Set(permission);
        let arrayMerge = user.features.filter(item => permissionSet.has(item));

        return arrayMerge.length > 0;
    } else {
        return user.features.includes(permission);
    }
    
    
}

export default CheckPermission;