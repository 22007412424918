import PrepareFetch from "../helpers/PrepareFetch";

const UserContextUpdate = async (callback) => {
    let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/members/me","GET");

    await fetch(fetchData.url, fetchData.options)
            .then(response => {
                if(response.status === 200) {
                    return response.json();
                }

                throw new Error(response);
            })
            .then(json => {
                callback.apply(null, [json]);
            })
            .catch(error => {
                console.log("Erro ao atualizar os dados da seção");
            });
}

export default UserContextUpdate;