import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "styled-components";
import carouselArrow from "../assets/icons/carouselArrow.svg";

const HightlightCarouselBlock = styled.div`
    flex-grow: 1;
`

const HightlightCarouselItemBlock = styled(Link)`
    display: block;
    overflow: hidden;
    border-radius: .5rem;
`

const CustomCarousel = styled(Carousel)`
    & > .carousel.carousel-slider {
        display: grid;
        grid-template-areas: 
            "slider slider slider"
            "arrowLeft indicators arrowRight";
        grid-template-columns: 1.5rem 1fr 1.5rem;
        row-gap: 1rem;

        .control-dots {
            position: relative;
            grid-area: indicators;
            margin: 0;
        }

        .dot {
            transition: .3s all ease;
            box-shadow: none;
            background: none;
            border: 2px solid var(--color-brand);
            width: .5rem;
            height: .5rem;
            opacity: 1;
            border-radius: .5rem;
            margin: 0 .25rem;

            &.selected {
                width: 1rem;
                background: var(--color-brand);
            }
        }

        .slider-wrapper {
            grid-area: slider;

            img {
                border-radius: .5rem;
            }
            
        }

        .carouselPrevious {
            grid-area: arrowLeft;
        }

        .carouselNext {
            grid-area: arrowRight;
        }

        .carouselPrevious,
        .carouselNext {
            width: 1.5rem;
        }
    }


    .carouselPrevious,
    .carouselNext {
        border: none;
        background: none;
        bottom: 10px;
        cursor: pointer;
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        z-index: 2;

        img {
            pointer-events: none;

            &.is-hidden {
                opacity: .2;
            }
        }
    }

    .carouselPrevious {
        left: 0;

        img {
            transform-origin: center;
            transform: rotate(180deg);
        }
    }

    .carouselNext {
        right: 0;
    }
`

const CustomProductCarousel = styled(Carousel)`
    & > .carousel.carousel-slider {
        .slider-wrapper {
            grid-area: slider;

            img {
                border-radius: .5rem;
                width: auto;
                max-width: 100%;
            }
            
        }
    }

    & > .carousel {
        .thumbs-wrapper {
            margin: 0;
        }

        .thumb {
            border: 1px solid rgba(23,49,52,.1);
            border-radius: .25rem;
            overflow: hidden;

            &:hover {
                border: 1px solid var(--color-brand);
            }

            &.selected {
                border: 1px solid var(--color-brand);
                box-shadow: 0 6px 0 -3px var(--color-brand);
                border-bottom-left-radius: .5rem;
                border-bottom-right-radius: .5rem;
            }

            img {
                aspect-ratio: 1/1;
            }
        }
    }
`

const HighlightCarousel = ({items, ratio}) => {
    if(typeof ratio === "undefined") {
        ratio = "16/10"
    }
    let CarouselItemRender = () => {
        return items.map(
                (item, index) => {
                    return (
                        <div key={"carousel_" + index}>
                            {item.link !== null && (
                                <HightlightCarouselItemBlock to={item.link}>
                                    <img src={item.image} alt={item.text} style={{aspectRatio: ratio}} />
                                </HightlightCarouselItemBlock>
                            )}
                            {item.link === null && (
                                <img src={item.image} alt={item.text} style={{aspectRatio: ratio}} />
                            )}
                        </div>
                    )    
                }
        )
    }

    let arrowRender = (clickHandler, visible, label, className) => {
        return (
            <span onClick={clickHandler} className={className}>
                <img src={carouselArrow} alt={label} className={(visible ? "is-visible" : "is-hidden")} />
            </span> 
        )
    }

    let arrowPrev = (clickHandler, visible, label) => {
        return arrowRender(clickHandler, visible, label, "carouselPrevious");
    }

    let arrowNext = (clickHandler, visible, label) => {
        return arrowRender(clickHandler, visible, label, "carouselNext");
    }

    return (
        <HightlightCarouselBlock>
            <CustomCarousel
                showStatus={false}
                showThumbs={false}
                renderArrowPrev={arrowPrev}
                renderArrowNext={arrowNext}
            >
                {CarouselItemRender()}
            </CustomCarousel>
        </HightlightCarouselBlock>
    )
}

const ProductCarousel = ({items}) => {
    let CarouselItemRender = () => {
        return items.map(
                (item, index) => {
                    return (
                        <div key={"carousel_" + index}>
                            <img src={item.url} alt="" />
                        </div>
                    )    
                }
        )
    }

    return (
        <HightlightCarouselBlock>
            <CustomProductCarousel
                showStatus={false}
                showThumbs={true}
                showIndicators={false}
            >
                {CarouselItemRender()}
            </CustomProductCarousel>
        </HightlightCarouselBlock>
    )
}

export { HighlightCarousel, ProductCarousel};