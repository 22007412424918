import styled from "styled-components";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import Breakpoints from "../config/Breakpoints";
import userImg from "../assets/icons/userProfile.svg";

const BlockUser = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  line-height: 1.25rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .userName {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  span {
    font-size: var(--font-size-sm);
    color: var(--color-blueGrayDark);
  }

  strong {
    font-size: var(--font-size-lg);
    color: var(--black);
  }

  @media ${Breakpoints.md} {
    strong {
      font-size: var(--font-size-xl);
    }

    &.slim {
      img {
        display: none;
      }

      .userName {
        flex-direction: row;
        gap: .25rem;
        align-items: baseline;
      }

      strong {
        font-size: var(--font-size-lg);
      }
    }
  }
`

const UserBlock = ({className}) => {
  const {user} = useContext(UserContext);

  return (
      <BlockUser className={className}>
        <img src={userImg} alt="Ícone usuário" />
        <div className="userName">
          <span>Olá,</span>
          <strong>{user.member.Name}</strong>
        </div>
      </BlockUser>
  )
}

export default UserBlock;