const devices = {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1440,
    xxl: 1600,
}

const Breakpoints = {
    xs: `screen and (min-width: ${devices.xs}px)`,
    sm: `screen and (min-width: ${devices.sm}px)`,
    md: `screen and (min-width: ${devices.md}px)`,
    lg: `screen and (min-width: ${devices.lg}px)`,
    xl: `screen and (min-width: ${devices.xl}px)`,
    xxl: `screen and (min-width: ${devices.xxl}px)`,

    max: {
        xs: `screen and (max-width: ${devices.sm - 1}px)`,
        sm: `screen and (max-width: ${devices.md - 1}px)`,
        md: `screen and (max-width: ${devices.lg - 1}px)`,
        lg: `screen and (max-width: ${devices.xl - 1}px)`,
        xl: `screen and (max-width: ${devices.xxl - 1}px)`,
    },

    only: {
        xs: `screen and (max-width: ${devices.sm - 1}px)`,
        sm: `screen and ((min-width: ${devices.sm}px) and (max-width: ${devices.md - 1}px))`,
        md: `screen and ((min-width: ${devices.md}px) and (max-width: ${devices.lg - 1}px))`,
        lg: `screen and ((min-width: ${devices.lg}px) and (max-width: ${devices.xl - 1}px))`,
        xl: `screen and ((min-width: ${devices.xl}px) and (max-width: ${devices.xxl - 1}px))`,
        xxl: `screen and (min-width: ${devices.xxl}px)`,
    }
}

export default Breakpoints;