import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import bgSquares from  "../assets/images/bg-squares--right-multiply.webp";

const PageHeader = ({className, children}) => {
    const PageHeaderBlock = styled.h1`
        display: flex;
        flex-direction: column;
        margin: 0 0 2.5rem 0;
        padding: 1.5rem;
        background: var(--color-gradient) no-repeat;
        background-size: 71% auto;
        
        color: var(--color-brand);
        font-size: var(--font-size-xxl);
        font-weight: var(--font-weight-bold);
        line-height: 1em;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            height: 100%;
            background: #DAE2E8 url(${bgSquares}) top right no-repeat;
            background-blend-mode: multiply;
            background-size: auto 100%;
            z-index: 1;
        }

        span {
            position: relative;
            z-index: 2;
        }
        
        small {
            display: block;
            font-weight: var(--font-weight-regular);
            font-size: var(--font-size-sm);
            color: var(--color-blueGrayDark);
            line-height: var(--font-size-lg);
        }

        .onlyDesktop {
            display: none;
        }

        @media ${Breakpoints.md} {
            margin: 0 3rem 2.5rem;
            padding: 3rem;
            font-size: 1.75rem;
            border-radius: .5rem;

            .onlyMobile {
                display: none;
            }
            .onlyDesktop {
                display: block;
            }
        }

        @media ${Breakpoints.lg} {
            padding: 3rem 4.5rem;
        }
    `

    return (
        <PageHeaderBlock className={className}>
           <span>{children}</span>
        </PageHeaderBlock>
    )
}

export default PageHeader;