function ParseApexError(err, classException, genericMessage) {
    err = err + '';
    const errorObject = JSON.parse(err.replace('Error: ',''));
    let rtn = genericMessage;

    if(errorObject[0].message !== '') {
        rtn = errorObject[0].message.split('\n\n')[0];
        if(classException) {
            rtn = rtn.replace(`${classException}: `, '');
        }
    }

    return rtn;
}

export default ParseApexError;