import styled from "styled-components";
import { Skeleton } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PrepareFetch from "../helpers/PrepareFetch";
import PointsFormatUtil from "../helpers/PointFormatUtil";
import DateFormatUtil from "../helpers/DateFormatUtil";
import Breakpoints from "../config/Breakpoints";

import Breadcrumb from "../atom/Breadcrumb";
import StoreResumeCard from '../atom/StoreResumeCard';
import RedemptionStatus from '../atom/RedemptionStatus';
import { RedemptionCouponInfo } from "./ShoppingCartResumeCoupon";

import alertSkeleton from "../assets/icons/alertSkeleton.svg";
import noImage from "../assets/images/store-noPicture.png";

const RedemptionContext = ({className, container}) => {
    let [label, setLabel] = useState("");

    useEffect(() => {
        switch(container) {
            case "LOJA_MARKETING":
                setLabel("Loja de Marketing");
                break;
            case "PREMMIAR":
                setLabel("Catálogo de prêmios");
                break;
            case "CARTAO_CREDITO":
                setLabel("Cartão de Crédito Intelbras Pontua");
                break;
            default:
                setLabel("");
                break;
        }
    },[container])
    

    const Container = styled.div`
        display: inline-flex;
        padding: .5rem 1rem;
        border-radius: 99px;
        width: fit-content;
        background: var(--color-blueGrayLight);
        color: var(--color-blueGrayDark);
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-regular);
    `

    return (
        <>
            {label !== "" && (
                <Container className={className}>{label}</Container>
            )}
        </>
    )
}

const RedemptionBlockItem = ({className,id,context}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [trackingNumbers, setTrackingNumbers] = useState([]);
    const linkBase = context === "store" ? "/loja-de-marketing" : "";
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {
            href: context === "store" ? "/loja-de-marketing" : "/"
        },
        {
            name: "Pedidos",
            href: context === "store" ? "/loja-de-marketing/pedidos" : "/pedidos"
        }
    ]);
    const [ couponData, setCouponData ] = useState({
        originalTotal: 0,
        applied: false,
        hasAnyAppliedCoupon: false,
        couponId: "",
        couponInfo: "",
        couponType: "",
        couponTotalDiscount: 0,
        couponCashback: "",
    });

    const handleData = (data) => {
        let trackingList = [];
        let discountTotal = 0;
        let hasAnyAppliedCoupon = false;
        let originalValue = 0;

        if(typeof data.items !== "undefined" && data.items.length > 0) {
            data.items.forEach(item => {
                console.log(item.codigoRastreio);
                originalValue = originalValue + item.originalValue;
                if(item.codigoRastreio !== null && trackingList.indexOf(item.codigoRastreio) === -1) {
                    trackingList.push(item.codigoRastreio);
                }
                if(item.originalValue > item.value) {
                    discountTotal = discountTotal + (item.originalValue - item.value);
                    hasAnyAppliedCoupon = true;
                }
            });
        }

        setData(prev => data);
        setTrackingNumbers(prev => trackingList);
        if(data.coupon !== null) {
            setCouponData({
                originalTotal: originalValue,
                applied: data.coupon !== null,
                hasAnyAppliedCoupon: hasAnyAppliedCoupon,
                couponId: data.coupon.couponId,
                couponInfo: data.coupon.couponInfo,
                couponType: data.coupon.couponType === 'Desconto' ? 'discount' : 'cashback',
                couponCashback: data.coupon.couponCashback,
                couponTotalDiscount: discountTotal
            })
        }
    };

    useEffect(() => {
        setLoading(true);
        setError(false);
        if(typeof id === "undefined") {
            navigate(`${linkBase}/pedidos`);
        }

        (async () => {
            let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/redemptions/${id}`,"GET",{
                "cache-by": "user"
            });

            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                          return response.json();
                        }
      
                        throw new Error(response);
                    })
                    .then(json => {
                        if(json.length === 0) {
                          navigate(`${linkBase}/pedidos`)
                        } else {
                          setLoading(false);
                          setBreadcrumbItems((prevItems, props) => {
                            return [
                                ...prevItems,
                                {
                                    name: `Pedido ${json[0].name}`
                                }
                            ]
                          })
                          handleData(json[0]);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
        })();
        // eslint-disable-next-line
    },[id,linkBase])

    const Container = styled.section`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;

        .columnsContainer {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @media ${Breakpoints.md} {
                flex-direction: row;
            }
        }

        .columnContent {
            @media ${Breakpoints.md} {
                flex: 1 1 0;
            }
        }

        .columnResume {
            @media ${Breakpoints.md} {
                width: 26.85rem;
            }
        }

        .columnContent, .columnResume {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .title {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            min-height: 2.25rem;
        }

        .redemptionContainer {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 2rem 1.5rem;
            border-radius: .5rem;
            border: 1px solid var(--color-lightGray);

            hr {
                height: 0;
                width: 100%;
                border: none;
                border-bottom: 1px solid var(--color-lightGray);
            }
        }

        .redemptionProductItem {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            font-size: var(--font-size-sm);
            color: var(--color-black);

            .productInfo {
                flex: 1 1 0;
                display: flex;
                flex-direction: column;
                gap: 0;
            }

            .thumb {
                width: 4.75rem;
            }

            .productName {
                font-size: var(--font-size-md);
            }

            .productInfo__originalValue {
                opacity: .5;
        
                em {
                    text-decoration: line-through;
                    font-style: normal;
                    font-size: .875rem;
                }
            }

            @media ${Breakpoints.md} {
                flex-direction: row;
                align-items: center;
            }
        }

        .status {
            display: flex;
            background: none;
            align-items: center;
            gap: 1.5rem;
            padding: 0;

            &:last-child {
                align-items: flex-start;
            }

            a {
                color: var(--color-brand);
                text-decoration: none;
                font-weight: var(--font-weight-bold);
            }

            & > span {
                color: var(--color-black);
                font-weight: var(--font-weight-regular);
                font-size: var(--font-size-sm);

                & > strong {
                    display: block;
                }
            }

            .icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 99px;
                background: var(--color-xLightGray);
                width: 2.5rem;
                height: 2.5rem;
                margin: 0 1.125rem;

                img {
                    width: 1.5rem;
                }
            }
        }

        .statusContainer {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .status:not(:first-child) {
                .icon {
                    position: relative;

                    &:before {
                        display: block;
                        position: absolute;
                        content: '';
                        width: 1px;
                        height: 1rem;
                        background: var(--color-lightGray);
                        bottom: calc(100% + .5rem);
                        left: calc(50% - 1px);
                    }
                }
            }
        }

        .warningBlock {
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            padding: 1.5rem;
            color: var(--color-blueGrayDark);
            align-items: center;
            border-radius: .5rem;
            border: 1px solid var(--color-lightGray);
        }
    `

    return (
        <Container>
            {loading && (
                <Skeleton sx={{width: "60%"}}/>
            )}
            {!loading && (
                <Breadcrumb items={breadcrumbItems} />
            )}

            <div className="columnsContainer">
                {loading && (
                    <>
                        <Skeleton
                            sx={{
                                aspectRatio: "2/1",
                                borderRadius: ".5rem",
                                height: "auto",
                                width: "70%"
                            }}
                            variant="rectangular"
                            width="70%"
                        />
                        <Skeleton
                            sx={{
                                aspectRatio: "2/1",
                                borderRadius: ".5rem",
                                height: "auto",
                                width: "30%"
                            }}
                            variant="rectangular"
                            width="30%"
                        />
                    </>
                )}
                {!loading && (
                    <>
                        <div className="columnContent">
                            <h2 className="mainTitle title">
                                <span>Pedido {data.name}</span>
                                {data.container !== "" && !context && (
                                    <RedemptionContext container={data.container} />
                                )}
                            </h2>
                            <div className="redemptionContainer">
                                {data.items.map((item) => {
                                    return (
                                        <div key={`rewardItem_${item.rewardId}`} className="redemptionProductItem">
                                            <img src={item.image !== null ? item.image : noImage} className="thumb" alt="" />
                                            <div className="productInfo">
                                                <strong className="productName">{item.name}</strong>
                                                <div>
                                                    Quantidade: <strong>{item.quantity}</strong>
                                                </div>
                                                <div>
                                                    Valor: &#160;
                                                    {item.originalValue > item.value && (
                                                    <>
                                                        <strong className="productInfo__originalValue"><span>de</span> <em>{PointsFormatUtil(item.originalValue)}</em></strong>
                                                        &#160;
                                                        <strong><span>por</span> {PointsFormatUtil(item.value)} <span>pontos</span></strong>
                                                    </>
                                                    )}
                                                    &#160;
                                                    {item.originalValue === item.value && (
                                                        <strong>{PointsFormatUtil(item.value)} <span>pontos</span></strong>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <hr/>
                                <div className="statusContainer">
                                    <RedemptionStatus className="status" status={data.status}>
                                        em {DateFormatUtil(data.closedDate)}
                                    </RedemptionStatus>
                                    {trackingNumbers.length > 0 && (
                                        <RedemptionStatus className="status" status="Enviado">
                                            {trackingNumbers.length === 1 && (
                                                <>
                                                    Pedido enviado pelos Correios, confira o código de rastreamento: <br/>
                                                    <a href={`https://rastreamento.correios.com.br/app/index.php?objetos=${trackingNumbers[0]}`} target="_blank" rel="noreferrer">{trackingNumbers[0]}</a>
                                                </>
                                            )}
                                            {trackingNumbers.length > 1 && (
                                                <>
                                                Pedido enviado pelos Correios, confira os códigos de rastreamento:<br/>
                                                {trackingNumbers.map((item, index) => (
                                                    <>
                                                        <a href={`https://rastreamento.correios.com.br/app/index.php?objetos=${item}`} target="_blank" rel="noreferrer">{item}</a>
                                                        {index < trackingNumbers.length - 1 && (
                                                            <br/>
                                                        )}
                                                    </>
                                                ))}
                                                </>
                                            )}  
                                            <div style={{marginTop: ".25rem"}}>Para rastrear sua encomenda, acesse o <a href={`https://rastreamento.correios.com.br/app/index.php`} target="_blank" rel="noreferrer">site dos Correios</a>.</div> 
                                        </RedemptionStatus>
                                    )}
                                </div>
                                {data.address.street !== null && (
                                    <>
                                        <hr/>
                                        <div>
                                            <strong>Endereço</strong><br/>
                                            {data.address.street}, {data.address.streetNumber}
                                            {data.address.streetComplement !== "" ? ` - ${data.address.streetComplement} ` : ""}
                                            - {data.address.district} - {data.address.city}/{data.address.state}<br/>
                                            {data.address.postalCode}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="columnResume">
                            <h2 className="mainTitle title">
                                Sobre o pedido
                            </h2>
                            <StoreResumeCard style={{marginTop: "0"}}>
                                <div className="rowWrapper">
                                    <div className="row">
                                        <span>Resgate realizado em</span>
                                        <strong>{DateFormatUtil(data.closedDate)}</strong>
                                    </div>
                                </div>
                                <hr/>
                                {couponData.applied && (
                                    <RedemptionCouponInfo couponData={couponData} />
                                )}
                                <div className="rowWrapper">
                                    <div className="row">
                                        <span>Total</span>
                                        <strong className="super">{PointsFormatUtil(data.total)} pontos</strong>
                                    </div>
                                </div>
                            </StoreResumeCard>
                        </div>
                    </>
                )}
            </div>

            {error && (
                <div className="warningBlock">
                    <img src={alertSkeleton} alt="" />
                    Não foi possível carregar o conteúdo.
                </div>
            )}
        </Container>
    )
}

export default RedemptionBlockItem;
