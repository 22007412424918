const BirthDateValidate = (value) => {
    var dateformat = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/);
    
    return dateformat.test(value);
}

const BirthDateExport = (value) => {
    let v = value.split("/");

    return `${v[2]}-${v[1]}-${v[0]}`
}

export { BirthDateValidate, BirthDateExport }