import {useState, useEffect, useRef} from "react";
import {TextField, MenuItem, Skeleton, CircularProgress} from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Breakpoints from "../config/Breakpoints";
import MuiBlock from "../atom/MuiCustoms";
import StyledButton from "../atom/Button";
import ProductFacades from "../helpers/ProductFacades";
import PrepareFetch from "../helpers/PrepareFetch";
import ProductCard from "./ProductCard";
import ProductBreadcrumb from '../atom/ProductBreadcrumb';
import alertSkeleton from "../assets/icons/alertSkeleton.svg";

const StoreProductList = ({className, paramCategoryId}) => {
    // TODO: Fazer paginação infinita
    if(typeof paramCategoryId === "undefined") {
        paramCategoryId = null;
    }

    const ProductListContainer = styled.section`
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;

        .orderArea {
            display: flex;
            justify-content: stretch;

            .orderField {
                flex-grow: 1;
                min-width: 16rem;
            }

            @media ${Breakpoints.sm} {
                justify-content: flex-end;

                .orderField {
                    flex-grow: 0;
                }
            }
        }

        .cardArea {
            display: grid;
            gap: 1.5rem;

            @media ${Breakpoints.sm} {
                grid-template-columns: 1fr 1fr;
            }

            @media ${Breakpoints.md} {
                grid-template-columns: 1fr 1fr 1fr;
            }
            
            @media ${Breakpoints.lg} {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        .showMore {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
        }

        .warningBlock {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            height: auto;
            border-radius: .5rem;
            background: var(--color-lightGray);
            color: var(--color-gray);
            justify-content: center;
            align-items: center;
            aspect-ratio: 16/4;
        }

        .notFound {
            color: var(--color-blueGrayDark);
        }

        .productListContainer {
            display: grid;
            gap: 1.5rem;
            position: relative;

            @media ${Breakpoints.md} {
                grid-template-columns: 1fr 3fr;
            }
        }

        .filterArea {
            order: 2;
            border-radius: .5rem;
            border: 1px solid var(--color-brand);
            padding: 2rem 1.5rem;
            box-shadow: 0 6px 0 -3px var(--color-brand);

            strong {
                font-size: var(--font-size-lg);
                color: var(--color-black);
            }

            ul {
                margin: 1rem 0 0;
                padding: 0 0 0 1rem;
                font-size: var(--font-size-sm);

                li {
                    padding: .25rem .5rem;
                    border-radius: .25rem;
                    color: var(--color-brand);
                    font-weight: var(--font-weight-bold);
                    cursor: pointer;

                    &.selected {
                        background-color: rgba(0,178,107,.1);
                        color: var(--color-blueGrayDark);
                    }
                }
            }

            @media ${Breakpoints.md} {
                order: 1;
                max-width: 17rem;
                align-self: flex-start;
                position: sticky;
                top: 0;
            }
        }

        .productsArea {
            order: 1;
            flex: 1 1 0;

            @media ${Breakpoints.md} {
                order: 2;
            }
        }
    `

    const orderOptions = [
        {
            label: "Menos pontos primeiro",
            value: "value asc"
        },
        {
            label: "Mais pontos primeiro",
            value: "value desc"
        },
        {
            label: "Alfabética A-Z",
            value: "name asc"
        },
        {
            label: "Alfabética Z-A",
            value: "name desc"
        }
    ]

    const navigate = useNavigate();
    const productContainerRef = useRef(null);

    const [categoryLoading, setCategoryLoading] = useState(true);
    const [productLoading, setProductLoading] = useState(true);
    const [error, setError] = useState(false);
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [query, setQuery] = useState({
        page: 1,
        pageSize: 12,
        orderBy: orderOptions[0].value,
        categoryId: paramCategoryId,
        reset: true,
    });

    const [queryMeta, setQueryMeta] = useState({
        hasMoreItems: false,
    })

    const HandleOrderBy = (e) => {
        setQuery({
            ...query,
            orderBy: e.target.value,
            page: 1,
            reset: true
        })
    }

    const HandleCategory = (categoryId) => {
        if(paramCategoryId !== null) {
            if(categoryId === null) {
                navigate("/loja-de-marketing");
            } else {
                navigate(`/loja-de-marketing/categoria/${categoryId}`);
            }
        }

        window.scrollTo({
            top: productContainerRef.current.offsetTop,
            left: 0,
            behavior: "smooth",
        });

        setQuery({
            ...query,
            categoryId: categoryId,
            page: 1,
            reset: true
        })
    }

    const GetMoreData = () => {
        setQuery({
            ...query,
            page: query.page + 1,
            reset: false
        })
    }

    useEffect(() => {
        setProductLoading(true);

        let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/rewards?page=${query.page}&orderBy=${query.orderBy}&pageSize=${query.pageSize}${(query.categoryId !== null ? `&categoryId=${query.categoryId}` : '')}`,"GET",{
            "cache-by": "user"
        });
    
        (async () => {
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                            return response.json();
                        }

                        throw new Error(response.json());
                    })
                    .then(json => {
                        let queryUpdate = {
                            hasMoreItems: (json.length === query.pageSize)
                        }
                        if(query.reset) {
                            setProductList(ProductFacades(json));
                        } else {
                            setProductList(prevProducts => [...prevProducts, ...ProductFacades(json)]);
                        }
                        
                        setQueryMeta(actualQueryMeta => {
                            return {...actualQueryMeta, ...queryUpdate};
                        });
                        setProductLoading(false);
                    })
                    .catch(error => {
                        setProductLoading(false);
                        setError(true);
                        console.warn(error);
                    })
        })();
    },[query])

    useEffect(() => {
        setCategoryLoading(true);
        let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/rewards/category`,"GET",{
            "cache-by": "user"
        });

        (async () => {
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                            return response.json();
                        }

                        throw new Error(response);
                    })
                    .then(json => {
                        setCategoryList(json.map(item => {
                            if(paramCategoryId !== null && item.Id === paramCategoryId) {
                                setCategoryName(item.Name);
                            } 

                            return {
                                id: item.Id,
                                name: item.Name,
                                selected: false
                            }
                        }))

                        setCategoryLoading(false);
                    })
                    .catch(error => {
                        setCategoryLoading(false);
                        setError(true);
                    });
        })();
    },[paramCategoryId])

    return (
        <ProductListContainer className={className} ref={productContainerRef}>
            {error && (
                <div className="warningBlock">
                    <img src={alertSkeleton} alt="" />
                    Não foi possível carregar o conteúdo.
                </div>
            )}
            {!error && (
                <>
                    <MuiBlock className="orderArea">
                        {paramCategoryId !== null && (
                            <>
                                {categoryLoading && (
                                    <div style={{flex: "1 1 0"}}>
                                        <Skeleton
                                            sx={{
                                                borderRadius: ".5rem",
                                            }}
                                            width="50%"
                                        />
                                    </div>
                                )}
                                {!categoryLoading && (
                                    <ProductBreadcrumb productData={{name: categoryName}} style={{flex: "1 1 0"}} />
                                )}
                            </>
                        )}
                        <TextField
                            select
                            label="Ordenar por"
                            onChange={HandleOrderBy}
                            value={query.orderBy}
                            size="small"
                        >
                            {orderOptions.map((option, index) => (
                                <MenuItem key={"orderBy_"+index} value={option.value} sx={{display: "flex !important", paddingTop: ".5rem !important", paddingBottom: ".5rem !important"}}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </MuiBlock>
                    <div className="productListContainer">
                        {categoryLoading && (
                            <Skeleton
                                sx={{
                                    borderRadius: ".5rem",
                                    height: "20rem",
                                    maxWidth: "17rem"
                                }}
                                type="rectangular"
                                width="100%"
                            />
                        )}
                        {categoryList.length > 0 && (
                            <>
                                {!categoryLoading && (
                                    <div className="filterArea">
                                        <strong>Categorias</strong>
                                        <ul>
                                            <li className={(query.categoryId === null ? 'selected' : '')} onClick={() => HandleCategory(null)}>Todos os produtos</li>
                                            {categoryList.map(item => {
                                                return (
                                                    <li key={item.id} className={(query.categoryId === item.id ? 'selected' : '')} onClick={() => HandleCategory(item.id)}>{item.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="productsArea">
                            {productLoading && query.reset && (
                                <div className="cardArea">
                                    <Skeleton
                                        sx={{
                                            borderRadius: ".5rem",
                                            height: "20rem",
                                            width: "100%"
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                    />
                                    <Skeleton
                                        sx={{
                                            borderRadius: ".5rem",
                                            height: "20rem",
                                            width: "100%"
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                    />
                                    <Skeleton
                                        sx={{
                                            borderRadius: ".5rem",
                                            height: "20rem",
                                            width: "100%"
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                    />
                                    <Skeleton
                                        sx={{
                                            borderRadius: ".5rem",
                                            height: "20rem",
                                            width: "100%"
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                    />
                                </div>
                                
                            )}
                            {(!productLoading || (productLoading && !query.reset)) && (
                                <>
                                    {productList.length === 0 && (
                                        <div className="notFound">
                                            Não há produtos cadastrados.
                                        </div>
                                    )}
                                    {productList.length > 0 && (
                                        <>
                                            <div className="cardArea">
                                                {productList.map((item) => {
                                                    return (
                                                        <ProductCard key={item.id} id={item.id} images={item.images} name={item.name} value={item.value} />
                                                    )
                                                })}
                                            </div>
                                            {queryMeta.hasMoreItems && (
                                                <div className="showMore">
                                                    <StyledButton className="secondary small" disabled={(productLoading && !queryMeta.reset)} onClick={e => GetMoreData()}>
                                                        Mostrar mais
                                                        {(productLoading && !query.reset) && (
                                                            <CircularProgress
                                                                size={24}
                                                                sx={{
                                                                    color: "var(--color-brand)",
                                                                    position: "absolute",
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    margin: "-12px 0 0 -12px"
                                                                }}
                                                            />
                                                        )}
                                                    </StyledButton>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </ProductListContainer>
    )
}

export default StoreProductList;