import { Box } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../components/Footer";
import Header from "../components/Header";

const PageContainer = ({
  children,
  showHeader = true,
  showFooter = true,
  pageTitle = "Intelbras Pontua"
}) => {

  return (
    <>
      <HelmetProvider>
          <Helmet>
            <title>
              {pageTitle}
            </title>
          </Helmet>
      </HelmetProvider>

      {showHeader && <Header />}

      <Box component="main">{children}</Box>

      {showFooter && <Footer />}
    </>
  );
};

export default PageContainer;
