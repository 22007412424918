import PageContainer from "../components/PageContainer";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import FaqList from "../atom/Faq";

const Faq = () => {
  return (
    <PageContainer pageTitle={"Dúvidas frequentes | "+ process.env.REACT_APP_SITE_NAME}>
      <PageHeader>
        Dúvidas frequentes
      </PageHeader>
      <PageContent>
        <div className="contentArea full">
          <FaqList container="dúvidas frequentes" />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default Faq;
