import card1Img from "../assets/images/highlightBox-compras.webp";
import card1Img__vendas from "../assets/images/highlightBox-vendas.webp";
import card2Img from "../assets/images/highlightBox-materiaispromo.webp";
import card3Img from "../assets/images/highlightBox-abasteca.webp";
import card4Img from "../assets/images/highlightBox-paguecontas.webp";

const CardsData = [
    {
        image: card1Img,
        text: "Suas compras de produtos Intelbras valem pontos",
        action: "Acumule pontos",
        link: "/acumule-pontos",
        style: "text-right",
        permission: [
            "ACUMULE_PONTOS_REVENDAS",
            "ACUMULE_PONTOS_ASTEC",
            "ACUMULE_PONTOS_PROVEDORES",
            "ACUMULE_PONTOS_REVENDA_SOLUCOES"
        ]
    },
    {
        image: card1Img__vendas,
        text: "Suas vendas de produtos Intelbras valem pontos",
        action: "Acumule pontos",
        link: "/acumule-pontos",
        style: "text-right",
        permission: [
            "ACUMULE_PONTOS_VENDEDOR"
        ]
    },
    {
        image: card2Img,
        text: "Materiais promocionais para você e o seu negócio",
        action: "Loja de Marketing",
        link: "/loja-de-marketing",
        target: "_blank",
        style: "text-left",
        permission: null
    },
    {
        image: card3Img,
        text: "Abasteça seu veículo com créditos do Shell Box",
        action: "Resgate agora",
        link: "/credito-combustivel",
        target: "_blank",
        style: "text-right text-white",
        permission: null
    },
    {
        image: card4Img,
        text: "Pague contas de luz, água, telefone com seus pontos",
        action: "Pague agora",
        link: "/troca-por-servicos",
        target: "_blank",
        style: "text-left text-white",
        permission: null
    },
    
];

export default CardsData;