import { Helmet, HelmetProvider } from "react-helmet-async";
import AcceptHeader from "../components/AcceptHeader";
import AcceptBlock from "../components/AcceptBlock";

const Accept = () => {
  return (
    <>
      <HelmetProvider>
          <Helmet>
            <title>
              Regulamento | {process.env.REACT_APP_SITE_NAME}
            </title>
          </Helmet>
      </HelmetProvider>
      <AcceptHeader />
      <AcceptBlock />
    </>
  );
};

export default Accept;
