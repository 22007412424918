
import { useEffect, useContext, useState } from 'react';
import styled from "styled-components";
import { Skeleton, Snackbar, Alert, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

import ShoppingCartContext from "../context/ShoppingCartContext";
import Breakpoints from '../config/Breakpoints';
import PrepareFetch from '../helpers/PrepareFetch';
import PointsFormatUtil from '../helpers/PointFormatUtil';
import ProductFacades from '../helpers/ProductFacades';
import ShoppingCartContextUpdate from '../context/ShoppingCartContextUpdate';
import ShoppingCartFacades from '../helpers/ShoppingCartFacades';

import StyledButton from "../atom/Button";

import noImage from "../assets/images/store-noPicture.png";
import cart from "../assets/icons/cart.svg";
import linkArrowRightWhite from '../assets/icons/linkArrowRightWhite.svg';

const ShoppingCartItem = ({className, productData, editable}) => {
  if(typeof editable === "undefined") {
    editable = true;
  }

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-lightGray);
    align-items: center;

    &.disabled {
      opacity: .5;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    img.thumb {
      width: 4.75rem;

      @media ${Breakpoints.max.xs} {
        display: none;
      }
    }

    .detailBlock {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: .25rem;

      h4 {
        font-size: var(--font-size-md);
        color: var(--color-brand);
        margin: 0;
      }

      strong {
        font-size: var(--font-size-md);
        color: var(--color-black);

        span {
          text-transform: uppercase;
          letter-spacing: .08em;
          font-size: .625rem;
        }

        &.detailBlock__originalValue {
          opacity: .5;

          em {
            text-decoration: line-through;
            font-style: normal;
            font-size: .875rem;
          }
        }
      }
    }

    .quantityBlock {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .addRemove,
      .quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 3rem;
        font-weight: var(--font-weight-bold);
      }

      .addRemove {
        font-size: var(--font-size-xxl);
        color: var(--color-brand);
        border: none;
        background: none;
        cursor: pointer;

        &.disabled {
          color: var(--color-lightGray);
          pointer-events: none;
        }
      }

      .quantity {
        font-size: var(--font-size-md);
        border-radius: .25rem;
        border: 2px solid var(--color-brand);
      }

      .removeAll {
        font-size: .625rem;
        text-transform: uppercase;
        letter-spacing: .08em;
        color: var(--color-blueGrayDark);
        font-weight: var(--font-weight-bold);
        text-decoration: none;
      }

      &.viewOnly {
        div {
          gap: .5rem;

          & > strong {
            color: var(--color-blueGrayDark);
            font-size: .625rem;
            letter-spacing: .08em;
          }
        }

        .quantity {
          border-color: var(--color-lightGray);
        }
      }
    }
  `
  const images = productData.images;
  const id = productData.id;
  const defaultErrorMessage = "Houve um problema ao atualizar o carrinho.";

  const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext);
  const [ displayImage, setDisplayImage ] = useState(null);
  const [ loadingImage, setLoadingImage ] = useState(false);
  
  const [ removeStatus, setRemoveStatus ] = useState(true);
  const [ addStatus, setAddStatus ] = useState(true);
  const [ itemStatus, setItemStatus ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(defaultErrorMessage);

  useEffect(() => {
    if(images === null) {
      setLoadingImage(true);

      (async () => {
        try {
          let fetchData = PrepareFetch(`/proxy/services/apexrest/api/v1/rewards/${id}`,"GET");

          await fetch(fetchData.url, fetchData.options)
                  .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    }

                    throw new Error(response);
                  })
                  .then(json => {
                    let data = ProductFacades(json);
                    if(typeof data[0] !== "undefined" && data[0].images.length > 0) {
                      let image = data[0].images[0].url;
                      setDisplayImage(image);
                      setLoadingImage(false);

                      setShoppingCart(prevShoppingCart => {
                        for(let i=0;i<prevShoppingCart.items.length;i++) {
                          if(prevShoppingCart.items[i].id === id) {
                            prevShoppingCart.items[i].images = image;
                          }
                        }

                        return prevShoppingCart;
                      });
                    }
                  })
                  .catch(error => {
                      setDisplayImage(null);
                      setLoadingImage(false);
                  });
        } catch (error) {
          setLoadingImage(false);
        }
      })();
    } else {
      setDisplayImage(images);
    }
  },[displayImage, images, id])

  const addRemoveItems = (productData, mode) => {
    let method = mode === "delete" ? "DELETE" : "PATCH";
    let body = {
      rewardId: productData.id
    };

    let newQuantity = mode === "add" ? productData.quantity + 1 : productData.quantity - 1;
    
    if(mode !== "delete") {
      body.quantity = newQuantity;
    }

    // if(mode === "add") {
    //   setShoppingCart(ShoppingCartAdd(shoppingCart,productData));
    // } else {
    //   setShoppingCart(ShoppingCartRemove(shoppingCart,productData));
    // }
    // setShoppingCartTotal(shoppingCart.total);
    // setShoppingCartQuantity(shoppingCart.quantity);

    let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/items",method,{
      "Content-Type": "application/json"
    },body);

    setError(false);
    if(mode === "add") {
      setAddStatus(false);
    } else if(mode === "remove") {
      setRemoveStatus(false);
    } else if(mode === "delete") {
      setItemStatus(false);
    }

    try {
      fetch(fetchData.url, fetchData.options)
        .then(response => {
          if(response.status === 200) {
            return response.json();
          } 

          response.json().then(jsonError => {
            if(jsonError[0].message.indexOf("Insufficient inventory") > -1) {
                setErrorMessage("Não há estoque deste produto para incluir mais itens no carrinho.");
            } else {
                setErrorMessage(defaultErrorMessage);
            }
          })

          throw new Error(response);
        })
        .then(json => {
          setShoppingCart(ShoppingCartFacades(json));

          if(mode === "add") {
            setAddStatus(true);
          } else {
            if(mode === "remove") {
              setRemoveStatus(true);
            } 
          }     
        })
        .catch(error => {
          setAddStatus(true);
          setRemoveStatus(true);
          setError(true);
        })
    } catch (error) {
      setError(true);
      setAddStatus(true);
      setRemoveStatus(true);
    }
  }

  return (
    <Container className={(!itemStatus ? "disabled" : "")}>
      {loadingImage && (
        <Skeleton
            sx={{
                borderRadius: ".5rem",
                height: "4.75rem",
                width: "4.75rem"
            }}
            variant="rectangular"
        />
      )}
      {!loadingImage && (
        <>
          {displayImage === null && (
            <img src={noImage} className="thumb" alt="" />
          )}
          {displayImage !== null && (
            <img src={displayImage} className="thumb" alt="" />
          )}
        </>
      )}
      <div className="detailBlock">
        <h4>{productData.name}</h4>
        {productData.originalValue > productData.value && (
          <>
            <strong className="detailBlock__originalValue"><span>De</span> <em>{PointsFormatUtil(productData.originalValue)}</em></strong>
            <strong><span>Por</span> {PointsFormatUtil(productData.value)} <span>pontos</span></strong>
          </>
        )}
        {productData.originalValue === productData.value && (
          <strong>{PointsFormatUtil(productData.value)} <span>pontos</span></strong>
        )}
      </div>
      <div className={`quantityBlock ${(!editable ? 'viewOnly' : '')}`}>
        {editable && (
          <>
            <div>
                {(productData.quantity === 1 || !removeStatus) && (
                  <button className="addRemove disabled" disabled>-</button>
                )}
                {(productData.quantity > 1 && removeStatus) && (
                  <button className="addRemove" onClick={() => addRemoveItems(productData,"remove")}>-</button>  
                )}
                <div className="quantity">
                  {(!addStatus || !removeStatus) && (
                    <CircularProgress
                      size={20}
                      sx={{
                          color: "var(--color-gray)",
                      }}
                  />
                  )}
                  {(addStatus && removeStatus) && (
                    <>{productData.quantity}</>
                  )}
                </div>
                {!addStatus && (
                  <button className="addRemove disabled" disabled>+</button>
                )}
                {addStatus && (
                  <button className="addRemove" onClick={() => addRemoveItems(productData,"add")}>+</button>
                )}
            </div>
            <div>
                <a href="#remove" onClick={() => addRemoveItems(productData,"delete")} className="removeAll">Remover</a>
            </div>
          </>
        )}
        {!editable && (
          <>
            <div>
                <strong>QTDE:</strong>
                <div className="quantity">{productData.quantity}</div>
            </div>
          </>
        )}
      </div>
      { error && (
        <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                {errorMessage}
            </Alert>
        </Snackbar>
      )}
    </Container>
  )
}

const ShoppingCartItems = ({className}) => {
    const { shoppingCart } = useContext(ShoppingCartContext);
    const [ cartItems, setCartItems ] = useState([]);

    useEffect(() => {
      if(shoppingCart.items) {
        setCartItems(shoppingCart.items);
      }
    },[shoppingCart])
  
    const Container = styled.div`
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 1rem;
  
      h3 {
        font-size: var(--font-size-xl);
        color: var(--color-blueGrayDark);
        margin: 0;
        padding-left: 1.25rem;
      }
  
      .list {
        display: flex;
        flex-direction: column;
        border-radius: .5rem;
        border: 1px solid var(--color-lightGray);
        padding: 1.5rem;
        gap: 1.5rem;
      }

      .emptyCart {
        margin: 3rem 0;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;

        .icon {
          display: inline-flex;
          flex: 0;
          padding: 1rem;
          border-radius: 99px;
          background: var(--color-xLightGray);

          img {
            width: 2.5rem;
          }
        }

        & > div {
          display: flex;
          flex-direction: column; 
          align-items: center;
          text-align: center;
        }

        h4, p {
          margin: 0;
        }

        h4 {
          font-size: var(--font-size-lg);
        }

        p {
          font-size: var(--font-size-sm);
          color: var(--color-gray);
        }
      }
    `
  
    return (
      <Container>
        <h3>Carrinho de compras</h3>
        <div className="list">
          {cartItems.length > 0 && (
            <>
              {cartItems.map(item => {
                return (
                  <ShoppingCartItem key={item.id} productData={item} />
                )
              })}
            </>
          )}
          {cartItems.length === 0 && (
            <div className="emptyCart">
              <div className="icon"><img src={cart} alt="" /></div>
              <div>
                <h4>Seu carrinho está vazio.</h4>
                <p>
                  Você ainda não adicionou nenhum produto no carrinho.<br/>
                  Aproveite agora nossas ofertas!
                </p>
              </div>
              <StyledButton component={Link} to="/loja-de-marketing" style={{borderRadius: ".5rem"}}>
                Adicionar mais itens
                <img src={linkArrowRightWhite} alt="" style={{width: "1.5rem"}} />
              </StyledButton>
            </div>
          )}
        </div>
      </Container>
    )
  }

  export { ShoppingCartItems, ShoppingCartItem };