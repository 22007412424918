import { TextField } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from '@material-ui/lab';
import PrepareFetch from "../helpers/PrepareFetch";
import { useState, useMemo, useEffect } from "react";
import ProductFacades from "../helpers/ProductFacades";
import { debounce } from '@mui/material/utils';
import Breakpoints from "../config/Breakpoints";
import MuiBlock from "../atom/MuiCustoms";
import search from "../assets/icons/search.svg";

const SearchField = styled(TextField)`
    width: 14rem;
    position: relative;

    input {
      padding-right: 2.75rem;
    }

    .MuiFormLabel-root {
      left: .625rem;
    }

    .MuiOutlinedInput-notchedOutline legend {
      margin-left: .625rem;
    }

    .MuiInputBase-root {
      border-radius: 99px;
      padding-left: 1.125rem !important;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: .875rem;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background: url(${search}) center center no-repeat;
      background-size: 100% auto;
      transform: translateY(-50%);
    }

    .MuiAutocomplete-endAdornment {
      right: 2.625rem !important;
    }

    .MuiAutocomplete-popupIndicator {
      display: none;
    }

    @media ${Breakpoints.md} {
      width: 30vw;
    }
  `

const StoreSearchBar = ({className}) => {
  const [value, setValue] = useState(null);
  const [itemResults, setItemResults] = useState([]);
  const [inputValue, setInputValue] = useState('');
  // const autocompleteValue = '';

  const navigate = useNavigate();

  const doFetch = useMemo(
    () =>
      debounce((query, callback) => {
        if(query.length < 3) return;        
        (async () => {
          try {
            let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/rewards?q=" + query + "&orderBy=FieloPLT__Points__c&page=1&pageSize=5","GET",{
              'cache-by': 'items'
            });
    
            await fetch(fetchData.url, fetchData.options)
                    .then(response => {
                      if(response.status === 200) {
                        return response.json();
                      }
    
                      throw new Error(response);
                    })
                    .then(json => {
                      if(typeof callback === "function") {
                        callback.call(undefined, json);
                      }
                    })
                    .catch(error => {
                      console.log("Busca invalida");
                    });
          } catch (error) {
            console.log("Erro de requisicao");
          }
        })();
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setItemResults(value ? [value] : []);
      return undefined;
    }

    doFetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...ProductFacades(results)];
        }

        setItemResults(newOptions);
      }
    });

    return () => {
      active = false;
    };

  },[value, inputValue, doFetch])

  const goToItemPage = (id) => {
    navigate("/loja-de-marketing/produto/"+id);
  }

  return(
    <MuiBlock className={className}>
      <Autocomplete
        id="autocompleteItems"
        getOptionLabel={(option) => {
          return option.name;
        }}
        filterOptions={(x) => x}
        options={itemResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        autoHighlight={false}
        autoSelect={false}
        clearOnEscape
        open={inputValue.length > 3}
        value={value}
        noOptionsText={'Nenhum produto encontrado.'}
        onChange={(event, newValue) => {
          setValue(newValue);
          setItemResults(newValue ? [newValue, ...itemResults] : itemResults);
          goToItemPage(newValue.id);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <SearchField {...params} label="O que esta buscando?" variant="outlined" />}
      />
    </MuiBlock>
  );
};

export default StoreSearchBar
