import styled from "styled-components";
import { Link } from "react-router-dom";
import Breakpoints from "../config/Breakpoints";
import contactTitleAfter from "../assets/images/contactTitleAfter.svg";

const ContactTitle = ({className, title}) => {
    if(typeof title === "undefined") {
        title = "Entre em contato conosco";
    }

    const ContactTitleBlock = styled.h2`
        margin: 0;
        color: var(--color-brand);
        font-size: var(--font-size-xl);
        line-height: 1em;
        height: fit-content;

        @media ${Breakpoints.md} {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            position: relative;
            font-size: var(--font-size-xxl);

            span {
                display: inline-block;
                max-width: 11.875rem;
            }

            &:after {
                display: block;
                content: '';
                margin-left: 5.625rem;
                width: 13.75rem;
                height: 4rem;
                background: url(${contactTitleAfter}) top left no-repeat;
            }
        }
    `

    return (
        <ContactTitleBlock className={className}>
            <span>{title}</span>
        </ContactTitleBlock>
    )
}

const ContactItem = ({className, link, image, headline, children, toggleChatbot}) => {
    const ContactItemBlock = styled.div`
        padding: 1.5rem 0;
        border-bottom: 1px solid var(--color-lightGray);
        
        
        .contactItemCtt {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: flex-start;
            text-decoration: none;
            color: var(--color-black);
            font-size: var(--font-size-md);
            line-height: 1em;

            strong {
                display: block;
                color: var(--color-brand);
            }

            ul, a {
                color: var(--color-brand);
                font-weight: var(--font-weight-bold);
            }

            ul {
                padding-left: 1.5rem;
            }

            li {
                margin: .25rem 0;
            }

            a {
                text-decoration: none;
            }
        
            @media ${Breakpoints.md} {
                font-size: var(--font-size-lg);
            }
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        img {
            width: 2.25rem;
            height: 2.25rem;
        }
    `

    let openChatbot = (e) => {
        if(typeof window.blipClient !== "undefined") {
            window.blipClient.toogleChat();
        }

        return true;
    }

    return (
        <ContactItemBlock className={className}>
            {(toggleChatbot && !link) && (
                <div onClick={openChatbot} className="contactItemCtt" style={{cursor: "pointer"}}>
                    {image && (
                        <img src={image} alt="" />
                    )}
                    <span>
                        {headline && (
                            <strong>{headline}</strong>
                        )}
                        {children}
                    </span>
                </div>
            )}
            {(link && !toggleChatbot) && (
                <Link to={link} className="contactItemCtt">
                    {image && (
                        <img src={image} alt="" />
                    )}
                    <span>
                        {headline && (
                            <strong>{headline}</strong>
                        )}
                        {children}
                    </span>
                </Link>
            )}
            {(!link && !toggleChatbot) && (
                <span className="contactItemCtt">
                    {image && (
                        <img src={image} alt="" />
                    )}
                    <span>
                        {headline && (
                            <strong>{headline}</strong>
                        )}
                        {children}
                    </span>
                </span>
            )}
            
        </ContactItemBlock>
    )
}

const ContactBlock = ({className, children}) => {
    const ContactBlockContent = styled.div`
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 3rem 1.5rem;

        .contactItemsBlock {
            display: flex;
            flex-direction: column;
            gap: 0;
        }

        @media ${Breakpoints.md} {
            padding: 5rem 0;
            margin: 0 auto;
            width: 45rem;
            flex-direction: row;
            justify-content: space-between;

            .contactItemsBlock {
                flex: 0;
                min-width: 20rem;
            }
        }
    `

    return (
        <ContactBlockContent className={className}>
            {children}
        </ContactBlockContent>
    )
}

export { ContactTitle, ContactItem, ContactBlock }