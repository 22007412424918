import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"
import ScrollToTop from "./helpers/ScrollToTop";
import PrivateRoute from "./helpers/PrivateRoute";
import Context from "./context";
import MainAppContext from "./context/MainAppContext";

import Home from "./pages/Home";
import Faq from "./pages/Faq";
import Accept from "./pages/Accept";
import DownloadApp from "./pages/DownloadApp";
import CreditCard from "./pages/CreditCard";
import CreditCardCheckout from "./pages/CreditCardCheckout";
import CreditCardCheckoutFeedback from "./pages/CreditCardCheckoutFeedback";
import TalkToUs from "./pages/TalkToUs";
import PointsRule from "./pages/PointsRule";
import ErrorPage from "./pages/ErrorPage";
import PremmiarSSO from "./pages/PremmiarSSO";
import PointsStatement from "./pages/PointsStatement";
import Rules from "./pages/Rules";
import Store from "./pages/Store";
import StoreCategory from "./pages/StoreCategory";
import ProductDetail from "./pages/ProductDetail";
import ShoppingCart from "./pages/ShoppingCart";
import Checkout from "./pages/Checkout";
import CheckoutFeedback from "./pages/CheckoutFeedback";
import { StoreRedemptions, Redemptions } from "./pages/Redemptions";
import { StoreRedemptionItem, RedemptionItem } from "./pages/Redemption";
import { sessionStartHandle } from "./helpers/SessionStart";

// Acredito que salvar o authToken no localStorage traz inseguranca pra aplicacao
// Com os dados encontrados na aba "application" no chrome e a url obtida na aba "network"
// Qualquer usuario consegueria se passar pela nossa aplicacao para fazer chamadas de API
const storeToken = ({ token }) => {
  localStorage.setItem("authToken", token);
  sessionStartHandle('onAuthSuccess');
}

const App = () => {
  const [ needsAgreement, setNeedsAgreement ] = useState(true);
  // const environment = process.env.VERCEL_ENV || "development";

  return (
    <ReactKeycloakProvider authClient={keycloak} onTokens={storeToken} initOptions={{ onLoad: 'login-required' }} autoRefreshToken={true}>
      <PrivateRoute>
        <MainAppContext.Provider value={{needsAgreement, setNeedsAgreement}}>
          <Context>
            <ScrollToTop>
              <Routes>
                {needsAgreement && (
                  <>
                    <Route path="/" element={ <Accept /> } />
                    <Route path="/aceite" element={ <Accept /> } />
                  </>
                )}
                {!needsAgreement && (
                  <>
                    <Route path="/" element={ <Home /> } />
                    <Route path="/home" element={ <Home /> } />
                    <Route path="/acumule-pontos" element={ <PointsRule /> } />
                    <Route path="/fale-conosco" element={ <TalkToUs /> } />
                    <Route path="/duvidas-frequentes" element={ <Faq /> } />
                    <Route path="/baixe-o-app" element={ <DownloadApp /> } />
                    <Route path="/cartao-de-credito" element={ <CreditCard /> } />
                    <Route path="/cartao-de-credito/resgate" element={ <CreditCardCheckout /> } />
                    <Route path="/cartao-de-credito/resgate/sucesso" element={ <CreditCardCheckoutFeedback /> } />
                    <Route path="/regulamento" element={ <Rules /> } />

                    <Route path="/troca-por-produtos" element={ <PremmiarSSO startUrl="/stores" /> } /> 
                    <Route path="/troca-por-servicos" element={ <PremmiarSSO startUrl="/billpayment" /> } /> 
                    <Route path="/troca-por-viagens" element={ <PremmiarSSO startUrl="/store/62002/1" /> } /> 
                    <Route path="/troca-de-pontos" element={ <PremmiarSSO /> } /> 
                    <Route path="/recarga-celular" element={ <PremmiarSSO startUrl="/mobile-recharge" /> } /> 
                    <Route path="/credito-combustivel" element={ <PremmiarSSO startUrl="/fuelcredit" /> } /> 

                    
                    <Route path="/loja-de-marketing" element={ <Store /> } />
                    <Route path="/loja-de-marketing/categoria/:id" element={ <StoreCategory /> } />
                    <Route path="/loja-de-marketing/produto/:id" element={ <ProductDetail /> } />
                    <Route path="/loja-de-marketing/carrinho" element={ <ShoppingCart /> } />
                    <Route path="/loja-de-marketing/checkout" element={ <Checkout /> } />
                    <Route path="/loja-de-marketing/checkout/sucesso" element={ <CheckoutFeedback /> } />
                    <Route path="/loja-de-marketing/pedidos" element={ <StoreRedemptions /> } />
                    <Route path="/loja-de-marketing/pedidos/:id" element={ <StoreRedemptionItem /> } />

                    <Route path="/extrato-de-pontos" element={ <PointsStatement /> } />
                    <Route path="/pedidos" element={ <Redemptions /> } />
                    <Route path="/pedidos/:id" element={ <RedemptionItem /> } />
                  </>
                )}
                <Route path="/cadastro-invalido" element={ 
                  <ErrorPage title="Parece que você não tem acesso ao Intelbras Pontua">
                    <div>
                      <p>Parceiro, o Intelbras Pontua é um benefício exclusivo para Parceiros credenciados no Programa de Canais Intelbras (PCI).</p>
                      <p>Se você é o gestor da conta, revise seu cargo para "Proprietário/a" ou "Sócio" no <a href="https://extranet.intelbras.com.br/gestao-canais/colaboradores/" target="_blank" rel="noreferrer">Portal de relacionamento</a>. Se você é Vendedor de um Distribuidor, solicite ao gestor da conta a revisão do seu cargo para cargo "Vendedor/a" no Portal de relacionamento. O acesso será liberado no prazo de 24 horas.</p>
                      <p>Se o problema persistir ou se tiver alguma dúvida, não hesite em entrar em contato através do e-mail <a href="mailto:intelbraspontua@intelbras.com.br">intelbraspontua@intelbras.com.br</a>.</p>
                    </div>
                  </ErrorPage>
                } />

                <Route path="*" element={ <ErrorPage title="Ops!" description="Não conseguimos encontrar a página que está procurando." showLink={true} /> } />

              </Routes>
            </ScrollToTop>
          </Context>
        </MainAppContext.Provider>
      </PrivateRoute>
    </ReactKeycloakProvider>
  );
};

export default App;
