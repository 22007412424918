import { FormGroup, FormControlLabel, Checkbox, TextField, MenuItem, CircularProgress, Snackbar, Alert } from "@mui/material";
import styled from "styled-components";
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask'
import { useNavigate } from "react-router-dom";

import { BirthDateValidate, BirthDateExport } from '../helpers/BirthDateUtils';
import { NumberExport } from '../helpers/PhoneNumberUtils';
import PrepareFetch from "../helpers/PrepareFetch";

import MuiBlock from "../atom/MuiCustoms";
import Breakpoints from "../config/Breakpoints";
import StyledButton from "../atom/Button";

import CheckoutResume from '../components/CheckoutResume';

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3 {
        margin: 0;
        font-size: var(--font-size-xl);
        color: var(--color-blueGrayDark);
    }

    & > strong {
        color: var(--color-blueGrayDark);
    }

    .formRow {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media ${Breakpoints.sm} {
            flex-direction: row;
            align-items: center;
        }
    }

    .formHelper {
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-bold);
    }

    .checkboxText {
        font-size: var(--font-size-sm);
        color: var(--color-blueGrayDark);

        a {
            font-weight: var(--font-weight-bold);
            color: var(--color-brand);
            text-decoration: none;
        }
    }
`

const FormAccordion = ({opened, accordionTitle, accordionSubtitle, clickEvent, children}) => {
    const Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 1.25rem;
        border-radius: .5rem;
        border: 1px solid var(--color-lightGray);

        &.opened {
            border-color: var(--color-brand);
        }
    `

    const Head = styled.div`
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        cursor: pointer;

        .fakeRadio {
            position: relative;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 99px;
            border: 2px solid var(--color-brand);

            &:before {
                content: '';
                position: absolute;
                width: .75rem;
                height: .75rem;
                border-radius: 99px;
                background: var(--color-brand);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: all .3s ease-in;
            }
        }

        &.opened .fakeRadio {
            &:before {
                opacity: 1;
            }
        }

        .headContent {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            color: var(--color-blueGrayDark);

            span {
                font-size: var(--font-size-sm);
            }
        }
    `

    const Content = styled.div`
        padding-top: 1rem;
        border-top: 1px solid var(--color-lightGray);
        display: none;
        
        &.opened {
            display: block;
        }

        & > .helperInfo {
            text-align: center;
            font-size: var(--font-size-sm);
            color: var(--color-blueGrayDark);
        }

        .resumeRow {
            display: flex;
            gap: 1rem;
            flex-direction: column;
            padding-top: 1rem;
    
            hr {
                height: 0;
                width: 100%;
                border: none;
                border-bottom: 1px solid var(--color-lightGray);
            }
    
            .row {
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
        
                span {
                    font-size: var(--font-size-sm);
                    color: var(--color-gray);
        
                    &.super {
                        font-size: var(--font-size-md);
                        color: var(--color-blueGrayDark);
                        font-weight: var(--font-weight-bold);
                    }
                }
        
                strong {
                    width: 30%;
                    text-align: right;
                    font-size: var(--font-size-sm);
                    color: var(--color-black);
        
                    &.super {
                        font-size: var(--font-size-md);
                    }
                }
            }
        }

        @media ${Breakpoints.sm} {
            margin-left: 2.5rem;
        }
    `

    return (
        <Container className={opened ? "opened" : ""}>
            <Head className={opened ? "opened" : ""} onClick={clickEvent}>
                <div className="fakeRadio"></div>
                <div className="headContent">
                    <strong>{accordionTitle}</strong>
                    <span>{accordionSubtitle}</span>
                </div>
            </Head>
            <Content className={opened ? "opened" : ""}>
                {children}
            </Content>
        </Container>
    )
}

const FormFields = ({className, hasCard, points}) => {
    const {register, control, formState: { errors }, handleSubmit, setValue} = useForm({mode: "onBlur"});
    const [zipcodeLoading, setZipcodeLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [submitAllowed, setSubmitAllowed] = useState(false);
    const termsLink = localStorage.getItem("creditCardAgreement");
    const navigate = useNavigate();

    if(typeof hasCard === "undefined") {
        hasCard = false;
    }

    const submitCheckout = (data) => {
        let sendData = {
            value: points,
        };

        if(!hasCard) {
            sendData = {
                ...sendData,
                birthDate: BirthDateExport(data.birthDate),
                address: {
                    postalCode: data.postalCode,
                    street: data.street,
                    streetNumber: data.streetNumber + "",
                    streetComplement: data.streetComplement,
                    reference: data.reference,
                    district: data.district,
                    city: data.city,
                    state: data.state,
                    mobile: NumberExport(data.mobile)
                }
            }
        }

      
        const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/card/likemais/checkout","POST",{
            "Content-Type": "application/json"
        },sendData);

        try {
            setError(false);
            setLoading(true);

            fetch(fetchData.url, fetchData.options)
            .then(response => {
                if(response.status === 200) {
                    setLoading(false);
                    
                    navigate("/cartao-de-credito/resgate/sucesso");
                }

                throw new Error(response);
            })
            .catch(error => {
                setLoading(false);
                setError(true);

                console.log("Erro ao processar o pedido de resgate");
                if(process.env.VERCEL_ENV !== "production") {
                    console.log(error);
                }
            });
        } catch (error) {
            setError(true);
            setLoading(false);
            console.log("Erro ao processar o pedido de resgate");
        }
        
    }

    const checkPostalCode = (e) => {
        let zipcode = e.target.value.replace(/_|-/g,"");

        if(zipcode.length === 8) {
            (async () => {
              try {
                setZipcodeLoading(true);

                await fetch("https://viacep.com.br/ws/" + zipcode + "/json")
                        .then(response => {
                            setZipcodeLoading(false);
                            if(response.status === 200) {
                                return response.json();
                            }
        
                            throw new Error(response);
                        })
                        .then(json => {
                            if(typeof json.erro === "undefined") {
                                setValue('district',json.bairro,{shouldValidate: true, shouldDirty: true});
                                setValue('city',json.localidade,{shouldValidate: true});
                                setValue('street',json.logradouro,{shouldValidate: true, shouldDirty: true});
                                setValue('state',json.uf,{shouldValidate: true});
                            }
                        })
              } catch (error) {
                setZipcodeLoading(false);
                console.warn("Erro ao carregar dados do CEP");
              }
            })();
          }
    }

    return (
        <form onSubmit={handleSubmit(submitCheckout)} className={className}>
            <FormContainer>
                {!hasCard && (
                    <>  
                        <strong>Informe o endereço para envio do Cartão</strong>
                        <Controller
                            name="postalCode"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value} }) => (
                                <InputMask 
                                    mask="99999-999" 
                                    maskChar="_"
                                    onChange={(e) => {
                                        onChange(e);
                                        checkPostalCode(e);
                                    }}
                                    onBlur={onBlur}
                                    value={value || ""}
                                >
                                    {inputProps => (
                                        <TextField
                                                label="CEP"
                                                type="tel"
                                                fullWidth
                                                variant="outlined"
                                                style={{width: "12.5rem"}}
                                                error={!!errors.postalCode}
                                                required
                                                {...inputProps}
                                            ></TextField>
                                    )}
                                </InputMask>
                            )}
                        />
                        
                        <Controller
                            name="street"
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ 
                                field: { onChange, onBlur, value}
                            }) => (
                                <TextField
                                    label="Endereço"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    disabled={zipcodeLoading}
                                    error={!!errors.street}
                                />
                            )}
                        />

                        <div className="formRow">
                            <TextField
                                {...register('streetNumber', {
                                    required: true, 
                                    valueAsNumber: true,
                                    validate: (value) => value > 0,
                                })}
                                type="tel"
                                label="Número"
                                required
                                fullWidth
                                variant="outlined"
                                error={!!errors.streetNumber}
                            />

                            <TextField
                                {...register('streetComplement')}
                                label="Complemento"
                                fullWidth
                                variant="outlined"
                                error={!!errors.streetComplement}
                            />

                            <TextField
                                {...register('reference')}
                                label="Referência"
                                fullWidth
                                variant="outlined"
                                error={!!errors.reference}
                            />
                            
                        </div>
                        
                        <Controller
                            name="district"
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ 
                                field: { onChange, onBlur, value}
                            }) => (
                                <TextField
                                    label="Bairro"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    disabled={zipcodeLoading}
                                    error={!!errors.district}
                                />
                            )}
                        />

                        <div className="formRow">
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: true }}
                                defaultValue=""
                                render={({ 
                                    field: { onChange, onBlur, value}
                                }) => (
                                    <TextField
                                        label="Cidade"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        disabled={zipcodeLoading}
                                        error={!!errors.city}
                                    />
                                )}
                            />
                            
                            <Controller
                                name="state"
                                control={control}
                                rules={{ required: true }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value} }) => (
                                    <TextField
                                        label="Estado"
                                        value={value || ""}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        disabled={zipcodeLoading}
                                        error={!!errors.state}
                                        select
                                    >
                                        <MenuItem value="AC">Acre</MenuItem>
                                        <MenuItem value="AL">Alagoas</MenuItem>
                                        <MenuItem value="AP">Amapá</MenuItem>
                                        <MenuItem value="AM">Amazonas</MenuItem>
                                        <MenuItem value="BA">Bahia</MenuItem>
                                        <MenuItem value="CE">Ceará</MenuItem>
                                        <MenuItem value="DF">Distrito Federal</MenuItem>
                                        <MenuItem value="ES">Espírito Santo</MenuItem>
                                        <MenuItem value="GO">Goiás</MenuItem>
                                        <MenuItem value="MA">Maranhão</MenuItem>
                                        <MenuItem value="MT">Mato Grosso</MenuItem>
                                        <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                        <MenuItem value="MG">Minas Gerais</MenuItem>
                                        <MenuItem value="PA">Pará</MenuItem>
                                        <MenuItem value="PB">Paraíba</MenuItem>
                                        <MenuItem value="PR">Paraná</MenuItem>
                                        <MenuItem value="PE">Pernambuco</MenuItem>
                                        <MenuItem value="PI">Piauí</MenuItem>
                                        <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                        <MenuItem value="RN">Rio de Grande do Norte</MenuItem>
                                        <MenuItem value="RS">Rio de Grande do Sul</MenuItem>
                                        <MenuItem value="RO">Rondônia</MenuItem>
                                        <MenuItem value="RR">Roraima</MenuItem>
                                        <MenuItem value="SC">Santa Catarina</MenuItem>
                                        <MenuItem value="SP">São Paulo</MenuItem>
                                        <MenuItem value="SE">Sergipe</MenuItem>
                                        <MenuItem value="TO">Tocantins</MenuItem>
                                    </TextField>
                                )}
                            />
                            
                        </div>
                        
                        <div className="formRow">
                            <Controller
                                name="birthDate"
                                control={control}
                                rules={{ required: true, validate: (value) => BirthDateValidate(value) }}
                                render={({ field: { onChange, onBlur, value} }) => (
                                    <InputMask 
                                        mask="99/99/9999" 
                                        maskChar="_"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value || ""}
                                    >
                                        {inputProps => (
                                            <TextField
                                                    label="Data de nascimento"
                                                    type="tel"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={!!errors.birthDate}
                                                    required
                                                    {...inputProps}
                                                    style={{width: "18rem"}}
                                                ></TextField>
                                        )}
                                    </InputMask>
                                )}
                            />
                        </div>

                        <div className="formRow">
                            <Controller
                                name="mobile"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value} }) => (
                                    <InputMask 
                                        mask="(99) 99999.9999" 
                                        maskChar="_"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value || ""}
                                    >
                                        {inputProps => (
                                            <TextField
                                                    label="Telefone celular"
                                                    type="tel"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={!!errors.mobile}
                                                    required
                                                    {...inputProps}
                                                    style={{width: "18rem"}}
                                                ></TextField>
                                        )}
                                    </InputMask>
                                )}
                            />
                            <div className="formHelper">
                                Para acompanhamento e rastreamento do pedido através de SMS.
                            </div>
                        </div>
                    </>
                )}

                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                {...register('aceite', {required: true})} 
                                onClick={() => setSubmitAllowed(!submitAllowed)}
                            />} 
                        label={
                            <div className="checkboxText">
                                Li e aceito os <a href={termsLink} target="_blank" rel="noreferrer noopener">termos do regulamento do Cartão</a>
                            </div>
                        }
                    />
                </FormGroup>

                <StyledButton type="submit" disabled={(!submitAllowed || loading)} style={{width: "100%", borderRadius: ".5rem"}}>
                    Enviar
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "var(--color-white)",
                            }}
                        />
                    )}
                </StyledButton>
                { error && (
                    <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                            Houve um problema ao efetuar o resgate.
                        </Alert>
                    </Snackbar>
                )}
                
            </FormContainer>
        </form>
    )
}

const CheckoutCreditCardForm = ({points, hasCreditCard, data}) => {
    const [accordionOptions, setAccordionOptions] = useState([
        false,
        false
    ]);

    const changeAccordion = (position) => {
        let newAccordion = accordionOptions.map((item, index) => {
            if(index === position) {
                return true;
            }

            return false;
        });
        
        setAccordionOptions(newAccordion);
    }

    return (
        <MuiBlock style={(hasCreditCard ? {flex: "1 1 0", maxWidth: '50vw', margin: '0 auto'} : {flex: "1 1 0"})}>
            <FormContainer>
                <h3>Resgate no Cartão de Crédito Intelbras Pontua</h3>
                {hasCreditCard && (
                    <FormAccordion 
                        opened={true} 
                        accordionTitle="Já possuo o cartão" 
                        accordionSubtitle="Desejo trocar meus pontos por crédito" 
                    >
                        {data.map((item, index) => {
                            return (
                                <div className="resumeRow" key={"resumeCardItem__"+index}>
                                    <div className="row">
                                        <span className={(item.super && typeof item.value === "undefined" ? "super" : "")}>{item.label || ""}</span>
                                        <strong className={item.super ? "super" : ""}>{item.value}</strong>
                                    </div>
                                    {
                                    (
                                        typeof item.ommitDivisor === "undefined"
                                    ) && (
                                        <hr/>
                                    )}
                                </div>
                            )
                        })}
                        <FormFields hasCard={true} points={points} />
                        <p className="helperInfo">Crédito liberado em até 10 dias úteis após a solicitação</p>
                    </FormAccordion>
                )}
                {!hasCreditCard && (
                    <>
                        <FormAccordion 
                            opened={accordionOptions[0]} 
                            accordionTitle="Já possuo o cartão" 
                            accordionSubtitle="Desejo trocar meus pontos por crédito" 
                            clickEvent={() => changeAccordion(0)}
                        >
                            <FormFields hasCard={true} points={points} />
                            <p className="helperInfo">Crédito liberado em até 10 dias úteis após a solicitação</p>
                        </FormAccordion>
                        <FormAccordion 
                            opened={accordionOptions[1]} 
                            accordionTitle="Ainda não possuo o cartão" 
                            accordionSubtitle="Desejo receber o cartão" 
                            clickEvent={() => changeAccordion(1)}
                        >
                            <FormFields hasCard={false} points={points} />
                            <p className="helperInfo">Entrega do Cartão em até 30 dias úteis após a solicitação</p>
                        </FormAccordion>
                    </>
                )}
                
            </FormContainer>
        </MuiBlock>
    )
}

export default CheckoutCreditCardForm;