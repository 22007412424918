// import { useKeycloak } from "@react-keycloak/web";

const PrepareFetch = (endpoint, method, extraHeaders, body) => {
    // const { keycloak } = useKeycloak();
    const token = localStorage.getItem("authToken");
    const url = process.env.REACT_APP_API_URL + endpoint;

    const defaultHeaders = {
        "Authorization": "Bearer " + token,
        'Content-Type': 'application/json',
    };

    const options = {
        method: typeof(method) !== "undefined" ? method : "GET",
        headers: defaultHeaders
    };

    if(typeof body !== "undefined") {
        options.body = JSON.stringify(body);
    }

    options.headers = Object.assign(options.headers,extraHeaders);

    return { url, options };
}

export default PrepareFetch;