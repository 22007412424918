import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DOMPurify from 'dompurify'
import { Skeleton } from "@mui/material";

import CheckPermission from "../helpers/FeaturePermission";
import PrepareFetch from '../helpers/PrepareFetch';
import CreditCardFacades from '../helpers/CreditCardFacades';
import Breakpoints from "../config/Breakpoints";

import StyledButton from "../atom/Button";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import { ContactTitle, ContactItem, ContactBlock } from "../atom/ContactBlocks";
import FaqHighlight from "../atom/FaqHighlight";
import PageContainer from "../components/PageContainer";

import imgHighlightDesktop from "../assets/images/cartao-desktop.webp";
import imgHighlightMobile from "../assets/images/cartao-mobile.webp";
import ninaAvatar from "../assets/images/ninaAvatar.png";
import iconPhone from "../assets/icons/phone.svg";
import arrowRight from "../assets/icons/linkArrowRightWhite.svg";

const CreditCard = () => {
  const navigate = useNavigate();
  const permission = CheckPermission("CARTAO_LIKE_MAIS");

  const [isMobile, setIsMobile] = useState((window.innerWidth <= 1024));
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if(!permission) {
      navigate("/nao-encontrado");
    }
  },[navigate, permission]);
  
  useEffect(() => {
      window.addEventListener("resize", () => setIsMobile((window.innerWidth <= 1024)));
  });

  useEffect(() => {
    (async () => {
      let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/card/likemais`,"GET",{
          "cache-by": "user"
      });
      await fetch(fetchParams.url, fetchParams.options)
              .then(response => {
                  if(response.status === 200) {
                    return response.json();
                  }

                  throw new Error(response);
              })
              .then(json => {
                  let fetchData = CreditCardFacades(json);
                  setLoading(false);
                  setData(fetchData);

                  if(fetchData.agreement !== null) {
                    localStorage.setItem("creditCardAgreement",fetchData.agreement);
                  }
              })
              .catch(error => {
                  setLoading(false);
              });
    })();
  },[]);

  const CardContent = styled.div`
    font-size: 1rem;
    line-height: 1.25em;
    color: var(--color-blueGrayDark);

    p {
      margin: 0 0 .75rem;
    }

    strong {
      color: var(--color-brand);   
    }
  `

  const CardContactBlock = styled(ContactBlock)`
    @media ${Breakpoints.md} {
      width: 51.75rem !important;
      gap: 7rem !important;

      .contactItemsBlock {
        flex: 1 !important;
        min-width: 30rem;
      }
    }

    @media ${Breakpoints.max.xs} {
      display: none !important;
    }
  `

  const CardFaqHighlight = styled(FaqHighlight)`
    margin-left: 3rem;
    margin-right: 3rem;
    width: auto;

    @media ${Breakpoints.max.xs} {
      display: none;
    }
  `

  return (
    <PageContainer pageTitle={"Cartão de Crédito Intelbras Pontua | "+ process.env.REACT_APP_SITE_NAME}>
      <PageHeader>
        Cartão de Crédito Intelbras Pontua
      </PageHeader>
      <PageContent>
        <div className="imgArea">
          {isMobile && (
            <img src={imgHighlightDesktop} alt="" />
          )}
          {!isMobile && (
            <img src={imgHighlightDesktop} alt="" />
          )}
        </div>
        <div className="contentArea">
          <h3>Receba suas premiações de campanhas no nosso cartão de crédito</h3>
          <CardContent>
            {loading && (
              <>
                <p>
                  <Skeleton />
                </p>
                <p>
                  <Skeleton />
                </p>
                <p>
                  <Skeleton />
                </p>
                <p>
                  <Skeleton />
                </p>
                <p>
                  <Skeleton width="60%" />
                </p>
              </>
            )}
            {!loading && (
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.data)}}></div>
            )}
          </CardContent>
          {!loading && (
            <StyledButton component={Link} to="/cartao-de-credito/resgate">
              Resgatar 
              <img src={arrowRight} alt="" />
            </StyledButton>
          )}
        </div>
      </PageContent>
      <CardContactBlock>
          <ContactTitle />
          <div className="contactItemsBlock">
              <ContactItem image={iconPhone}>
                Em caso de dúvidas ou problemas com seu cartão, entre em contato com a central de atendimento.
                <ul>
                  <li><Link to="tel:40200221">4020-0221</Link></li>
                  <li><Link to="https://www.cartaolikemais.com.br" target="_blank">www.cartaolikemais.com.br</Link></li>
                </ul>
              </ContactItem>
              <ContactItem toggleChatbot={true} image={ninaAvatar} headline="Fale com a Nina,">
                  a assistente virtual da Intelbras
              </ContactItem>
          </div>
      </CardContactBlock>
      <CardFaqHighlight container="cartão de crédito" />
    </PageContainer>
  );
};

export default CreditCard;
