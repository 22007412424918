import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import PointsFormatUtil from "../helpers/PointFormatUtil";
import CheckPermission from "../helpers/FeaturePermission";
import DateFormatUtil from "../helpers/DateFormatUtil";
import Info from "../assets/icons/info.svg";
import linkArrowRight from "../assets/icons/linkArrowRight.svg";

const PointCardBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    border-radius: .5rem;
    background: var(--color-white);
    border-bottom: 3px solid var(--color-brand);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    color: var(--color-blueGrayDark);
    font-size: var(--font-size-sm);
    overflow: hidden;

    .infoBlock {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        margin-bottom: .5rem;
    }

    .iconBlock {
        width: 2.25rem;
        height: 2.25rem;
    }

    .definitionBlock {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: help;

        img {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    .titleBlock {
        padding-right: 1.75rem;
    }

    .pointsBlock {
        font-size: 1.75rem;
        font-weight: var(--font-weight-bold);
        color: var(--color-black);
        letter-spacing: -.5px;
    }

    .linkBlock {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;
        color: var(--color-brand);
        font-weight: var(--font-weight-bold);
        line-height: 1.125rem;
        text-decoration: none;

        img {
            width: 1rem;
            height: 1rem;
        }

        @media ${Breakpoints.max.xs} {
            img {
                display: none;
            }
        }
    }

    @media ${Breakpoints.md} {
        width: 7.5rem;

        &:first-child {
            width: 10rem;

            .pointsBlock {
                font-size: var(--font-size-2xl);
            }
        }
    }

    @media ${Breakpoints.lg} {
        width: 9rem;

        &:first-child {
            width: 14rem;
        }
    }

    @media ${Breakpoints.xl} {
        width: 11rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .definitionBlock {
            top: 1.5rem;
        }

        &:first-child {
            width: 16rem;
        }
    }
`

const PointCard = ({ className, label, icon, value, expiring, action, definition, onClick }) => {
  const DefinitionRender = () => {
    let message = "";

    definition.forEach(item => {
        if(item.permission !== null && CheckPermission(item.permission)) {
            message = item.message;
        } else if(item.permission === null && message === "") {
            message = item.message;
        }
    });  
    
    return (
        <div style={{maxWidth: "10rem"}}>{message}</div>
    )
  }

  const ExpiringRender = () => {
      if(typeof expiring === "undefined" || expiring.value === null) {
          return;
        }

      return (
          <div className="expiringBlock">
              <strong>{PointsFormatUtil(expiring.value)} pontos</strong> a expirar
              { expiring.in !== null && (
                      <span> em <strong>{DateFormatUtil(expiring.in)}</strong></span>
                  )
              }
          </div>
      )
  }

  return (
      <PointCardBlock className={className} onClick={onClick}>
          {icon && (
              <img src={icon} alt="" className="iconBlock" />
          )}
          {definition && (
              <div className="definitionBlock">
                  <Tooltip title={DefinitionRender()} arrow={true} placement="right-start">
                      <img
                          src={Info}
                          alt="Mais informações"
                      />
                  </Tooltip>
              </div>
          )}
          <div className="infoBlock">
              {label && (
                  <div className="titleBlock">
                      {label}
                  </div>
              )}
              {(value !== null && typeof value !== "undefined") && (
                  <div className="pointsBlock">
                      {PointsFormatUtil(value)}
                  </div>
              )}
              {ExpiringRender()}
          </div>
          {(action && !onClick) && (
              <Link to={action.to} className="linkBlock">
                  {action.label}
                  <img src={linkArrowRight} alt="" />
              </Link>
          )}
      </PointCardBlock>
  );
};

export default PointCard;
