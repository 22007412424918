const ShoppingCartFacades = (json) => {
    let quantity = 0;
    let originalValue = 0;
    let total = 0;
    let discount = 0;
    let items = [];
    let hasAnyCouponApplied = false;

    if(typeof json.items !== "undefined") {
        items = json.items.map(item => {
            quantity  = quantity + item.quantity;
            originalValue = originalValue + item.originalValue;
            total = total + item.value;

            if(item.hasCouponApplied) {
                hasAnyCouponApplied = true;
            }
    
            return {
                id: item.rewardId,
                name: item.name,
                value: item.value,
                originalValue: item.originalValue,
                images: item.image,
                quantity: item.quantity,
                total: item.quantity * item.value
            }
        })
    }

    discount = originalValue - total;

    return {
        total: total,
        originalTotal: originalValue,
        showPoints: true,
        items: items,
        quantity: quantity,
        coupon: {
            applied: json.coupon !== null,
            hasAnyCouponApplied: hasAnyCouponApplied,
            couponId: json.coupon && json.coupon.couponId ? json.coupon.couponId : null,
            couponDefault: json.coupon && json.coupon.couponDefault ? json.coupon.couponDefault : false,
            couponType: json.coupon && json.coupon.couponType === 'Desconto' ? 'discount' : 'cashback',
            couponTotalDiscount: discount,
            couponCashback: json.coupon && json.coupon.couponCashback ? json.coupon.couponCashback : null,
            couponInfo: json.coupon && json.coupon.couponInfo ? json.coupon.couponInfo : null
        }
    };
}

export default ShoppingCartFacades;