import StoreResumeCard from '../atom/StoreResumeCard';
import { ShoppingCartCouponInfo } from '../components/ShoppingCartResumeCoupon';

const CheckoutResume = ({className, style, data, showCouponData}) => {
    if(typeof data !== "object") {
        data = [];
    }

    return (
        <StoreResumeCard className={className} style={style}>
            <h3>Resumo do pedido</h3>
            <hr/>
            {showCouponData && (
                <ShoppingCartCouponInfo />
            )}
            {data.map((item, index) => {
                return (
                    <div className="resumeRow" key={"resumeCardItem__"+index}>
                        <div className="row">
                            <span className={(item.super && typeof item.value === "undefined" ? "super" : "")}>{item.label || ""}</span>
                            <strong className={item.super ? "super" : ""}>{item.value}</strong>
                        </div>
                        {
                        (
                            index < (data.length - 1) && 
                            (typeof item.ommitDivisor === "undefined")
                        ) && (
                            <hr/>
                        )}
                    </div>
                )
            })}
        </StoreResumeCard>
    )
}

export default CheckoutResume;