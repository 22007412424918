// Loja integrada / home
import PageContainer from "../components/PageContainer";
import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import StoreHeader from "../components/StoreHeader";
import StoreCarousel from "../components/StoreCarousel";
import StoreProductList from "../components/StoreProductList";

const Store = () => {
  return (
    <PageContainer pageTitle={"Loja de Marketing | "+ process.env.REACT_APP_SITE_NAME } showHeader={false}>
      <StoreHeader />
      <ShoppingPageContainer className="singleColumn--md">
        <StoreCarousel />
        <StoreProductList />
      </ShoppingPageContainer>
    </PageContainer>
  );
};

export default Store;
