import styled from "styled-components";
import Breakpoints from '../config/Breakpoints';

const StoreResumeCard = styled.div`    
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    border-radius: .5rem;
    background: var(--color-xLightGray);

    & > .resumeRow {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }

    h3 {
        margin: 0;
        font-size: var(--font-size-xl);
        color: var(--color-blueGrayDark);
        line-height: 1em;
    }

    hr {
        height: 0;
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--color-lightGray);
    }

    .rowWrapper {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .row {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: var(--font-size-sm);
            color: var(--color-gray);

            &.super {
                font-size: var(--font-size-md);
                color: var(--color-blueGrayDark);
                font-weight: var(--font-weight-bold);
            }
        }

        strong {
            width: 30%;
            text-align: right;
            font-size: var(--font-size-sm);
            color: var(--color-black);

            &.super {
                font-size: var(--font-size-md);
            }

            &.link {
                color: var(--color-brand);
                cursor: pointer;
            }
        }
    }

    @media ${Breakpoints.md} {
        width: 23.75rem;
        align-self: self-start;
        position: sticky;
        top: 0;
        margin-top: 2.75rem;
    }
`

export default StoreResumeCard;