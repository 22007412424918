import { useState, useEffect, useContext } from 'react';
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import UserContext from "../context/UserContext";
import UserContextUpdate from '../context/UserContextUpdate';
import ShoppingCartContext from "../context/ShoppingCartContext";
import ShoppingCartFacades from "../helpers/ShoppingCartFacades";
import ShoppingCartContextUpdate from '../context/ShoppingCartContextUpdate';
import CalculatePointsSpend from '../helpers/StoreCalculatePointsSpend';

import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import PointsFormatUtil from '../helpers/PointFormatUtil';
import PageContainer from "../components/PageContainer";
import CheckoutHeader from "../components/CheckoutHeader";
import CheckoutResume from '../components/CheckoutResume';
import CheckoutForm from '../components/CheckoutForm';
import StoreFeedback from "../components/StoreFeedback";

import alertIcon from '../assets/icons/creditCardAlert.svg';

const Checkout = () => {
    const [loading, setLoading] = useState(true);
    const { setUser } = useContext(UserContext);
    const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext);
    const navigate = useNavigate();

    const pointsAfter = CalculatePointsSpend(shoppingCart.total);
    const buyAllowed = !(pointsAfter.points === null && pointsAfter.vmc === null);
    const [ resume, setResume ] = useState([
        {
            label: "Total",
            value: `${PointsFormatUtil(shoppingCart.total)} pontos`,
        },
    ]);

    // useEffect(() => {
    //     if(typeof shoppingCart.cupomPercent === 'number') {
    //         let cashbackTotal = Math.round(shoppingCartTotal * shoppingCart.cupomPercent).toFixed(0);
    //         setResume(prevItems => [...prevItems, {
    //             label: "Cashback",
    //             value: `${PointsFormatUtil(cashbackTotal)} pontos`,
    //         }]);
    //     }
    // },[shoppingCart.cupomPercent])

    useEffect(() => {
        UserContextUpdate((json) => {
            setUser(json);
        });

        ShoppingCartContextUpdate((json) => {
            let scFacade = ShoppingCartFacades(json);
            setShoppingCart(scFacade);
            setLoading(false);

            if(scFacade.quantity === 0) {
                navigate("/loja-de-marketing/carrinho");
            }
        });

    },[setUser, setLoading, setShoppingCart])

    useEffect(() => {
        setResume(prevItems => [...prevItems, {
                label: "Saldo após o resgate",
                super: true,
                ommitDivisor: true    
        }]);
        
        if(pointsAfter.points !== null) {
            setResume(prevItems => [...prevItems, {
                label: "Pontos",
                value: `${PointsFormatUtil(pointsAfter.points)} pontos`,
                ommitDivisor: true,
            }])
        }

        if(pointsAfter.vmc !== null) {
            setResume(prevItems => [...prevItems, {
                label: "Pontos VMC",
                value: `${PointsFormatUtil(pointsAfter.vmc)} pontos`,
                ommitDivisor: true,
            }])
        }
    },[])

    return (
        <PageContainer pageTitle={"Fazer pedido » Loja de Marketing | "+ process.env.REACT_APP_SITE_NAME} showHeader={false} showFooter={false}>
            {loading && (
                <div style={{
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress
                        size={48}
                        sx={{
                            color: "var(--color-brand)",
                        }}
                    />
                </div>
            )}
            {!loading && (
                <>
                    <CheckoutHeader backButton="Voltar para a loja" backButtonLink="/loja-de-marketing/carrinho" />
                    {!buyAllowed && (
                        <StoreFeedback 
                            icon={alertIcon} 
                            title="Você não possui saldo suficiente para fazer o resgate" 
                            button={{
                                label: "Voltar para o carrinho",
                                link: "/loja-de-marketing/carrinho"
                            }}
                        />
                    )}
                    {buyAllowed && (
                        <ShoppingPageContainer style={{justifyContent: "space-between", position: "relative"}}>
                            <CheckoutForm total={shoppingCart.total} products={shoppingCart} expendedPoints={pointsAfter}  />
                            <CheckoutResume data={resume} showCouponData={shoppingCart.coupon.hasAnyCouponApplied || false} style={{marginTop: "0"}} />
                        </ShoppingPageContainer>
                    )}
                </>
            )}            
        </PageContainer>
    )
}

export default Checkout;