// Loja / Carrinho
import PageContainer from "../components/PageContainer";
import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import StoreHeader from "../components/StoreHeader";
import { ShoppingCartItems } from "../components/ShoppingCartItems";
import ShoppingCartResume from "../components/ShoppingCartResume";

const ShoppingCart = () => {
  return (
    <PageContainer pageTitle={"Carrinho | "+ process.env.REACT_APP_SITE_NAME } showHeader={false}>
      <StoreHeader />
      
      <ShoppingPageContainer>
        <ShoppingCartItems />
        <ShoppingCartResume />
      </ShoppingPageContainer>
    </PageContainer>
  );
};

export default ShoppingCart;
