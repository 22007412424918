import styled from "styled-components";
import { Link } from "react-router-dom";
import Breakpoints from "../config/Breakpoints";
import highlightTroca from "../assets/images/highlight-troca.webp";
import bgSquares from "../assets/images/bg-squares--right-green.webp";
import arrowRight from "../assets/icons/linkArrowRightWhite.svg";

const HighlightTrocaBlock = styled(Link)`
    padding: 1.5rem 1.75rem;
    background: var(--color-brand) url(${bgSquares}) bottom right no-repeat;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-decoration: none;
    justify-content: center;

    & > img {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
    
    h2 {
        margin: 0;
        font-size: var(--font-size-xxl);
        font-weight: var(--font-weight-bold);
        color: var(--color-white);
        line-height: 1em;
    }

    .textBlock {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        justify-content: flex-start;
    }

    .fakeButton {
        display: inline-flex;
        width: fit-content;
        gap: .5rem;
        padding: .5rem 1rem;
        border-radius: 99px;
        background: var(--color-black);
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-bold);
        color: var(--color-white);

        img {
            width: 1rem;
        }
    }

    @media ${Breakpoints.max.sm} {
        text-align: center;

        .textBlock {
            align-items: center;
        }  
    }

    @media ${Breakpoints.only.sm} {
        padding-left: 12rem;
        padding-right: 12rem;
    }

    @media ${Breakpoints.md} {
        box-sizing: border-box;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 50%;

        & > img {
            max-width: 180px;
        }
    }

    @media ${Breakpoints.xl} {
        & > img {
            max-width: 262px;
        }
    }
`

const HighlightTroca = ({className}) => {
    const link = "/troca-de-pontos";

    return (
        <HighlightTrocaBlock to={link} className={className}>
            <img src={highlightTroca} alt="Prêmios" />
            <div className="textBlock">
                <h2>Troque seus pontos por produtos, serviços e viagens</h2>
                <span className="fakeButton">
                    Acesse o catálogo de prêmios
                    <img src={arrowRight} alt="" />
                </span>
            </div>
        </HighlightTrocaBlock>
    )
}

export default HighlightTroca;