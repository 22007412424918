import { useState, useEffect, useContext } from 'react';
import { CircularProgress } from "@mui/material";
import UserContext from "../context/UserContext";
import UserContextUpdate from '../context/UserContextUpdate';
import ShoppingCartContext from "../context/ShoppingCartContext";
import ShoppingCartFacades from "../helpers/ShoppingCartFacades";
import ShoppingCartContextUpdate from '../context/ShoppingCartContextUpdate';

import PageContainer from "../components/PageContainer";
import CheckoutHeader from "../components/CheckoutHeader";
import StoreFeedback from "../components/StoreFeedback";

import successIcon from '../assets/icons/packageBox.svg';

const CheckoutFeedback = () => {
    const [loading, setLoading] = useState(true);
    const { setUser } = useContext(UserContext);
    const { setShoppingCart } = useContext(ShoppingCartContext);

    useEffect(() => {
        UserContextUpdate((json) => {
            setUser(json);
        });

        ShoppingCartContextUpdate((json) => {
            let scFacade = ShoppingCartFacades(json);
            setShoppingCart(scFacade);
            setLoading(false);
        });

    },[setUser, setLoading, setShoppingCart])

    
    const button = {
        label: "Voltar para a loja",
        link: "/loja-de-marketing"
    }

    return (
        <PageContainer pageTitle={"Resgate de pontos no Cartão de Crédito | "+ process.env.REACT_APP_SITE_NAME} showHeader={false} showFooter={true}>
            {loading && (
                <div style={{
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress
                        size={48}
                        sx={{
                            color: "var(--color-brand)",
                        }}
                    />
                </div>
            )}
            {!loading && (
                <>
                    <CheckoutHeader backButton="Voltar para o loja" backButtonLink="/loja-de-marketing" />
                    <StoreFeedback icon={successIcon} title="Pedido concluído com sucesso" subtitle="Consulte detalhes do seu resgate em Pedidos." button={button} />
                </>
            )}
        </PageContainer>
    )
}

export default CheckoutFeedback;