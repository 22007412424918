import { Link } from "react-router-dom";
import styled from "styled-components";
import homeIcon from "../assets/icons/home.svg";
import arrowIcon from "../assets/icons/breadcrumbArrow.svg";

const Breadcrumb = ({className, style, items}) => {
  const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: .25rem;
    align-items: center;
    font-size: var(--font-size-sm);
    color: var(--color-blueGrayDark);

    & > div {
        display: flex;
        flex-direction: row;
        gap: .25rem;
        align-items: center;
    }
    
    a {
        color: var(--color-brand);
        text-decoration: none;
        font-weight: var(--font-weight-bold);
    }
  `

  return (
    <Container className={className} style={style}>
        {items.map((item, index) => {
            return (
                <div key={`breadcrumbItem__${index}`}>
                    {item.href && (
                        <Link to={item.href}>
                            {index === 0 && (
                                <img src={homeIcon} alt="Voltar à home" />
                            )}
                            {index > 0 && (
                                <>{item.name}</>
                            )}
                        </Link>
                    )}
                    {typeof item.href === "undefined" && (
                        <span>{item.name}</span>
                    )}
                    {index < (items.length - 1) && (
                        <img src={arrowIcon} alt="" />
                    )}
                </div>
            )
        })}
    </Container>
  )
}

export default Breadcrumb;