import PageContainer from "../components/PageContainer";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import Statement from "../atom/Statement";

const Rules = () => {
  return (
    <PageContainer pageTitle={"Regulamento | "+ process.env.REACT_APP_SITE_NAME}>
      <PageHeader>
        Regulamento
      </PageHeader>
      <PageContent>
        <div className="contentArea full">
          <Statement />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default Rules;
