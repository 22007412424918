import Points from "../config/Points";

const CalculatePointsSpend = (total) => {    
    let rtn = {
      points: null,
      vmc: null,
      pointsExpended: null,
      vmcExpended: null,
      avaliablePoints: 0
    };
    let points = {
      points: null,
      vmc: null
    }

    Points().map(item => {
      if(item.id === "Point") {
        points.points = item.value;
        if(points.points > 0) {
          rtn.avaliablePoints = rtn.avaliablePoints + points.points;
        }
      }
      if(item.id === "Vmc") {
        points.vmc = item.value;
        if(points.vmc  > 0) {
          rtn.avaliablePoints = rtn.avaliablePoints + points.vmc;
        }
      }
      return true;
    });

    if(total <= rtn.avaliablePoints) {
      if(points.vmc !== null) {
        if(points.vmc > 0) {
          if(total >= points.vmc) {
            rtn.vmc = 0;
            rtn.points = points.points - (total - points.vmc);
            rtn.vmcExpended = points.vmc;
            rtn.pointsExpended = (total - rtn.vmcExpended);
          } else {
            rtn.points = points.points;
            rtn.vmc = points.vmc - total;
            rtn.pointsExpended = 0;
            rtn.vmcExpended = total;
          }
        } else {
          rtn.points = points.points - total;
          rtn.pointsExpended = total;  
          rtn.vmc = points.vmc;
          rtn.vmcExpended = 0;
        }
      } else {
        rtn.points = points.points - total;
        rtn.pointsExpended = total;
      }
    }

    return rtn;
}

export default CalculatePointsSpend;