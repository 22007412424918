import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CalculatePointsSpend from '../helpers/StoreCalculatePointsSpend';
import ShoppingCartContext from "../context/ShoppingCartContext";

import PointsFormatUtil from '../helpers/PointFormatUtil';
import StyledButton from '../atom/Button';
import StoreResumeCard from '../atom/StoreResumeCard';

import { ShoppingCartCouponInfo, ShoppingCartCouponForm } from '../components/ShoppingCartResumeCoupon';

import linkArrowRightWhite from '../assets/icons/linkArrowRightWhite.svg';
import linkArrowRight from '../assets/icons/linkArrowRight.svg';
// import CheckPermission from '../helpers/FeaturePermission';

const ShoppingCartResume = ({className}) => {
    const { shoppingCart } = useContext(ShoppingCartContext);
    
    let pointsAfter = CalculatePointsSpend(shoppingCart.total || 0);
    const buyAllowed = !(pointsAfter.points === null && pointsAfter.vmc === null);


    useEffect(() => {
        // console.log(pointsAfter);

    },[shoppingCart]);
    
    return (
      <StoreResumeCard>
        <h3>Resumo do pedido</h3>
        <hr />
        {shoppingCart.coupon.applied && (
            <ShoppingCartCouponInfo />
        )}
        
        <div className="rowWrapper">
            <div className="row">
                <span>Total</span>
                <strong className="super">
                    {PointsFormatUtil(shoppingCart.total || 0)} pontos
                </strong>
            </div>
        </div>

        <ShoppingCartCouponForm />
        
        {(shoppingCart.total > 0) && (
            <>
            <StyledButton component={Link} to="/loja-de-marketing/checkout" disabled={!buyAllowed} style={{borderRadius: ".5rem", width: "100%"}}>
                Continuar 
                <img src={linkArrowRightWhite} alt="" style={{width: "1.5rem"}} />
            </StyledButton>
            <StyledButton component={Link} to="/loja-de-marketing" className="secondary" style={{borderRadius: ".5rem", width: "100%"}}>
                <img src={linkArrowRight} alt="" style={{transform: "rotate(180deg)", width: "1.5rem"}} />
                Adicionar mais itens
            </StyledButton>
            </>
        )}

        {(shoppingCart.total > 0 && shoppingCart.showPoints) && (
            <>
                <hr/>
                <div className="rowWrapper">
                    {(pointsAfter.points !== null || pointsAfter.vmc !== null) && (
                        <>
                            <div className="row">
                                <span className="super">Saldo após o resgate</span>
                            </div>
                            {pointsAfter.points !== null && (
                                <div className="row">
                                    <span>Pontos</span>
                                    <strong>{PointsFormatUtil(pointsAfter.points)} pontos</strong>
                                </div>
                            )}
                            {pointsAfter.vmc !== null && (
                                <div className="row">
                                    <span>Pontos VMC</span>
                                    <strong>{PointsFormatUtil(pointsAfter.vmc)} pontos</strong>
                                </div>
                            )}
                        </>
                    )}
                    {!buyAllowed && (
                        <div className="row">
                            <span><em>Você não tem saldo suficiente</em></span>
                        </div>
                    )}
                </div>
            </>
        )}
      </StoreResumeCard>
    )
  
}

export default ShoppingCartResume;