import { Button } from "@mui/material";
import styled from "styled-components";

const StyledButton = styled(Button)`
    &.MuiButtonBase-root {
        display: inline-flex;
        width: fit-content;
        font-family: var(--font-family);
        text-transform: none;
        border-radius: 99px;
        gap: .625rem;
        align-items: center;
        padding: .75rem 1.5rem;
        background-color: var(--color-brand);
        color: var(--color-white);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);

        &:hover {
            background-color: var(--color-brand);
        }

        &.secondary {
            border: 1px solid var(--color-brand);
            color: var(--color-brand);
            background: none;
        }

        &.small {
            padding: .5rem 1.5rem;
            font-size: var(--font-size-md);
        }

        &.xsmall {
            padding-left: 4px;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-right: 4px;
            font-size: 14px;
            border-radius: 4px;
        }

        &[aria-disabled='true'],
        &:disabled {
            opacity: .5;
            color: var(--color-white);
        }
    }
`

export default StyledButton;