import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import { LogoImg } from "../atom/Logo";
import ExitButton from "../atom/ExitButton";

import alertSkeleton from "../assets/icons/alertSkeleton.svg"

const UserErrorBlock = styled.div`
    height: calc(100vh - 20rem);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    padding: 1.5rem;
    color: var(--color-darkgray);
    
    img {
        margin: 0 auto;
        width: 3rem;
    }

    h2 {
        margin: 0;
        font-size: var(--font-size-2xl);
    }

    p {
      margin-bottom: 1.5rem;
    }

    & > .textBlock > div {
      margin-bottom: 1rem;
    }

    a {
      color: var(--color-brand);
      font-weight: var(--font-weight-bold);
      text-decoration: none;
    }

    @media ${Breakpoints.md} {
        height: calc(100vh - 25rem);
        margin: 1.5rem 3rem 2.5rem;
        align-items: center;
        justify-content: space-around;
        gap: 2rem;

        & > .imgBlock {
          width: 30vw;
          text-align: center;

          & > img {
              width: 10rem;
          }
        }

        & > .textBlock {
            flex: 1;
            width: fit-content;
        }

        p {
            margin: 1rem 0 0;
        }
    }
`

const BlockHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  border-bottom: 1px solid var(--color-lightGray);
  margin: 0 0 2.5rem;

  @media ${Breakpoints.md} {
    margin: 1.5rem 3rem 2.5rem;
    padding: 2rem 2.5rem;
  }
`

const ErrorPage = ({title, description, showLink, children}) => {
  return (
    <>
      <HelmetProvider>
          <Helmet>
            <title>
              Ops, algo deu errado | {process.env.REACT_APP_SITE_NAME}
            </title>
          </Helmet>
      </HelmetProvider>
      <BlockHeader>
        <div>
            <LogoImg/> 
        </div>
        <div>
            <ExitButton style={{flex: "0"}} />
        </div>
      </BlockHeader>
      <UserErrorBlock>
        <div className="imgBlock">
          <img src={alertSkeleton} alt="" />
        </div>
        <div className="textBlock">
            <h2>{title}</h2>
            {description && (
              <p>{description}</p>
            )}
            {children}
            {showLink && (
              <Link to="/" style={{
                color: "var(--color-brand)",
                fontWeight: "var(--font-weight-bold)",
                textDecoration: "none"
              }}>Voltar para home</Link>
            )}
        </div>
      </UserErrorBlock>
      <Footer />
    </>
  );
};

export default ErrorPage;