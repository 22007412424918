import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import CheckPermission from "../helpers/FeaturePermission";
import downArrow from "../assets/icons/downArrow.svg";

const NavItems = ({ label, href, menuItems, icon, className, target, permission }) => {
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 1024));

    React.useEffect(() => {
        window.addEventListener("resize", () => setIsMobile((window.innerWidth <= 1024)));
    });

    const [showSubmenu, setShowSubmenu] = useState(false);

    const toggleSubmenu = () => {
        setShowSubmenu(!showSubmenu);
    }

    const resetBodyOverflow = () => {
        document.querySelector("body").classList.remove("overflow-hidden");
    }

    let submenuItemsRender = () => {
        return menuItems.map(
            (item, index) =>
            <NavItem key={"submenu_" + index} label={item.name} menuItems={item.children} href={item.href} icon={item.icon} className={item.className} target={item.target} permission={item.permission}></NavItem>
        )
    }

    let iconRender = () => {
        if(icon != null) {
            return (
                <img src={icon} alt="" className="menuIcon" />
            )
        }
    }

    let linkRender = () => {
        if(menuItems.length > 0) {
            if(!isMobile) {
                return (
                    <div onMouseEnter={toggleSubmenu} onMouseLeave={toggleSubmenu} className={(showSubmenu ? 'isVisible' : '')}>
                        <Link>
                            {iconRender()}
                            {label}
                            <img src={downArrow} className="downArrow" alt="" />
                        </Link>
                        <ul className={(showSubmenu ? 'isVisible' : '')}>
                            {submenuItemsRender()}
                        </ul>
                    </div>
                )
        } else {
            return (
                <div onClick={toggleSubmenu} className={(showSubmenu ? 'isVisible' : '')}>
                    <Link>
                        {iconRender()}
                        {label}
                        <img src={downArrow} className="downArrow" alt="" />
                    </Link>
                    <ul className={(showSubmenu ? 'isVisible' : '')}>
                        {submenuItemsRender()}
                    </ul>
                </div>
            )
        }
        } else {
            if(typeof href == "string") {
                return (
                    <Link to={href} target={target} onClick={resetBodyOverflow}>
                        {iconRender()}
                        {label}
                    </Link>
                )
            } else if(typeof href == "function") {
                return (
                    <Link onClick={href}>
                        {iconRender()}
                        {label}
                    </Link>
                )
            }
            
        }
    }

    let visible = true;
    if(typeof permission === "object" && permission !== null) {
        visible = CheckPermission(permission);
    }

    return (
        <>
        {visible && (
            <li className={className}>
                {linkRender()}
            </li>
        )}
        </>
    )
};

const NavItem = styled(NavItems)`
    position: relative;

    & a {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        color: var(--color-black);
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-semibold);
        line-height: 1.125em;
        text-decoration: none;

        .downArrow {
            transition: transform .2s ease;
            transform-origin: center;
        }
    }

    .menuIcon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .5rem;
    }

    ul {
        transition: all .3s ease-in;
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .isVisible {
        a .downArrow {
            transform: rotate(180deg);
        }
    }

    @media ${Breakpoints.max.sm} {
        & a {
            padding: 1rem 0;
            border-bottom: 1px solid var(--color-lightGray);
            width: 100%;
        }

        &:last-child > div > a,
        &:last-child > a {
            border-bottom: none;
        }

        ul {
            padding-left: 2rem;
        }

        .isVisible {
            a .downArrow {
                transform: rotate(180deg);
            }
            ul {
                max-height: 100vh;
                overflow: visible;
            }
        }
    }

    @media ${Breakpoints.md} {
        & > div {
            height: 1.875rem;
        }

        ul {
            position: absolute;
            background: var(--color-darkgray);
            color: var(--color-white);
            border-radius: .25rem;
            padding: .5rem 1.5rem;
            left: 50%;
            transform: translate(-50%,2rem);
            top: 1.75rem;
            opacity: 0;
            pointer-events: none;
            z-index: 92;

            &:before {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                border-left: transparent solid .4rem;
                border-right: transparent solid .4rem;
                border-bottom: var(--color-darkgray) solid .4rem;
            }

            a {
                display: block;
                color: var(--color-white);
                padding: .75rem .25rem .75em 0;
                border-bottom: 1px solid rgba(255,255,255,.1);
                white-space: nowrap;
            }

            li:last-child > a {
                border-bottom: none;
            }
        }

        .isVisible {
            ul {
                max-height: 100vh;
                overflow: visible;
                transform: translate(-50%,0);
                opacity: 1;
                pointer-events: initial;
            }
        }
    }
`

const MenuItems = ({ menuItems, className }) => {
    let MenuItensRender = () => {
        let menu = [];
        if(typeof menuItems == "function") {
            menu = menuItems();
        } else {
            menu = menuItems;
        }

        return menu.map(
            (item, index) => 
            <NavItem key={'navItem_' + index}  label={item.name} href={item.href} menuItems={item.children} icon={item.icon} className={item.className} target={item.target || "_self"} permission={item.permission || null}></NavItem>
        )
    }

    return (
        <ul className={className}>
            {MenuItensRender()}   
        </ul>
    )
};

const MainMenu = styled(MenuItems)`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;

    @media ${Breakpoints.max.sm} {
        padding: 1rem 5rem;
        gap: 0;

        &.mainMenu {
            background: var(--color-xLightGray);
        }

        &.userMenu {
            border-bottom: 1px solid var(--color-lightGray);
        }
    }

    @media ${Breakpoints.lg} {
        gap: 3rem;
    }

    @media ${Breakpoints.xl} {
        gap: 4rem;
    }
`

export default MainMenu;