import ResourceStaticReplaceUtil from "./StaticReplacementUtil";

const BannersFacades = (json) => {
    return json.map(item => {
        let img = null;

        if(item.images.web.ContentDownloadUrl) {
            img = ResourceStaticReplaceUtil(item.images.web.ContentDownloadUrl);
        }

        return {
            image: item.images?.web?.ContentDocument?.LatestPublishedVersion?.ContentUrl || null,
            imageMobile: item.images?.mobile?.ContentDocument?.LatestPublishedVersion?.ContentUrl || null,
            link: item.banner?.V_LandingPage__c || null,
            text: item.banner?.V_Container__c || null
        }
    });
}

export default BannersFacades;