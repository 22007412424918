import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import MuiBlock from "../atom/MuiCustoms";
import Breakpoints from "../config/Breakpoints";
import PageContainer from "../components/PageContainer";
import PageHeader from "../atom/PageHeader";
import PointCard from "../components/PointCard";
import PointStatementReport from "../components/PointStatementReport";
import Points from "../config/Points";
import styled from "styled-components";

// import PlusSign from "../assets/icons/plusSign.svg";
// import MinusSign from "../assets/icons/minusSign.svg";


const PointsStatementBlock = ({point}) => {
  const [tab, setTab] = useState("statement");

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const StyledTab = styled(Tab)`
    text-transform: none !important;
    font-weight: var(--font-weight-bold) !important;
    font-size: 1rem;
    color: var(--color-blueGrayDark);

    &.Mui-selected {
      color: var(--color-brand);
    }

    @media ${Breakpoints.md} {
      &:first-child {
        margin-left: 2rem;
      }
    }
  `

  const StyledPanel = styled(TabPanel)`
    padding: 0 !important;
    flex-grow: 1;
    display: flex;

    &[hidden] {
      display: none;
    }
  `

  return (
    <MuiBlock style={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
      <TabContext value={tab}>
        <TabList onChange={handleTab} aria-label="">
          <StyledTab label="Extrato detalhado" value="statement" />
          <StyledTab label="Pontos a expirar" value="expiring" />
        </TabList>
        <StyledPanel value="statement">
          <PointStatementReport pointType={point} mode="statement" />
        </StyledPanel>
        <StyledPanel value="expiring">
          <PointStatementReport pointType={point} mode="expiring" />
        </StyledPanel>
      </TabContext>
    </MuiBlock>
  )
}

const PointsStatement = () => {
  let points = Points();
  const [selectedPoint, setSelectedPoint] = useState(points[0].id);

  let PointCardListItem = styled(PointCard)`
    width: 100% !important;
    box-sizing: border-box;
    transition: all .3s ease;
    box-shadow: none;
    border: 1px solid var(--color-lightGray);
    padding: 1rem;
    width: auto !important;
    cursor: pointer;

    &.selected {
      border: 2px solid var(--color-brand);
      border-bottom-left-radius: .75rem;
      border-bottom-right-radius: .75rem;
      box-shadow: 0 6px 0 -3px var(--color-brand);
      padding: calc(1rem - 1px);
    }
  `

  let PointsRender = () => {
    return points.map(
      (item, index) =>
        <PointCardListItem
          className={`pointcard ${(selectedPoint === item.id ? "selected" : "")}`}
          key={"pointcard_" + index}
          label={item.name}
          value={item.value}
          expiring={item.expiring}
          definition={item.definition}
          onClick={() => setSelectedPoint(item.id)}
      />
    )
  }

  let PointStatementContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .pointList {
      overflow: auto;
      margin: 0 0 0 1.5rem;
      padding-bottom: 1rem;
    }

    .pointList__container {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      @media ${Breakpoints.md} {
        flex-direction: column;
      }
    }
    
    @media ${Breakpoints.max.xs} {
      .pointList {
        width: calc(100% - 1.5rem);

        &__container {
          flex-wrap: nowrap;
          padding-right: 1.5rem;
        }
      }

      .pointcard {
        flex-grow: 1;
        flex-basis: 12rem;
        width: 12rem;
        flex-shrink: 0;
      }
    }

    .statementBlock {
      margin: 0 1.5rem;
    }
    
    @media ${Breakpoints.md} {
      flex-direction: row;
      margin: 0 3rem 2.5rem;

      .pointList {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 17rem;
        padding-top: 3rem;
        margin: 0;
      }

      .statementBlock {
        flex-grow: 1;
        display: flex;
        margin: 0;
      }
    }
  `

  return (
    <PageContainer pageTitle={"Extrato de pontos | "+ process.env.REACT_APP_SITE_NAME }>
      <PageHeader>Extrato de pontos</PageHeader>

      <PointStatementContainer>
        <div className="pointList">
            <div className="pointList__container">
              {PointsRender()}
            </div>
        </div>
        <div className="statementBlock">
          <PointsStatementBlock point={selectedPoint} />
        </div>  
      </PointStatementContainer>

    </PageContainer>
  );
};

export default PointsStatement;
