import { useContext } from "react";
import DialogContext from '../context/DialogContext';
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import UserProfileImg from "../assets/icons/userProfile.svg";

const Menu = () => {
    const environment = process.env.VERCEL_ENV || "development";

    // default
    let menu = [
        {
            icon: null,
            name: "Página inicial",
            href: "/home",
            target: "_self",
            children: [],
            className: null,
            permission: null,
        },
        {
            icon: null,
            name: "Acumule Pontos",
            href: "/acumule-pontos",
            target: "_self",
            children: [],
            className: null,
            permission: null
        },
        {
            icon: null,
            name: "Troque seus pontos",
            href: "",
            target: "_self",
            children: [
                {
                    icon: null,
                    name: "Loja de Marketing",
                    href: "/loja-de-marketing",
                    target: "_blank",
                    children: [],
                    className: null,
                    permission: null
                },
                {
                    icon: null,
                    name: "Por produtos",
                    href: "/troca-por-produtos",
                    target: "_blank",
                    children: [],
                    className: null,
                    permission: null
                },
                {
                    icon: null,
                    name: "Por serviços",
                    href: "/troca-por-servicos",
                    target: "_blank",
                    children: [],
                    className: null,
                    permission: null
                },
                {
                    icon: null,
                    name: "Por viagens",
                    href: "/troca-por-viagens",
                    target: "_blank",
                    children: [],
                    className: null,
                    permission: null
                },
                {
                    icon: null,
                    name: "Cartão de Crédito",
                    href: "/cartao-de-credito",
                    target: "_self",
                    children: [],
                    className: null,
                    permission: [
                        "CARTAO_LIKE_MAIS"
                    ]
                },
            ],
            className: null,
            permission: null,
        },
        {
            icon: null,
            name: "Saiba mais",
            href: "",
            target: "_self",
            children: [
            {
                icon: null,
                name: "Regulamento",
                href: "/regulamento",
                target: "_self",
                children: [],
                className: null,
                permission: null,
            },
            {
                icon: null,
                name: "Dúvidas frequentes",
                href: "/duvidas-frequentes",
                target: "_self",
                children: [],
                className: null,
                permission: null,
            },
            {
                icon: null,
                name: "Baixe o app",
                href: "/baixe-o-app",
                target: "_self",
                children: [],
                className: null,
                permission: null,
            },
            {
                icon: null,
                name: "Fale conosco",
                href: "/fale-conosco",
                target: "_self",
                children: [],
                className: null,
                permission: null,
            },
            ],
            className: null,
            permission: null,
        },
    ];

    // only dev environment
    // if(environment === "development") {
    //     menu = [
    //         {
    //             icon: null,
    //             name: "Página inicial",
    //             href: "/home",
    //             target: "_self",
    //             children: [],
    //             className: null,
    //             permission: null,
    //         },
    //         {
    //             icon: null,
    //             name: "Acumule Pontos",
    //             href: "/acumule-pontos",
    //             target: "_self",
    //             children: [],
    //             className: null,
    //             permission: null
    //         },
    //         {
    //             icon: null,
    //             name: "Troque seus pontos",
    //             href: "",
    //             target: "_self",
    //             children: [
    //                 {
    //                     icon: null,
    //                     name: "Loja de Marketing",
    //                     href: "/loja-de-marketing",
    //                     target: "_blank",
    //                     children: [],
    //                     className: null,
    //                     permission: null
    //                 },
    //                 {
    //                     icon: null,
    //                     name: "Por produtos",
    //                     href: "/troca-por-produtos",
    //                     target: "_blank",
    //                     children: [],
    //                     className: null,
    //                     permission: null
    //                 },
    //                 {
    //                     icon: null,
    //                     name: "Por serviços",
    //                     href: "/troca-por-servicos",
    //                     target: "_blank",
    //                     children: [],
    //                     className: null,
    //                     permission: null
    //                 },
    //                 {
    //                     icon: null,
    //                     name: "Por viagens",
    //                     href: "/troca-por-viagens",
    //                     target: "_blank",
    //                     children: [],
    //                     className: null,
    //                     permission: null
    //                 },
    //                 {
    //                     icon: null,
    //                     name: "Cartão de Crédito",
    //                     href: "/cartao-de-credito",
    //                     target: "_self",
    //                     children: [],
    //                     className: null,
    //                     permission: [
    //                         "CARTAO_LIKE_MAIS"
    //                     ]
    //                 },
    //             ],
    //             className: null,
    //             permission: null,
    //         },
    //         {
    //             icon: null,
    //             name: "Saiba mais",
    //             href: "",
    //             target: "_self",
    //             children: [
    //             {
    //                 icon: null,
    //                 name: "Regulamento",
    //                 href: "/regulamento",
    //                 target: "_self",
    //                 children: [],
    //                 className: null,
    //                 permission: null,
    //             },
    //             {
    //                 icon: null,
    //                 name: "Dúvidas frequentes",
    //                 href: "/duvidas-frequentes",
    //                 target: "_self",
    //                 children: [],
    //                 className: null,
    //                 permission: null,
    //             },
    //             {
    //                 icon: null,
    //                 name: "Baixe o app",
    //                 href: "/baixe-o-app",
    //                 target: "_self",
    //                 children: [],
    //                 className: null,
    //                 permission: null,
    //             },
    //             {
    //                 icon: null,
    //                 name: "Fale conosco",
    //                 href: "/fale-conosco",
    //                 target: "_self",
    //                 children: [],
    //                 className: null,
    //                 permission: null,
    //             },
    //             ],
    //             className: null,
    //             permission: null,
    //         },
    //     ];
    // }

    return menu;
};

const UserMenu = () => {
    const environment = process.env.VERCEL_ENV || "development";
    const { setDialogOpened } = useContext(DialogContext);

    let openLogoutDialog = () => {
        setDialogOpened(true);
    }

    // default
    let menu = [
        {
            icon: UserProfileImg,
            name: "Minha conta",
            href: "",
            target: "_self",
            children: [
                {
                    icon: null,
                    name: "Extrato de pontos",
                    href: '/extrato-de-pontos',
                    target: "_self",
                    children: [],
                    className: "",
                    permission: null
                },
                {
                    icon: null,
                    name: "Pedidos",
                    href: "/pedidos",
                    target: "_self",
                    children: [],
                    className: "",
                    permission: null
                },
                {
                    icon: null,
                    name: "Sair",
                    href: openLogoutDialog,
                    target: "_self",
                    children: [],
                    className: "exit",
                    permission: null
                },
            ],
            className: null,
            permission: null,
        }
    ];

    // only dev environment
    // if(environment === "development") {
    //     menu = [
    //         {
    //             icon: UserProfileImg,
    //             name: "Minha conta",
    //             href: "",
    //             target: "_self",
    //             children: [
    //                 {
    //                     icon: null,
    //                     name: "Extrato de pontos",
    //                     href: '/extrato-de-pontos',
    //                     target: "_self",
    //                     children: [],
    //                     className: "",
    //                     permission: null
    //                 },
    //                 {
    //                     icon: null,
    //                     name: "Sair",
    //                     href: openLogoutDialog,
    //                     target: "_self",
    //                     children: [],
    //                     className: "exit",
    //                     permission: null
    //                 },
    //             ],
    //             className: null,
    //             permission: null,
    //         }
    //     ]
    // }

    return menu;
}

let SocialMenu = [
    { name: "Facebook", icon: Facebook, href: "https://www.facebook.com/souparceirointelbras" },
    { name: "Instagram", icon: Instagram, href: "https://www.instagram.com/souparceirointelbras/" },
]

export { Menu, UserMenu, SocialMenu };
