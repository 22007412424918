import React, { useState, useEffect } from 'react';
import Breakpoints from '../config/Breakpoints';
import PrepareFetch from "../helpers/PrepareFetch";
import styled from "styled-components";
import { Alert, Snackbar, CircularProgress, Skeleton } from "@mui/material";

import ResourceStaticReplaceUtil from "../helpers/StaticReplacementUtil";
// import ReactMarkdown from "react-markdown"
// import remarkGfm from "remark-gfm"
// import Regulamento from "../assets/documents/regulamento.md"

const StatementBlock = styled.div`
    position: relative;
    min-height: calc(100vh - 10rem);

    .MuiSkeleton-root {
        position: absolute;
        z-index: 1;
    }

    iframe {
        position: absolute;
        z-index: 2;
        overflow: auto;
        border: none;
    }

    .MuiSkeleton-root,
    iframe {
        width: 100% !important;
        height: 100%;
        min-height: calc(100vh - 10rem);
        border-radius: .5rem;
    }


    @media ${Breakpoints.md} {
        min-height: calc(100vh - 20rem);

        .MuiSkeleton-root,
        iframe {
            min-height: calc(100vh - 20rem);
        }
    }
`

const Statement = ({className}) => {
    const [statement, setStatement] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        (async () => {
            let fetchParams = PrepareFetch("/proxy/services/apexrest/api/v1/agreement","GET",{
                "cache-by": "user"
            });
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                            return response.json();
                        }

                        throw new Error(response);
                    })
                    .then(json => {
                        setLoading(false);
                        setStatement(ResourceStaticReplaceUtil(json.urlPdf));
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
        })();
    }, []);

    return (
        <StatementBlock className={className}>
            { (!loading && !error) && (
                <>
                    <Skeleton variant="rectangular" />
                    <iframe src={statement} title="Regulamento" />
                </>
            )}
            { loading && (
                <CircularProgress
                    size={36}
                    sx={{
                        color: "var(--color-brand)",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        margin: "-12px 0 0 -12px"
                    }}
                />
            )}
            { error && (
                <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                        Houve um problema ao carregar o regulamento.
                    </Alert>
                </Snackbar>
            )}
            
        </StatementBlock>
    )
}

export default Statement;