import { Link } from "react-router-dom";
import styled from "styled-components";
import PointsFormatUtil from "../helpers/PointFormatUtil";
import noImage from "../assets/images/store-noPicture.png";

// [TODO] -> passar as informacoes pra dentro do ProductCard
// [TODO] -> Mover as informacoes para a esquerda, possivelmente trocar para GRID?

const ProductCard = ({className, id, images, name, value}) => {
  const ProductCardContainer = styled(Link)`
    transition: all .3s ease-in;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    border-radius: .5rem;
    border: 1px solid var(--color-lightGray);
    text-decoration: none;
    overflow: hidden;

    &:hover {
      border-color: var(--color-brand);
      box-shadow: 0 0 0 1px var(--color-brand);
    }

    img {
      width: 100%;
      max-width: 12.5rem;
      align-self: center;
      margin-top: .5rem;
    }

    .infoBlock {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem 1.5rem 1.5rem;
      gap: 1rem;
    }

    .productTitle small,
    .price small {
      font-size: .625rem;
      text-transform: uppercase;
      letter-spacing: .8px;
      font-weight: var(--font-weight-bold);
    }

    .productTitle strong,
    .price strong {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      line-height: var(--font-size-xl);
    }

    .productTitle {
      display: flex;
      flex-direction: column;
      gap: .25rem;
      flex-grow: 1;
      color: var(--color-black);

      small {
        color: var(--color-brand);
      }
    }

    .price {
      display: inline-flex;
      gap: .5rem;
      align-items: baseline;
      color: var(--color-brand);
    }
  `

  return (
    <ProductCardContainer className={className} to={`/loja-de-marketing/produto/${id}`}>
      {images === null && (
        <img src={noImage} alt="" />
      )}
      {(typeof images === "object" && images !== null && images.length > 0) && (
        <img src={images[0].url} alt="" />
      )}

      <div className="infoBlock">
        <div className="productTitle">
          <small>Intelbras</small>
          <strong>{name}</strong>
        </div>
        <div className="price">
          {value !== null && (
            <>
              <strong>{PointsFormatUtil(value)}</strong>
              <small>pontos</small>
            </>
          )}
        </div>
      </div>
    </ProductCardContainer>
  );
};

export default ProductCard;
