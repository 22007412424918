import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Checkbox, FormGroup, FormControlLabel, Button, Alert, Snackbar, CircularProgress } from "@mui/material";
import UserContext from "../context/UserContext";
import MainAppContext from '../context/MainAppContext';
import styled from "styled-components";
import PrepareFetch from '../helpers/PrepareFetch';
import Breakpoints from "../config/Breakpoints";
import Statement from "../atom/Statement";
import MuiBlock from "../atom/MuiCustoms";

const ContainerBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 1.5rem;

    @media ${Breakpoints.md} {
        margin: 0 3rem;
    }
`

const ContainerTitle = styled.h1`
    margin: 0;
    font-size: var(--font-size-lg);
    color: var(--color-blueGrayDark);
    font-weight: var(--font-weight-semibold);
    letter-spacing: -.4px;
`

const Frame = styled.div`
    border-radius: .5rem .5rem 0 .5rem;
    border: 1px solid var(--color-lightGray);
    overflow: hidden;
    height: calc(100vh - 10rem);

    @media ${Breakpoints.md} {
        height: calc(100vh - 25rem);
    }
`

const AcceptStatement = styled(Statement)`
    @media ${Breakpoints.md} {
        &.accept {
            min-height: calc(100vh - 25rem) !important;

            iframe {
                min-height: calc(100vh - 25rem) !important;
            }
        }
    }
`

const AcceptBar = styled.div`
    background-color: var(--color-brand);
    color: var(--color-white);
    padding: 1rem;
    border-radius: 0 0 .5rem .5rem;

    .formArea {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttonBlock {
        position: relative;
        display: inline-flex;
    }

    .checkboxArea {
        flex-grow: 1;
    }

    @media ${Breakpoints.md} {
        padding: 1rem 3.875rem;

        .formArea {
            flex-direction: row;
        }
    }
`

const AcceptBlock = () => {
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/agreement/accept");
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const { setNeedsAgreement } = useContext(MainAppContext);

    const updateUserRegulationsAcceptance = () => {
        // user.member.V_NeedAcceptAgreement__c = false;
        // navigate("/home");
        setLoading(true);
        setDisabled(true);

        try {
            fetch(fetchData.url, fetchData.options)
            .then(response => {
                if(response.status === 200) {
                    setLoading(false);
                    setDisabled(false);

                    user.member.V_NeedAcceptAgreement__c = false;
                    setNeedsAgreement(false);
                    
                    navigate("/home");
                }

                throw new Error(response);
            })
            .catch(error => {
                setLoading(false);
                setError(true);
                setDisabled(false);

                console.log("Erro ao gravar aceite");
                if(process.env.VERCEL_ENV !== "production") {
                    console.log(error);
                }
            });
        } catch (error) {
            setLoading(false);
            setError(true);
            setDisabled(false);
            
            console.log("Erro ao gravar aceite");
            if(process.env.VERCEL_ENV !== "production") {
                console.log(error);
            }
        }
    };

    return (
        <ContainerBlock>
            <ContainerTitle>Para continuar, é preciso aceitar o regulamento do programa</ContainerTitle>

            <div>
                <Frame>
                    <AcceptStatement className="accept" />
                </Frame>
                <AcceptBar>
                    <MuiBlock>
                        <div className="formArea">
                            <FormGroup className="checkboxArea">
                                <FormControlLabel 
                                    className="acceptCheckbox"
                                    control={<Checkbox onChange={() => setDisabled(!disabled)} disabled={loading} />} 
                                    label="Li e aceito o regulamento" 
                                />
                            </FormGroup>
                            <MuiBlock className="buttonBlock">
                                <Button 
                                    className="acceptButton"
                                    disabled={disabled}
                                    onClick={updateUserRegulationsAcceptance}
                                >
                                    Continuar
                                </Button>
                                { loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "var(--color-white)",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            margin: "-12px 0 0 -12px"
                                        }}
                                    />
                                )}
                                { error && (
                                    <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                        <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                                            Houve um problema ao processar seu pedido.
                                        </Alert>
                                    </Snackbar>
                                )}
                            </MuiBlock>
                        </div>
                    </MuiBlock>
                </AcceptBar>
            </div>
        </ContainerBlock>
    )
}

export default AcceptBlock;