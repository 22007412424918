import styled from "styled-components";
import DOMPurify from 'dompurify'
import Breakpoints from "../config/Breakpoints";
import { ProductCarousel } from "./Carousel";
import noImage from "../assets/images/store-noPicture.png";

const ProductDescription = ({productData}) => {
    if(typeof productData.description === "undefined" || productData.description === null) {
      productData.description = "<em>Sem descrição</em>";
    }
  
    const Container = styled.div`
      padding: 1.5rem;
      border-radius: .5rem;
      border: 1px solid var(--color-lightGray);
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      & > .description {
        padding-top: 2rem;
        border-top: 1px solid var(--color-lightGray);
        font-size: var(--font-size-sm);

      }
  
      @media ${Breakpoints.md} {
        width: 70%;
      }
    `
  
    return (
      <Container>
        {productData.images === null && (
          <img src={noImage} alt="" style={{maxWidth: "50%", margin: "0 auto" }} />
        )}
        {(productData.images !== null && productData.images.length > 0) && (
          <ProductCarousel items={productData.images} />
        )}
        {productData.description !== "" && (
          <div className="description" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(productData.description.replace(/\n/g,"<br/>"))}}></div>
        )}
      </Container>
    )
  } 

  export default ProductDescription;