import { Link } from "react-router-dom";
import styled from "styled-components";
import CheckPermission from "../helpers/FeaturePermission";
import Breakpoints from "../config/Breakpoints";
import arrowRight from "../assets/icons/linkArrowRightWhite.svg";

const HighlightCardsBlock = styled.div`
    flex-grow: 1;
    flex-basis: 40%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    height: 100%;
    box-sizing: border-box;

    @media ${Breakpoints.md} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 2.5rem;
        align-items: stretch;
    }
`

const HighlightCardBlock = styled(Link)`
    box-sizing: border-box;
    flex-basis: 40%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem 8rem 2rem 1.75rem;
    height: 10.75rem;
    background-position: top left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: .5rem;
    color: var(--color-black);
    justify-content: space-between;
    text-decoration: none;
    gap: 1rem;
    max-width: 100%;

    strong {
        font-size: var(--font-size-md);
        line-height: 1em;
    }

    .button {
        display: inline-flex;
        gap: .5rem;
        padding: .5rem 1rem;
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-bold);
        border-radius: 10rem;
        align-items: center;
        background: var(--color-black);
        color: var(--color-white);
        flex: 0;
        white-space: nowrap;
        justify-content: center;
        width: fit-content;

        img {
            width: 1rem;
        }
    }

    &.text-right {
        padding-left: 8rem;
        padding-right: 1.75rem;
    }

    &.text-white {
        color: var(--color-white);
    }

    @media ${Breakpoints.md} {
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: auto;
    }

    @media ${Breakpoints.only.md} {
        min-height: 10rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
        padding-left: 2rem;
        padding-right: 13rem;

        &.text-right {
            padding-right: 2rem;
            padding-left: 13rem;
        }
    }

    @media ${Breakpoints.lg} {
        padding-left: 1rem;
        padding-right: 7rem;

        &.text-right {
            padding-right: 1rem;
            padding-left: 7rem;
        }
    }

    @media ${Breakpoints.xl} {
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-right: 8rem;

        &.text-right {
            padding-left: 8rem;
        }
    }

    @media ${Breakpoints.xxl} {
        padding-top: 3rem;
        padding-bottom: 4rem;
        padding-right: 12rem;

        &.text-right {
            padding-left: 10rem;
        }
    }
`

const HighlightCard = ({image, title, action, link, className, target}) => {
    if(typeof target == "undefined") {
        target = "_self";
    }

    return (
        <HighlightCardBlock className={className} to={link} target={target} style={{backgroundImage: `url(${image})`}}>
            <strong>{title}</strong>
            <span className="button">
                {action}
                <img src={arrowRight} alt="" />
            </span>
        </HighlightCardBlock>
    )
}

const HighlightCards = ({items}) => {
    return (
        <HighlightCardsBlock>
            { items.map(
                (item, index) => {
                    let visible = true;
                    if(typeof item.permission === "object" && item.permission !== null) {
                        visible = CheckPermission(item.permission);
                    }

                    if(visible) {
                        return (
                            <HighlightCard key={"highlightCard_" + index} image={item.image} className={item.style} title={item.text} action={item.action} link={item.link} target={item.target}></HighlightCard>
                        )
                    } 
                    
                    return false;
                }
            ) }
        </HighlightCardsBlock>
    )
}

export default HighlightCards;