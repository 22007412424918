import styled from "styled-components";
import { Link } from "react-router-dom";
import Breakpoints from "../config/Breakpoints";
import StyledButton from "../atom/Button";
import ConfettiBg from "../assets/images/cupom-confetti.svg";
import { useEffect, useState } from "react";

const StoreFeedback = ({icon, title, subtitle, button}) => {
    const [ cupomMessage, setCupomMessage ] = useState(null);

    useEffect(() => {
        if(sessionStorage.getItem('cupomFeedbackMessage') !== null) {
            setCupomMessage(sessionStorage.getItem('cupomFeedbackMessage'));
            sessionStorage.removeItem('cupomFeedbackMessage');
        }
    },[])

    const Container = styled.section`
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        @media ${Breakpoints.md} {
            max-width: 26rem;
            margin: 7.5rem auto;
        }
    `

    const IconBlock = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.625rem;
        height: 8.625rem;
        border-radius: 99px;
        background: var(--color-xLightGray);

        img {
            width: 5rem;
        }
    `

    const TextBlock = styled.div`
        display: flex;
        flex-direction: column;
        gap: .5rem;
        text-align: center;

        h3 {
            font-size: 1.75rem;
            color: var(--color-black);
            letter-spacing: -.02em;
            margin: 0;
            line-height: 1em;
        }

        p {
            font-size: var(--font-size-sm);
            color: var(--color-gray);
            margin: 0;
        }
    `

    const CupomInfo = styled.div`
        padding: 1rem;
        font-size: 1.25rem;
        color: var(--color-blueGrayDark);
        border: 1px solid var(--color-blueGrayLight);
        border-radius: .5rem;
        background: url(${ConfettiBg}) center right no-repeat;
        background-size: auto 100%;
        font-weight: var(--font-weight-bold);
        line-height: 1em;
        padding-right: 6rem;

        span {
            display: inline-block;
            border-radius: .25rem;
            background: var(--color-xLightGray);
            padding: .5rem;
            margin-top: .5rem;
        }

        @media ${Breakpoints.max.sm} {
            margin: 0 2rem;
        }
    `;

    return (
        <Container>
            {icon && (
                <IconBlock>
                    <img src={icon} alt="" />
                </IconBlock>
            )}
            <TextBlock>
                {title && (
                    <h3>{title}</h3>
                )}
                {subtitle && (
                    <p>{subtitle}</p>
                )}
            </TextBlock>
            {cupomMessage != null && (
                <CupomInfo dangerouslySetInnerHTML={{__html: cupomMessage}}>
                </CupomInfo>
            )}
            {button && (
                <StyledButton component={Link} to={button.link} className="secondary">
                    {button.label}
                </StyledButton>
            )}
            
        </Container>
    )
}

export default StoreFeedback;