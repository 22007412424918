import PageContainer from "../components/PageContainer";
import PointsDashboard from "../components/PointsDashboard";
import Highlights from "../components/HighlightsBlock";

const Home = () => {
  // redirecionar para accept se o user precisar
  // redirecionar para login se o user precisar

  return (
    <PageContainer pageTitle={"Home | "+ process.env.REACT_APP_SITE_NAME }>
      <PointsDashboard />
      <Highlights />
    </PageContainer>
  );
};

export default Home;
