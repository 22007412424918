import { useEffect, useState } from "react";
import styled from "styled-components";
import { HighlightCarousel } from "../atom/Carousel";
import PrepareFetch from "../helpers/PrepareFetch";
import BannersFacades from "../helpers/BannersFacades";
import Breakpoints from "../config/Breakpoints";
import { Skeleton } from "@mui/material";
import alertSkeleton from "../assets/icons/alertSkeleton.svg";

const StoreCarousel = ({className}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [carouselData, setCarouselData] = useState(null);

    const StoreCarouselBlock = styled.div`
        flex: 1;
        width: 100%;
        
        .warningBlock {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            height: auto;
            border-radius: .5rem;
            background: var(--color-lightGray);
            color: var(--color-gray);
            justify-content: center;
            align-items: center;
            aspect-ratio: 16/4;
        }
    `

    useEffect(() => {
        (async () => {
            let fetchParams = PrepareFetch("/proxy/services/apexrest/api/v1/banners?container=Store","GET",{
                "cache-by": "user"
            });
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                        return response.json();
                        }

                        throw new Error(response);
                    })
                    .then(json => {
                        setLoading(false);
                        setCarouselData(BannersFacades(json))
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
        })();
    },[]);

    return (
        <StoreCarouselBlock classNae={className}>
            {(!error && loading) && (
                <Skeleton
                    sx={{
                        aspectRatio: "16/4",
                        borderRadius: ".5rem",
                        height: "auto",
                        width: "100%"
                    }}
                    variant="rectangular"
                    width="100%"
                />
            )}
            {error && (
                <div className="warningBlock">
                    <img src={alertSkeleton} alt="" />
                    Não foi possível carregar o conteúdo.
                </div>
            )}
            {!error && !loading && (
                <HighlightCarousel items={carouselData} ratio="16/4" />
            )}
        </StoreCarouselBlock>
    )
}

export default StoreCarousel;