import { useState, useEffect } from 'react';
import styled from "styled-components";
import MuiBlock from '../atom/MuiCustoms';
import { CircularProgress, Snackbar, Alert } from "@mui/material";
import PrepareFetch from "../helpers/PrepareFetch";

const PremmiarSSO = ({startUrl}) => {
    if(typeof startUrl !== "undefined") {
        startUrl = "?startUrl="+startUrl;
    } else {
        startUrl = "";
    }

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        (async () => {
            let fetchParams = PrepareFetch("/proxy/services/apexrest/api/v1/members/ssoPremmiar"+startUrl);

            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                            return response.text();
                        }

                        throw new Error(response);
                    })
                    .then(url => {
                        window.location = url;
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
        })();
    },[startUrl]);

    let LoadingBlock = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        width: 100%;
        height: 100vh;
        text-align: center;
        font-size: var(--font-size-xs);
        color: var(--color-blueGrayDark);
    `

    return (
        <MuiBlock>
            { loading && (
                <LoadingBlock>
                    <CircularProgress
                        size={64}
                        sx={{
                            color: "var(--color-brand)",
                        }}
                    />
                    <span>Por favor, aguarde...</span>
                </LoadingBlock>
            )}
            
            { error && (
                <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                        Houve um problema ao processar seu pedido.
                    </Alert>
                </Snackbar>
            )}
        </MuiBlock>
    )
}

export default PremmiarSSO;