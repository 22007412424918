const DateFormatUtil = (date, config) => {
    if(date.indexOf("T") === -1) {
        date = `${date}T00:00:00`;
    }
    
    date = new Date(date);
    if(typeof config === "undefined") {
        config = {};
    }

    let defaultConfig = {
        day: "2-digit", 
        month: "2-digit",
        year: "numeric",
        timezone: "America/Sao_Paulo"
    };
    // verificar se virá uma data UTC
    return date.toLocaleString("pt-BR",{...defaultConfig, ...config});
}

export default DateFormatUtil;