import { Link } from "react-router-dom";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import Social from "../atom/Social";

const FooterBlock = styled.div`
  margin: 3rem 1.5rem 5rem;
  border-top: 1px solid var(--color-lightGray);
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${Breakpoints.md} {
    gap: 1rem;
    margin: 5rem 3rem 5rem;
    padding: 1.5rem 0;

  }
`

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;

  @media ${Breakpoints.md} {
    flex-direction: row;
    gap: 2.25rem;
  }
`

const FooterLinkBlock = styled.span`
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: 1.5rem;
  white-space: nowrap;

  a {
    transition: color .3s ease;
    color: var(--color-blueGrayDark);
    text-decoration: none;

    &:hover {
      color: var(--color-black);
    }
  }
`

const FooterLink = ({ href, label, target }) => {
  return (
      <FooterLinkBlock >
        <Link to={href} target={target}>
            {label}
        </Link>
      </FooterLinkBlock>
  );
}

const Footer = () => {
  let menuItems = [
    { name: "Regulamento", href: "/regulamento" },
    { name: "Política de privacidade", href: "https://termo.conta.intelbras.com/doc/public/Politica?lang=pt_BR&sp=User%20Accounts%20Portal", target: "_blank" },
    { name: "Dúvidas frequentes", href: "/duvidas-frequentes" },
    { name: "Fale conosco", href: "/fale-conosco" },
  ];

  let menuItemsRender = () => {
    return menuItems.map(
      (item, index) => <FooterLink key={'footerLink_' + index}  href={item.href} label={item.name} target={(item.target ? item.target : "_self")} />
    )
  }

  return (
    <FooterBlock>
      <FooterMenu>
          {menuItemsRender()}
      </FooterMenu>
      <Social />
    </FooterBlock>
  );
};

export default Footer;
