import React, { useState } from 'react';
import PageContainer from "../components/PageContainer";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import imgHighlightDesktop from "../assets/images/baixeApp-desktop.webp";
import imgHighlightMobile from "../assets/images/baixeApp-mobile.webp";
import AppDownload from "../atom/AppDownload";

const DowloadApp = () => {
  const [isMobile, setIsMobile] = useState((window.innerWidth <= 1024));

  React.useEffect(() => {
      window.addEventListener("resize", () => setIsMobile((window.innerWidth <= 1024)));
  });

  return (
      <PageContainer pageTitle={"Baixe o App | "+ process.env.REACT_APP_SITE_NAME}>
        <PageHeader>
          Baixe o app
        </PageHeader>

        <PageContent>
          <div className="imgArea">
            {isMobile && (
              <img src={imgHighlightMobile} alt="" />
            )}
            {!isMobile && (
              <img src={imgHighlightDesktop} alt="" />
            )}
          </div>
          <div className="contentArea">
            <h3>Acesse seu saldo e troque seus pontos de maneira fácil e rápida.</h3>
            <AppDownload />
          </div>
        </PageContent>

      </PageContainer>
  );
};

export default DowloadApp;
