import styled from "styled-components";
import CheckPermission from "../helpers/FeaturePermission";
import Breakpoints from '../config/Breakpoints';
import PageContainer from "../components/PageContainer";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import FaqHighlight from "../atom/FaqHighlight";
import imgHighlight__astec from "../assets/images/acumuleInfo--astec.webp";
import imgHighlight__provedor from "../assets/images/acumuleInfo--provedor.webp";
import imgHighlight__revenda_solucoes from "../assets/images/acumuleInfo--revenda_solucoes.webp";
import imgHighlight__revenda from "../assets/images/acumuleInfo--revenda.webp";
import imgHighlight__vendedor from "../assets/images/acumuleInfo--vendedor.webp";

// TODO -> Jogar "* Vendas exclusivas para a linha solar." para a direita
// TODO -> Ver dados para alterar os numeros das vendas

const PointFaqHighlight = styled(FaqHighlight)`
  margin-top: 2.25rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: auto;
  border-top: 1px solid var(--color-lightGray);
  padding-top: 2.25rem;

  @media ${Breakpoints.md} {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
    padding-top: 3rem;
  }
`

const PointRuleContent = styled.div`
  font-size: 1rem;
  line-height: 1.25em;
  color: var(--color-blueGrayDark);

  p {
    margin: 0 0 .75rem;
  }

  strong {
    color: var(--color-brand);   
  }
`

const PointRulePageContent = styled(PageContent)`
  .imgArea {
    position: relative;
    padding-bottom: 1rem;

    @media ${Breakpoints.md} {
      max-width: 25rem;
      padding: 0 4.5rem;
    }

    small {
      position: absolute;
      display: inline-block;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: var(--font-size-xs);
      color: var(--color-blueGrayDark);
      text-align: center;
      padding-left: 1.5rem;
      
      @media ${Breakpoints.md} {
        padding-left: 7.5rem;
        padding-right: 4.5rem;
      }
    }
  }
`

const PointsRule = () => {
  return (
    <PageContainer pageTitle={"Acumule pontos | "+ process.env.REACT_APP_SITE_NAME}>
      <PageHeader>
        Acumule Pontos
      </PageHeader>
      <PointRulePageContent>
        <div className="imgArea">
          {CheckPermission("ACUMULE_PONTOS_REVENDAS") && (
            <img src={imgHighlight__revenda} alt="Cada R$ 5 na venda de produtos Intelbras = 1 ponto; A cada R$ 10 do segmento Solar On Grid = 1 ponto" />
          )}
          {CheckPermission("ACUMULE_PONTOS_VENDEDOR") && (
            <img src={imgHighlight__vendedor} alt="Cada R$ 10 na venda de produtos Intelbras = 1 ponto" />
          )}
          {CheckPermission("ACUMULE_PONTOS_ASTEC") && (
            <img src={imgHighlight__astec} alt="Cada R$ 1 na compra de produtos Intelbras = 1 ponto; A cada R$ 5 na venda de produtos Intelbras = 1 ponto" />
          )}
          {CheckPermission("ACUMULE_PONTOS_PROVEDORES") && (
            <img src={imgHighlight__provedor} alt="Cada R$ 5 na compra de produtos Intelbras = 1 ponto" />
          )}
          {CheckPermission("ACUMULE_PONTOS_REVENDA_SOLUCOES") && (
            <img src={imgHighlight__revenda_solucoes} alt="Cada R$ 5 na compra de produtos Intelbras = 1 ponto" />
          )}
          <small>* Consulte no regulamento do benefício o detalhamento da mecânica e da pontuação.</small>
        </div>
        <div className="contentArea">
            {CheckPermission("ACUMULE_PONTOS_VENDEDOR") && (
              <h3>Suas vendas de produtos Intelbras valem pontos</h3>
            )}
            {!CheckPermission("ACUMULE_PONTOS_VENDEDOR") && (
              <h3>Suas compras de produtos Intelbras valem pontos</h3>
            )}
            <PointRuleContent>
              <p>Ao fazer parte do Programa de Canais e comercializar produtos Intelbras nos Distribuidores autorizados, acumule pontos automaticamente e troque por produtos e serviços incríveis no Intelbras Pontua. </p>
            </PointRuleContent>
        </div>
      </PointRulePageContent>
      <PointFaqHighlight container="acumule pontos" />
    </PageContainer>
  );
};

export default PointsRule;
