import { useEffect, useState, useContext } from "react";
import { CircularProgress } from "@mui/material";
import PrepareFetch from "../helpers/PrepareFetch";
import UserContext from "./UserContext";
import ShoppingCartContext from "./ShoppingCartContext";
import ShoppingCartFacades from "../helpers/ShoppingCartFacades";
import ShoppingCartContextUpdate, { ShoppingCartLoadPublicCoupons, ShoppingCartApplyCoupon, ShoppingCartCheckCoupon } from "./ShoppingCartContextUpdate";
import MainAppContext from "../context/MainAppContext";
import ErrorPage from "../pages/ErrorPage";

// TODO Ver se o useEffect 'e o melhor uso aqui
// Esta caindo num fallback por conta de CORS

const Context = ({ children }) => {
  const [user, setUser] = useState(null);
  const [shoppingCart, setShoppingCart] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const { setNeedsAgreement } = useContext(MainAppContext);

  useEffect(() => {
    (async () => {
      try {
        let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/members/me","GET");

        await fetch(fetchData.url, fetchData.options)
                .then(response => {
                  if(response.status === 200) {
                    return response.json();
                  }

                  throw new Error(response);
                })
                .then(json => {
                  setUser(json);
                  setNeedsAgreement(json.member.V_NeedAcceptAgreement__c);

                  if(typeof window.dataLayer === 'object') {
                    window.dataLayer.push({
                      'user_id': json.member.Id
                    })
                  }
                })
                .catch(error => {
                  // setUser(localUserJson);
                  setError(true);
                  console.log("User inválido");
                });
      } catch (error) {
        setError(true);
        console.log("Erro de requisição");
      }
    })();

    (async () => {
        try {
          let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart/start","GET");
          await fetch(fetchData.url, fetchData.options)
                  .then(response => {
                      if(response.status === 200) {
                          return response.json();
                      }

                      throw new Error(response);
                  })
                  .then(json => {
                      if(json.coupon === null) {
                        ShoppingCartLoadPublicCoupons(async (data) => {
                          if(data === undefined) {
                            setShoppingCart(ShoppingCartFacades(json));
                            return;
                          }
                          console.log("cupons", data);
                          if(data.length > 0) {
                            await ShoppingCartApplyCoupon(data[0].V_Code__c);
                          }

                          await ShoppingCartContextUpdate((data) => {
                            setShoppingCart(ShoppingCartFacades(data));
                          });
                        });
                      } else {
                        setShoppingCart(ShoppingCartFacades(json));
                      }
                  })
                  .catch(error => {
                      setError(true);
                      console.log("Shopping Cart Error");
                  });
      } catch (error) {
          setError(true);
          console.log("Erro de requisição");
      }
      })();
  },[setNeedsAgreement])

  useEffect(() => {
    if(user !== null && shoppingCart !== null) {
      setLoading(false);
    }
  },[user, shoppingCart])

  return (
      <>
        {(!error && !loading) && (
          <UserContext.Provider value={{user, setUser}}>
            <ShoppingCartContext.Provider value={{ shoppingCart, setShoppingCart }}>
              {children}
            </ShoppingCartContext.Provider>
          </UserContext.Provider>
        )}
        {!error && loading && (
          <div style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <CircularProgress
                size={48}
                sx={{
                    color: "var(--color-brand)",
                }}
            />
          </div>
        )}
        {error && (
          <ErrorPage title="Parece que você não tem acesso ao Intelbras Pontua">
            <div>
              <p>Parceiro, o Intelbras Pontua é um benefício exclusivo para Parceiros credenciados no Programa de Canais Intelbras (PCI).</p>
              <p>Se você é o gestor da conta, revise seu cargo para "Proprietário/a" ou "Sócio" no <a href="https://extranet.intelbras.com.br/gestao-canais/colaboradores/" target="_blank" rel="noreferrer">Portal de relacionamento</a>. Se você é Vendedor de um Distribuidor, solicite ao gestor da conta a revisão do seu cargo para cargo "Vendedor/a" no Portal de relacionamento. O acesso será liberado no prazo de 24 horas.</p>
              <p>Se o problema persistir ou se tiver alguma dúvida, não hesite em entrar em contato através do e-mail <a href="mailto:intelbraspontua@intelbras.com.br">intelbraspontua@intelbras.com.br</a>.</p>
            </div>
          </ErrorPage>
        )}
      </>
  );
};

export default Context;
