// Loja integrada / home
import { useParams } from 'react-router';
import PageContainer from "../components/PageContainer";
import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import StoreHeader from "../components/StoreHeader";
import StoreProductList from "../components/StoreProductList";

const StoreCategory = () => {
    const params = useParams();
    const id = params.id;

  return (
    <PageContainer pageTitle={"Loja de Marketing | "+ process.env.REACT_APP_SITE_NAME } showHeader={false}>
      <StoreHeader />
      <ShoppingPageContainer className="singleColumn--md">
        <StoreProductList paramCategoryId={id} />
      </ShoppingPageContainer>
    </PageContainer>
  );
};

export default StoreCategory;
