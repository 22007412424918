import styled from "styled-components";

import iconDelivered from "../assets/icons/status-delivered.svg";
import iconOpen from "../assets/icons/status-open.svg";
import iconReady from "../assets/icons/status-ready.svg";
import iconReverted from "../assets/icons/status-cancel.svg";
import iconTruck from "../assets/icons/truck.svg";

const RedemptionStatus = ({className, status, children}) => {
    const Container = styled.div`
        display: inline-flex;
        padding: .25rem 1rem;
        gap: .625rem;
        border-radius: 99px;
        background: var(--color-xLightGray);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bold);
        color: var(--color-blueGrayDark);
        width: fit-content;
        line-height: 1.25rem;
        align-items: center;

        .icon {
            height: 1.25rem;
        }

        img {
            width: 1.25rem;
        }
    `

    const icon = () => {
        switch(status){
            case "Confirmado":
                return iconReady;
            case "Entregue":
                return iconDelivered;
            case "Aberto":
                return iconOpen;
            case "Estornado":
                return iconReverted;
            case "Enviado":
                return iconTruck;
            default: 
                return null;
        }
    }

    return (
        <Container className={className}>
            {icon() !== null && (
                <div className="icon"><img src={icon()} alt="" /></div>
            )}
            <span>
                <strong>{status}</strong>
                {children}
            </span>
        </Container>
    )
}

export default RedemptionStatus;