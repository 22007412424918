import { useState, useContext } from 'react';
import styled from "styled-components";
import { Button, CircularProgress, Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MuiBlock from '../atom/MuiCustoms';
import ShoppingCartContext from "../context/ShoppingCartContext";
import PrepareFetch from '../helpers/PrepareFetch';
import ShoppingCartFacades from '../helpers/ShoppingCartFacades';
import ShoppingCartContextUpdate from '../context/ShoppingCartContextUpdate';

const ButtonAddToCart = ({children, className, productData}) => {
    const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext);
    const navigate = useNavigate();

    const defaultErrorMessage = "Houve um problema ao atualizar o carrinho.";

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(defaultErrorMessage);

    const StyledButton = styled(Button)`
        &.MuiButtonBase-root {
            background: var(--color-brand);
            color: var(--color-white);
            border-radius: .25rem;
            padding: 1rem;
            display: flex;
            flex-direction: row;
            gap: .5rem;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: var(--font-size-lg);
            font-weight: var(--font-weight-bold);
            line-height: 1em;
            text-transform: none;

            img {
                width: 1.5rem;
            }

            &:disabled {
                opacity: .4;
            }
        }
    `

    const addItemToCart = () => {
        const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/items","POST",{
            "Content-Type": "application/json"
          },{
            rewardId: productData.id,
            quantity: 1
          });

        setLoading(true);
        setDisabled(true);

        try {
            fetch(fetchData.url, fetchData.options)
            .then(response => {
                if(response.status === 200) {
                    return response.json();
                } else {
                    response.json().then(jsonError => {
                        if(jsonError[0].message.indexOf("Insufficient inventory") > -1) {
                            setErrorMessage("Não há estoque deste produto para incluir mais itens no carrinho.");
                        } else {
                            setErrorMessage(defaultErrorMessage);
                        }
                    })
                    
                    throw new Error(response.message);
                }                
            })
            .then(json => {
                setShoppingCart(ShoppingCartFacades(json));

                setLoading(false);
                setDisabled(false);
                navigate("/loja-de-marketing/carrinho");
            })
            .catch(error => {
                setLoading(false);
                setError(true);
                setDisabled(false);

                if(process.env.VERCEL_ENV !== "production") {
                    console.log(error);
                }
            });

        } catch (error) {
            setLoading(false);
            setError(true);
            setDisabled(false);
            
            if(process.env.VERCEL_ENV !== "production") {
                console.log(error);
            }
        }
    }

    return (
        <MuiBlock style={{position: "relative"}}>
            <StyledButton className={className} disabled={disabled} onClick={addItemToCart}>
                {children}
            </StyledButton>
            { loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: "var(--color-white)",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        margin: "-12px 0 0 -12px"
                    }}
                />
            )}
            { error && (
                <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </MuiBlock>
    )
}

export default ButtonAddToCart;