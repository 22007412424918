// Loja integrada / home
import PageContainer from "../components/PageContainer";
import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import StoreHeader from "../components/StoreHeader";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import ProductBreadcrumb from "../atom/ProductBreadcrumb";
import { RedemptionTabs, RedemptionBlock } from "../components/RedemptionBlock"; 

const StoreRedemptions = () => {
    const breadcrumbData = {
        name: "Pedidos",
        categoryId: null
    }
  return (
    <PageContainer pageTitle={"Pedidos » Loja de Marketing | "+ process.env.REACT_APP_SITE_NAME } showHeader={false}>
      <StoreHeader />
      <ShoppingPageContainer className="singleColumn--md">
        <ProductBreadcrumb productData={breadcrumbData} style={{width: "100%"}} />
        <h2 className="mainTitle" style={{
          width: "100%", 
          padding: "0 1.5rem"
        }}
        >Histórico de resgates</h2>
        <RedemptionBlock container="LOJA_MARKETING" style={{width: "100%"}} />
      </ShoppingPageContainer>
    </PageContainer>
  );
};

const Redemptions = () => {
    return (
        <PageContainer pageTitle={"Pedidos | "+ process.env.REACT_APP_SITE_NAME }>
            <PageHeader>Pedidos</PageHeader>
            <PageContent>
                <RedemptionTabs />
            </PageContent>
        </PageContainer>
    )
}

export { StoreRedemptions, Redemptions };
