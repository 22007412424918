import PrepareFetch from "../helpers/PrepareFetch";

const ShoppingCartContextUpdate = async (callback) => {
    let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart","GET");

    await fetch(fetchData.url, fetchData.options)
            .then(response => {
                if(response.status === 200) {
                    return response.json();
                }

                throw new Error(response);
            })
            .then(json => {
                callback.apply(null, [json]);
            })
            .catch(error => {
                console.warn(error);
                console.log("Erro ao atualizar os dados da seção");
            });
}

export const ShoppingCartLoadPublicCoupons = async(callback) => {
    let fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart/cupom","GET");

    await fetch(fetchData.url, fetchData.options)
        .then(response => {
            if(response.status === 200) {
                return response.json();
            }
        })
        .then(json => {
            callback.apply(null, [json]);
        });

}

export const ShoppingCartApplyCoupon = async (couponId) => {
    console.log(couponId);
    const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart/cupom","PATCH",{
        "Content-Type": "application/json"
      },{
        cupom: couponId
      });
    
    
    await fetch(fetchData.url, fetchData.options)
        .then(response => {
            if(response.status === 200) {
                return response.json();
            } 

            return response.text().then((text) => {
                throw Error(text)
            });
        })
        .catch(error => {
            console.log("Erro ao atualizar os dados da seção");
        });   
}

export const ShoppingCartCheckCoupon = async (callback) => {
    const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart/start","GET");
    
    await fetch(fetchData.url, fetchData.options)
        .then(response => {
            if(response.status === 200) {
                return response.json();
            } 

            return response.text().then((text) => {
                throw Error(text)
            });
        })
        .then(json => {
            callback.apply(null, [json]);
        })
        .catch(error => {
            console.log("Erro ao atualizar os dados da seção");
        });   
}

export default ShoppingCartContextUpdate;