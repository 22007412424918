import { useState, useEffect, useContext } from 'react';
import { CircularProgress } from "@mui/material";
import UserContext from "../context/UserContext";
import UserContextUpdate from '../context/UserContextUpdate';

import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import PointsFormatUtil from '../helpers/PointFormatUtil';
import PageContainer from "../components/PageContainer";
import CheckoutHeader from "../components/CheckoutHeader";
import CheckoutResume from '../components/CheckoutResume';
import CheckoutCreditCardForm from '../components/CheckoutCreditCardForm';
import StoreFeedback from "../components/StoreFeedback";

import alertIcon from '../assets/icons/creditCardAlert.svg';

const CreditCardCheckout = () => {
    const [loading, setLoading] = useState(true);
    const { user, setUser } = useContext(UserContext);
    const [data, setData] = useState([
        {
            label: "Resgate no Cartão de Crédito Intelbras Pontua",
            value: ""
        },
        {
            label: "Crédito no Cartão após o resgate",
            value: "",
            super: true,
        },
    ]);

    useEffect(() => {
        (async () => {
            await UserContextUpdate((json) => {
                setUser(json);

                setData([
                    {
                        label: "Resgate no Cartão de Crédito Intelbras Pontua",
                        value: PointsFormatUtil(json.member.Pontoscampanha__c) + " pontos campanha"
                    },
                    {
                        label: "Crédito no Cartão após o resgate",
                        value: json.member.Pontoscampanha__c.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}),
                        super: true,
                    },
                ])
                // console.log(data);
                setLoading(false);
            })
        })();
    },[setUser, setLoading])

    return (
        <PageContainer pageTitle={"Resgate de pontos no Cartão de Crédito | "+ process.env.REACT_APP_SITE_NAME} showHeader={false} showFooter={false}>
            {loading && (
                <div style={{
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress
                        size={48}
                        sx={{
                            color: "var(--color-brand)",
                        }}
                    />
                </div>
            )}
            {!loading && (
                <>
                    <CheckoutHeader backButton="Voltar para o site" backButtonLink="/cartao-de-credito" />
                    {user.member.Pontoscampanha__c === 0 && (
                        <StoreFeedback 
                            icon={alertIcon} 
                            title="Você não possui saldo suficiente para fazer o resgate" 
                            button={{
                                label: "Voltar para o site",
                                link: "/"
                            }}
                        />
                    )}
                    {user.member.Pontoscampanha__c > 0 && (
                        <ShoppingPageContainer style={{justifyContent: "space-between", position: "relative"}}>
                            <CheckoutCreditCardForm points={user.member.Pontoscampanha__c} hasCreditCard={user.member.V_ResgateCartaoCredito__c} data={data} />
                            {!user.member.V_ResgateCartaoCredito__c && (
                                <CheckoutResume data={data} />
                            )}
                        </ShoppingPageContainer>
                    )}
                </>
            )}            
        </PageContainer>
    )
}

export default CreditCardCheckout;