import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import DialogContext from '../context/DialogContext';
import { Tooltip } from "@mui/material";
import ExitDialog from "../atom/ExitDialog";
import leaveImg from "../assets/icons/leave.svg";

const BlockExitButton = styled.div`
  height: 1.5rem;

  a {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const ExitButton = () => {
  const [dialogOpened, setDialogOpened] = useState(false);

  const handleDialog = () => {
    setDialogOpened(true);
  }

  return (
      <>
        <BlockExitButton>
          <Tooltip title="Sair">
            <Link onClick={handleDialog}>
              <img
                src={leaveImg}
                alt="Sair" />
            </Link>
          </Tooltip>
        </BlockExitButton>
        <DialogContext.Provider value={{ dialogOpened, setDialogOpened }}>
          <ExitDialog />
        </DialogContext.Provider>
      </>
    )
}

export default ExitButton;