import keycloak from "../Keycloak";
import React, { useContext } from "react";
import DialogContext from '../context/DialogContext';
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import MuiBlock from '../atom/MuiCustoms';

const ExitDialog = () => {
    const { dialogOpened, setDialogOpened } = useContext(DialogContext);

    const handleClose = () => {
        setDialogOpened(false);
    };

    const logout = () => {
        keycloak.logout();
    };

    return (
        <MuiBlock>
            <Dialog
                open={dialogOpened}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deseja realmente sair?"}
                </DialogTitle>
                <DialogActions>
                    <MuiBlock>
                        <Button onClick={handleClose} className="button">Não</Button>
                        <Button onClick={logout} className="button" autoFocus>
                            Sim
                        </Button>
                    </MuiBlock>
                </DialogActions>
            </Dialog>
        </MuiBlock>
    )
}

export default ExitDialog;