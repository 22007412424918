// Loja / Detalhe do produto
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Skeleton } from "@mui/material";

import PrepareFetch from '../helpers/PrepareFetch';
import ProductFacades from "../helpers/ProductFacades";
import Breakpoints from '../config/Breakpoints';

import MuiBlock from '../atom/MuiCustoms';
import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import PageContainer from "../components/PageContainer";
import StoreHeader from "../components/StoreHeader";
import ProductBreadcrumb from '../atom/ProductBreadcrumb';
import ProductDescription from '../atom/ProductDescription';
import ProductPrice from '../atom/ProductPrice';


import alertSkeleton from "../assets/icons/alertSkeleton.svg"


const ProductErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  align-items: center;

  .imgBlock {
    img {
      width: 4.5rem;
    }
  }

  .textBlock {
    h2 {
      margin: 0;
      font-size: var(--font-size-lg);
      color: var(--color-black);
    }
  }

  .button {
    background: var(--color-white);
    border: 1px solid var(--color-brand);
    color: var(--color-brand);
    border-radius: .25rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    line-height: 1em;
  }
`

const ProductDetail = () => {
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const ProductDetailContainer = styled(ShoppingPageContainer)`
    &.singleColumn--md {
      align-items: flex-start !important;
    }

    .detailBlock {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      gap: 1.5rem;
    }

    @media ${Breakpoints.md} {
      .detailBlock {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  `;

  useEffect(() => {
    if(typeof id === "undefined") return;

    (async () => {
      let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/rewards/${id}`,"GET",{
          "cache-by": "user"
      });
      await fetch(fetchParams.url, fetchParams.options)
              .then(response => {
                  if(response.status === 200) {
                    return response.json();
                  }

                  throw new Error(response);
              })
              .then(json => {
                  if(json.length === 0) {
                    navigate("/loja-de-marketing")
                  } else {
                    setLoading(false);
                    setData(ProductFacades(json));
                  }
              })
              .catch(error => {
                  setLoading(false);
                  setError(true);
              });
    })();
  },[id]);

  return (
    <PageContainer pageTitle={"Detalhe de produto | "+ process.env.REACT_APP_SITE_NAME } showHeader={false}>
      <StoreHeader />
      <ProductDetailContainer className="singleColumn--md">
        {loading && (
          <div className="detailBlock">
          <Skeleton
              sx={{
                  aspectRatio: "2/1",
                  borderRadius: ".5rem",
                  height: "auto",
                  width: "70%"
              }}
              variant="rectangular"
              width="70%"
          />
          <Skeleton
              sx={{
                  aspectRatio: "2/1",
                  borderRadius: ".5rem",
                  height: "auto",
                  width: "30%"
              }}
              variant="rectangular"
              width="30%"
          />
          </div>
        )}

        {(!loading && !error) && (
            <>
              <ProductBreadcrumb productData={data[0]} />
              <div className="detailBlock">
                <ProductDescription productData={data[0]} />
                <ProductPrice productData={data[0]} />
              </div>
            </>
        )}

        {error && (
          <ProductErrorBlock>
            <div className="imgBlock">
              <img src={alertSkeleton} alt="" />
            </div>
            <div className="textBlock">
              <h2>Produto não encontrado</h2>
            </div>
            <MuiBlock>
              <Button component={Link} to="/store" className="button">
                Visualizar produtos
              </Button>
            </MuiBlock>
          </ProductErrorBlock>
        )}
      </ProductDetailContainer>  
    </PageContainer>
  );
}

export default ProductDetail;
