import styled from "styled-components";
import DOMPurify from 'dompurify'
import { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";

import PrepareFetch from "../helpers/PrepareFetch";
import FaqFacades from "../helpers/FaqFacades";

import downArrow from "../assets/icons/downArrow.svg";
import alertSkeleton from "../assets/icons/alertSkeleton.svg";


const FaqItem = ({className, question, answer}) => {
    const [opened, setOpened] = useState(false);

    const FaqItemBlock = styled.li`
        display: block;
        padding: 1.5rem 0;
        border-bottom: 1px solid var(--color-lightGray);

        & > strong {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            align-items: center;
            color: var(--color-brand);
            font-size: var(--font-size-lg);
            line-height: 1em;
            cursor: pointer;

            img {
                transition: all .5s ease;
                width: 1.5rem;
                height: 1.5rem;
                transform-origin: center;
                transform: rotate(-90deg); 
            }
        }

        & > div {
            box-sizing: border-box;
            transition: all .5s ease;
            transition-delay: .2s;
            opacity: 0;
            max-height: 0;
            margin: 1rem 0 0;
            padding-left: 2rem;
            font-size: var(--font-size-md);
            color: var(--color-darkgray);
            line-height: 1.25em;
            position: absolute;
            pointer-events: none;

            a {
                color: var(--color-brand);
                font-weight: var(--font-weight-bold);
            }

            p {
                margin-bottom: 1rem;
            }

            ol, ul {
                margin: 0 0 1rem;
                padding: 0 0 0 1rem;
                color: var(--color-blueGrayDark);

                li {
                    margin-bottom: .25rem;
                }
            }

            ul {
                padding-left: 1.5rem;
                list-style: disc;
            }
        }

        &[opened='true'] {
            & > strong img {
                transform: rotate(0deg);
            }
            
            & > div {
                pointer-events: initial;
                position: static;
                opacity: 1;
                max-height: 100vh;
            }
        }
    `

    return (
        <FaqItemBlock className={className} opened={opened.toString()}>
            <strong onClick={() => setOpened(!opened)}>
                <img src={downArrow} alt="" />
                <span>{question}</span>
            </strong>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(answer)}}></div>
        </FaqItemBlock>
    )
}

const FaqList = ({className, container}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/faq?container=${container}`,"GET",{
            "cache-by": "user"
        });

        (async () => {
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                          return response.json();
                        }
      
                        throw new Error(response);
                    })
                    .then(json => {
                        setLoading(false);
                        setData(FaqFacades(json));
                        console.log()
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
          })();
    },[container]);

    const FaqListBlock = styled.ul`
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        .warningBlock {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            height: auto;
            border-radius: .5rem;
            background: var(--color-lightGray);
            color: var(--color-gray);
            justify-content: center;
            align-items: center;
            aspect-ratio: 16/9;
        }
    `
    
    return (
        <FaqListBlock className={className}>
             {loading && (
                <Skeleton
                    sx={{
                        aspectRatio: "16/5",
                        borderRadius: ".5rem",
                        height: "auto",
                        width: "100%"
                    }}
                    variant="rectangular"
                    width="100%"
                />
            )}
            {!loading && !error && (
                <>
                    {data.map(item => {
                        return (
                            <div key={`faqitem_${item.id}`}>
                                <FaqItem className={className} question={item.question} answer={item.answer} />
                            </div>
                        )
                    })}
                </>
            )}
            {!loading && error && (
                <div className="warningBlock">
                    <img src={alertSkeleton} alt="" />
                    Não foi possível carregar o conteúdo.
                </div>
            )}
        </FaqListBlock>
    )
}

export default FaqList;