import { useParams } from 'react-router';

import PageContainer from "../components/PageContainer";
import ShoppingPageContainer from "../atom/ShoppingPageContainer";
import StoreHeader from "../components/StoreHeader";
import PageHeader from "../atom/PageHeader";
import PageContent from "../atom/PageContent";
import RedemptionBlockItem from '../components/RedemptionBlockItem';


const StoreRedemptionItem = () => {
    const params = useParams();
    const id = params.id;

    return (
        <PageContainer pageTitle={"Pedidos » Loja de Marketing | "+ process.env.REACT_APP_SITE_NAME } showHeader={false}>
        <StoreHeader />
        <ShoppingPageContainer className="singleColumn--md">
            <RedemptionBlockItem id={id} context="store" />
        </ShoppingPageContainer>
        </PageContainer>
    );
};

const RedemptionItem = () => {
    const params = useParams();
    const id = params.id;

    return (
        <PageContainer pageTitle={"Pedidos | "+ process.env.REACT_APP_SITE_NAME }>
            <PageHeader>Pedidos</PageHeader>
            <PageContent>
                <RedemptionBlockItem id={id} />
            </PageContent>
        </PageContainer>
    )
}

export { StoreRedemptionItem, RedemptionItem };
