import { Link } from "react-router-dom";
import styled from "styled-components";
import logoImg from "../assets/images/logo.svg";

export const LogoImg = ({className}) => {
    return (
        <img
            className={className}
            src={logoImg}
            alt={process.env.REACT_APP_SITE_NAME}
            style={{ height: "2.5rem", width: "12.1875rem" }}
        />
    )
}

const logo = ({className, link}) => {
    if(typeof link === "undefined") {
        link = "/home";
    }
    return (
        <Link to={link} className={className}>
            <LogoImg/>
        </Link>
    )
}

export const Logo = styled(logo)`
    display: inline-block;
    height: 2.5rem;
`