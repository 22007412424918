import { useContext, useEffect, useState } from 'react';
import { Chip, TextField, InputAdornment, CircularProgress, Snackbar, Alert, Tooltip } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CouponTagIcon from '../assets/icons/couponTag.svg';

import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';

import PrepareFetch from '../helpers/PrepareFetch';
import ParseApexError from '../helpers/ParseApexErrorMessage';
import ShoppingCartContext from "../context/ShoppingCartContext";
import ShoppingCartContextUpdate from "../context/ShoppingCartContextUpdate";
import ShoppingCartFacades from "../helpers/ShoppingCartFacades";

import PointsFormatUtil from '../helpers/PointFormatUtil';
import StyledButton from '../atom/Button';

import MuiBlock from '../atom/MuiCustoms';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 12.5rem;
    gap: .25rem;

    strong { 
        width: 100% !important;
    }

    & > div {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;
    }
`;

export const ShoppingCartCouponInfo = () => {
    const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext);
    const [ isRemovingLoading, setRemovingLoading ] = useState(false);
    const [ hasElegibleItems, setHasElegibleItems ] = useState(false);
    const [ couponValue, setCouponValue ] = useState('');
    const [ invalidCupomMessage, showInvalidCupomMessage ] = useState({
        status: false,
        message: ''
    });

    function handleDeleteCupom() {
        setRemovingLoading(true);
        const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart/cupom","DELETE",{
            "Content-Type": "application/json"
        },{});

        try {
            fetch(fetchData.url, fetchData.options)
            .then(response => {
                if(response.status === 200) {
                    return response.json();
                }
                throw new Error(response);
            })
            .then(data => {
                if(data.status) {
                    ShoppingCartContextUpdate((json) => {
                        let scFacade = ShoppingCartFacades(json);
                        setShoppingCart(scFacade);

                        setRemovingLoading(false);
                        // setHasValidCupom(false);
                    });
                }
            })
            .catch(error => {
                setRemovingLoading(false);
                showInvalidCupomMessage({
                    status: true,
                    message: 'Algo deu errado ao remover o cupom'
                });
            });
        } catch (error) {
            setRemovingLoading(false);
            showInvalidCupomMessage({
                status: true,
                message: 'Algo deu errado ao remover o cupom'
            });
        } 
    }

    useEffect(() => {
        setCouponValue(shoppingCart.coupon.couponType === 'discount' ? shoppingCart.coupon.couponTotalDiscount : shoppingCart.coupon.couponCashback);
        setHasElegibleItems(shoppingCart.coupon !== null ? shoppingCart.coupon.hasAnyCouponApplied : false);
    },[shoppingCart]);

    return (
        <>
        {shoppingCart.coupon.applied && (
            <>
                <MuiBlock className="rowWrapper">
                    <div className="row" style={{alignItems: 'flex-start'}}>
                        <ShoppingCartCashbackLabel>
                            Subtotal
                        </ShoppingCartCashbackLabel>
                        <StyledDiv>
                            <strong>{PointsFormatUtil(shoppingCart.originalTotal)} pontos</strong>
                        </StyledDiv>
                    </div>
                    <div className="row" style={{alignItems: 'flex-start'}}>
                        <ShoppingCartCashbackLabel>
                            {shoppingCart.coupon.couponType === 'discount' ? 'Desconto' : 'Cashback'}
                        </ShoppingCartCashbackLabel>
                        <StyledDiv>
                            {hasElegibleItems && (
                                <div>
                                    <strong>
                                        {shoppingCart.coupon.couponType === 'discount' ? 
                                            `${PointsFormatUtil(couponValue)} pontos` : 
                                            couponValue
                                        }
                                    </strong>
                                </div>
                            )}
                            {!hasElegibleItems && (
                                <div>
                                    {shoppingCart.coupon.couponDefault && (
                                        <strong>Adicione itens ao carrinho</strong>
                                    )}
                                    {!shoppingCart.coupon.couponDefault && (
                                        <strong>Nenhum item elegível</strong>
                                    )}
                                    <Tooltip 
                                        title={shoppingCart.coupon.couponInfo}
                                        placement="top-end"
                                        style={{maxWidth: '18.5rem', }}
                                    >
                                        <WarningAmberRoundedIcon size="small" style={{fill: !shoppingCart.coupon.couponDefault ? "var(--color-alert)" : "var(--color-darkgray)"}} />
                                    </Tooltip>
                                </div>
                            )}
                            <div>
                                {shoppingCart.coupon.couponDefault && (
                                    <Chip 
                                        label={shoppingCart.coupon.couponId} 
                                        className='MuiChip-medium'
                                    />
                                )}
                                {!shoppingCart.coupon.couponDefault && (
                                <Chip 
                                    label={shoppingCart.coupon.couponId} 
                                    onDelete={handleDeleteCupom} 
                                    disabled={isRemovingLoading} 
                                    className='MuiChip-medium'
                                    deleteIcon={<HighlightOffOutlinedIcon />}
                                />
                                )}
                                <Tooltip 
                                    title={shoppingCart.coupon.couponInfo}
                                    placement="top-end"
                                    style={{maxWidth: '18.5rem', }}
                                >
                                    <InfoOutlinedIcon size="small" style={{fill: "var(--color-blueGrayDark)"}} />
                                </Tooltip>
                            </div>
                        </StyledDiv>
                    </div> 
                    
                </MuiBlock>
                <Snackbar open={invalidCupomMessage.status} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={6000} onClose={() => showInvalidCupomMessage({status: false})}>
                    <Alert
                        onClose={() => showInvalidCupomMessage({status: false})}
                        severity="warning"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {invalidCupomMessage.message}
                    </Alert>
                </Snackbar>
                <hr/>
            </>
        )}
        </>
    )
}



export const RedemptionCouponInfo = ({couponData}) => {
    return (
        <>
        <MuiBlock className="rowWrapper">
            <div className="row" style={{alignItems: 'flex-start'}}>
                <ShoppingCartCashbackLabel>
                    Subtotal
                </ShoppingCartCashbackLabel>
                <StyledDiv>
                    <strong>{PointsFormatUtil(couponData.originalTotal)} pontos</strong>
                </StyledDiv>
            </div>
            <div className="row" style={{alignItems: 'flex-start'}}>
                <ShoppingCartCashbackLabel>
                    {couponData.couponType === 'discount' ? 'Desconto' : 'Cashback'}
                </ShoppingCartCashbackLabel>
                <StyledDiv>
                    <div>
                        <strong>
                            {couponData.couponType === 'discount' ? 
                                `${PointsFormatUtil(couponData.couponTotalDiscount)} pontos` : couponData.couponCashback
                            }
                        </strong>
                    </div>
                    <div>
                        <Chip 
                            label={couponData.couponId} 
                            className='MuiChip-medium'
                        />
                        <Tooltip 
                            title={couponData.couponInfo}
                            placement="top-end"
                            style={{maxWidth: '18.5rem', }}
                        >
                            <InfoOutlinedIcon size="small" style={{fill: "var(--color-blueGrayDark)"}} />
                        </Tooltip>
                    </div>
                </StyledDiv>
            </div>
        </MuiBlock>
        <hr/>
        </>
    )
}

export const ShoppingCartCouponForm = () => {
    const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext);
    const {control, formState: { errors }, handleSubmit, setValue} = useForm({mode: "onBlur"});
    const [ showForm, setShowForm] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ invalidCupomMessage, showInvalidCupomMessage ] = useState({
        status: false,
        message: ''
    });

    function handleCancel() {
        if(!isLoading) {
            setValue('cupomCode','');
            setShowForm(false);
        }
    }

    async function submitCheckout(data) {
        if(data.cupomCode !== '') {
            setIsLoading(true);
            
            const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/cart/cupom","PATCH",{
                "Content-Type": "application/json"
              },{
                cupom: data.cupomCode
              });
            
            try {
                fetch(fetchData.url, fetchData.options)
                .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    } 

                    return response.text().then((text) => {
                        throw Error(text)
                    });
                })
                .then(data => {
                    if(data.status) {
                        ShoppingCartContextUpdate((json) => {
                            let scFacade = ShoppingCartFacades(json);
                            setShoppingCart(scFacade);

                            setIsLoading(false);
                            setShowForm(false);
                        });
                    } else {
                        let message = ParseApexError(data, 'VN_CouponService.CouponServiceException', 'Cupom inativo ou inválido');
                        
                        setIsLoading(false);
                        showInvalidCupomMessage({
                            status: true,
                            message: message
                        });
                        setValue('cupomCode','');
                    }
                })
                .catch(error => {
                    // console.log(error);
                    let message = ParseApexError(error, 'VN_CouponService.CouponServiceException', 'Algo deu errado ao consultar o cupom');
                    setIsLoading(false);
                    showInvalidCupomMessage({
                        status: true,
                        message: message
                    });
                });
            } catch (error) {
                let message = ParseApexError(error, 'VN_CouponService.CouponServiceException', 'Algo deu errado ao consultar o cupom');

                setIsLoading(false);
                showInvalidCupomMessage({
                    status: true,
                    message: message
                });
            } 
        }
    }

    const StyledForm = styled.form`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 14.5rem;
        gap: .25rem;

        strong.smallLink {
            color: var(--color-blueGrayDark);
            cursor: pointer;

            &.disabled {
                color: var(--color-gray);
                cursor: not-allowed;
            }
        }
    `;

    const CleanUpTextValue = (val) => {
        return val.replace(/ /g,'').toUpperCase();
    }

    return (
        <>
            <hr/>
            <MuiBlock className="rowWrapper">
                <div className="row">
                    <ShoppingCartCashbackLabel>Cupom</ShoppingCartCashbackLabel>
                    {!showForm && (shoppingCart.coupon !== undefined && shoppingCart.coupon !== null) && (
                        <strong onClick={() => setShowForm(true)} className="link" style={{display: 'inline-flex', gap: '.5rem', whiteSpace: 'nowrap', justifyContent: 'flex-end'}}>
                            {shoppingCart.coupon.applied && (
                                <>Alterar cupom</>
                            )}
                            {!shoppingCart.coupon.applied && (
                                <>Incluir cupom</>
                            )}
                            <img src={CouponTagIcon} alt='tag' />
                        </strong>
                    )}
                    {showForm && (
                        <StyledForm onSubmit={handleSubmit(submitCheckout)}>
                            <div style={{whiteSpace: "nowrap"}}>
                                <Controller
                                    name="cupomCode"
                                    control={control}
                                    defaultValue=""
                                    render={({ 
                                        field: { onChange, onBlur, value}
                                    }) => (
                                        <TextField
                                            label="Cupom"
                                            value={value}
                                            onChange={(event) =>
                                                onChange(CleanUpTextValue(event.target.value))
                                            }
                                            onBlur={onBlur}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            error={!!errors.cupomCode}
                                            disabled={isLoading}
                                            autoComplete='off'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {isLoading && (
                                                            <CircularProgress
                                                                size={16}
                                                                sx={{color: 'var(--color-darkgray)'}}
                                                            />
                                                        )}
                                                        {!isLoading && (
                                                            <StyledButton className="xsmall" type="submit" size="small" variant="contained" disabled={value === ''}>
                                                                Aplicar
                                                            </StyledButton>
                                                        )}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                
                            </div>

                            <strong onClick={handleCancel} className={`smallLink ${(isLoading ? 'disabled' : '')}`}>cancelar</strong>
                        </StyledForm>
                    )}
                </div>
            </MuiBlock>
            <Snackbar open={invalidCupomMessage.status} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={6000} onClose={() => showInvalidCupomMessage({status: false})}>
                <Alert
                    onClose={() => showInvalidCupomMessage({status: false})}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {invalidCupomMessage.message}
                </Alert>
            </Snackbar>
        </>
    )
}

const ShoppingCartCashbackLabel = ({children}) => {
    const StyledLabel = styled.span`
        display: inline-flex;
        gap: .25rem;
        align-items: center;
    `;

    return (
        <StyledLabel>
            {children}
        </StyledLabel>
    )
}