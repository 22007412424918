import { Link } from "react-router-dom";
import styled from "styled-components";
import homeIcon from "../assets/icons/home.svg";
import arrowIcon from "../assets/icons/breadcrumbArrow.svg";

const ProductBreadcrumb = ({className, style, productData}) => {
  const Breadcrumb = styled.div`
    display: flex;
    flex-direction: row;
    gap: .25rem;
    align-items: center;
    font-size: var(--font-size-sm);
    color: var(--color-blueGrayDark);

    a {
        color: var(--color-brand);
        text-decoration: none;
        font-weight: var(--font-weight-bold);
    }
  `

  return (
    <Breadcrumb className={className} style={style}>
        <Link to="/loja-de-marketing">
            <img src={homeIcon} alt="Voltar à home" />
        </Link>
        <img src={arrowIcon} alt="" />
        {(typeof productData.categoryId !== "undefined" && productData.categoryId !== null) && (
          <>
            <Link to={`/loja-de-marketing/categoria/${productData.categoryId}`}>
              {productData.categoryName}
            </Link>
            <img src={arrowIcon} alt="" />
          </>
        )}
        <span>{productData.name}</span>
    </Breadcrumb>
  )
}

export default ProductBreadcrumb;