import { Button } from "@mui/material";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import MuiBlock from "../atom/MuiCustoms";

const FilterButton = ({children, selected, onClick}) => {
    const StyledButton = styled(Button)`
        &.MuiButtonBase-root {
            display: inline-flex;
            background: var(--color-white);
            padding: .375rem .75rem;
            font-size: .625rem;
            letter-spacing: .8px;
            text-transform: uppercase;
            font-family: var(--font-family);
            color: var(--color-blueGrayDark);
            background: var(--color-white);
            border-radius: .25rem;
            line-height: .75rem;

            &.selected {
                background: var(--color-brand);
                color: var(--color-white);
            }
        }

    `

    return (
        <MuiBlock style={{display: "flex", justifyContent: "stretch"}}>
            <StyledButton className={(selected ? "selected" : "")} onClick={onClick}>
                {children}
            </StyledButton>
        </MuiBlock>
    )
}

const FilterBlock = styled.div`
        display: flex;
        flex-direction: column;
        border-radius: .5rem;
        background: var(--color-blueGrayLight);
        padding: 1.5rem;
        gap: 1.5rem;
        align-items: center;

        .filterButtons {
            display: flex;
            gap: .5rem;
            justify-content: stretch;
        }

        .filterFields,
        .searchField {
            @media ${Breakpoints.max.sm} {
                width: 100%;
            }
        }

        @media ${Breakpoints.md} {
            gap: .5rem;
            flex-direction: row;
            justify-content: space-between;
        }
    `

export { FilterButton, FilterBlock };