import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";

const ShoppingPageContainer = ({className, children}) => {
    const PageContentBlock = styled.div`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin: 2rem 1.5rem;
        justify-content: space-between;

        @media ${Breakpoints.md} {
            flex-direction: row;
            margin: 3rem 3rem 1rem;

            &.singleColumn--md {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        @media ${Breakpoints.lg} {
            max-width: 1184px;
            margin-left: auto;
            margin-right: auto;
        }

        @media ${Breakpoints.xl} {
            max-width: 1344px;
        }
    `

    return (
        <PageContentBlock className={className}>
            {children}
        </PageContentBlock>
    )
}

export default ShoppingPageContainer;