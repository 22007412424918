import styled from "styled-components";
import { Link } from "react-router-dom";
import Breakpoints from "../config/Breakpoints";
import AppStoreDownload from "../assets/icons/appStoreDownload.svg";
import AppStoreQR from "../assets/images/qr-souparceiro-ios.svg";
import GooglePlayDownload from "../assets/icons/googlePlayDownload.svg";
import GooglePlayQR from "../assets/images/qr-souparceiro-android.svg";

const AppDownloadBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .titleBlock {
        display: flex;
        flex-direction: column;
        gap: .25rem;
    }

    strong {
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-regular);
        color: var(--color-blueGrayDark);
    }

    h2 {
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-bold);
        line-height: 1em;
        color: var(--color-brand);
        margin: 0;
    }

    .linkBlock {
        display: flex;
        flex-direction: row;
        gap: 2.25rem;
    }

    @media ${Breakpoints.max.sm} {
        .titleBlock {
            flex-direction: row;
            justify-content: center;
        }

        .linkBlock {
            justify-content: center;
        }

        strong, h2 {
            display: inline-flex;
        }

        strong {
            font-size: var(--font-size-md);
        }

        h2 {
            font-size: var(--font-size-lg);
        }
    }

    @media ${Breakpoints.md} {
        flex-direction: row;
    }
`

const AppLinkBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;

    a {
        display: inline-block;

        img {
            width: 100%;
        }
    }

    .qrCodeBlock {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        gap: .5rem;
        font-size: var(--font-size-sm);
        color: var(--color-blueGrayDark);

        img {
            width: 3.625rem;
            height: fit-content;
        }

        @media ${Breakpoints.max.sm} {
            display: none;
        }
    }
`

const AppLink = ({link, button, text, qrCode}) => {
    return (
        <AppLinkBlock>
            <Link to={link} target="_blank">
                <img src={button} alt={text}/>
            </Link>
            <div className="qrCodeBlock">
                <span>Se preferir, use<br /> o QR Code:</span>
                <img src={qrCode} alt="QR Code" />
            </div>
        </AppLinkBlock>
    )
}

const AppDownload = ({className, headline, title}) => {
    const googlePlayLink = "https://play.google.com/store/apps/details?id=br.com.intelbras.app_revendas";
    const appStoreLink = "https://apps.apple.com/br/app/sou-parceiro-intelbras/id1451607930";

    if(typeof headline === "undefined") {
        headline = "Baixe o app";
    }

    if(typeof title === "undefined") {
        title = "Sou Parceiro";
    }

    return (
        <AppDownloadBlock className={className}>
            <div className="titleBlock">
                <strong>{headline}</strong>
                <h2>{title}</h2>
            </div>
            <div className="linkBlock">
                <AppLink link={googlePlayLink} button={GooglePlayDownload} text="Disponível no Google Play" qrCode={GooglePlayQR} />
                <AppLink link={appStoreLink} button={AppStoreDownload} text="Baixar na App Store" qrCode={AppStoreQR} />
            </div>
        </AppDownloadBlock>
    )
}

export default AppDownload;