import { useState, useEffect, useContext } from 'react';
import { CircularProgress } from "@mui/material";
import UserContext from "../context/UserContext";
import UserContextUpdate from '../context/UserContextUpdate';

import PageContainer from "../components/PageContainer";
import CheckoutHeader from "../components/CheckoutHeader";
import StoreFeedback from "../components/StoreFeedback";

import successIcon from '../assets/icons/creditCardSuccess.svg';

const CreditCardCheckoutFeedback = () => {
    const [loading, setLoading] = useState(true);
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            await UserContextUpdate((json) => {
                setUser(json);
                setLoading(false);
            })
        })();
    },[setUser, setLoading])

    
    const button = {
        label: "Voltar para o site",
        link: "/"
    }

    return (
        <PageContainer pageTitle={"Resgate de pontos no Cartão de Crédito | "+ process.env.REACT_APP_SITE_NAME} showHeader={false} showFooter={true}>
            {loading && (
                <div style={{
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress
                        size={48}
                        sx={{
                            color: "var(--color-brand)",
                        }}
                    />
                </div>
            )}
            {!loading && (
                <>
                    <CheckoutHeader backButton="Voltar para o site" backButtonLink="/" />
                    <StoreFeedback icon={successIcon} title="Pedido de resgate concluído com sucesso" button={button} />
                </>
            )}
        </PageContainer>
    )
}

export default CreditCardCheckoutFeedback;