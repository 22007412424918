import React, { useState } from 'react';
import styled from "styled-components";
import DialogContext from '../context/DialogContext';
import ExitDialog from "../atom/ExitDialog";
import Breakpoints from "../config/Breakpoints";
import { Logo } from "../atom/Logo";
import MainMenu from "../atom/Menu";
import Social from "../atom/Social";
import { Menu, UserMenu } from "../config/Menu";

const HeaderBlock = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  align-items: center;

  .social {
    display: none;
  }

  @media ${Breakpoints.max.sm} {
    box-sizing: border-box;
    transition: all .3s ease-in;
    top: 0;
    width: 100%;
    background-color: var(--color-white);
    z-index: 2;
    min-height: 1px;
    padding: 0;
    overflow: auto;
    align-items: flex-start;

    .logoArea {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.5rem;
    }

    & > ul {
      box-sizing: border-box;
      display: none;
      flex-grow: 0;
      width: 100%;
    }

    &.isOpened {
      position: fixed;
      min-height: 100vh;
      max-height: 100vh;
      flex-direction: column;
      justify-content: start;
      z-index: 9999;

      & > ul {
        display: flex;
        flex-direction: column;
      }

      .social {
        display: flex;
        margin: 2rem auto;
        align-items: center;
        text-align: center;
      }
    }
  }

  @media ${Breakpoints.md} {
    padding: 1rem 3rem;
  }
`

const HamburguerMenu = styled.div`
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
  padding: .5rem;
  cursor: pointer;

  span {
    transition: all .3s ease;
    content: '';
    position: absolute;
    height: 1px;
    background: var(--color-brand);
    right: .5rem;
    width: 1rem;
  }

  span:nth-child(1),
  span:nth-child(3) {
    transform-origin: 1px;
  }

  span:nth-child(1) {
    transform: ${({ open }) => open ? 'rotate(45deg) translateY(4px)' : 'rotate(0)'};
  }

  span:nth-child(3) {
    transform: ${({ open }) => open ? 'rotate(-45deg) translateY(-3px)' : 'rotate(0)'};
  }
  
  span:nth-child(2) {
    width: .625rem;
    top: 50%;
    transform: ${({ open }) => open ? 'translate(1rem,-50%)' : 'translate(0,-50%)'};
    opacity: ${({ open }) => open ? '0' : '1'};
  }

  span:nth-child(3) {
    bottom: .5rem;
  }

  @media ${Breakpoints.md} {
    display: none;
  }
`

const Header = () => {
  const [isOpened, setOpened] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);

  const toggleOpened = () => {
      document.querySelector("body").classList.toggle("overflow-hidden",!isOpened);
      setOpened(!isOpened);
  }

  return (
    <DialogContext.Provider value={{ dialogOpened, setDialogOpened }}>
      <HeaderBlock open={isOpened} className={(isOpened) ? "isOpened" : ""}>
        <div className="logoArea">
          <Logo />
          <HamburguerMenu open={isOpened} onClick={toggleOpened}>
            <span></span>
            <span></span>
            <span></span>
          </HamburguerMenu>
        </div>
        
        <MainMenu className="mainMenu" menuItems={Menu} />
        <MainMenu className="userMenu" menuItems={UserMenu} />
        <Social className="social" />
      </HeaderBlock>

      <ExitDialog />

    </DialogContext.Provider>
  );
};

export default Header;
