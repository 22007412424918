import { useContext } from "react";
import UserContext from "../context/UserContext";
import CheckPermission from "../helpers/FeaturePermission";

const Points = () => {
    const {user} = useContext(UserContext);

    let pointsMatrix = [
        {
            id: "Point",
            name: "Pontos",
            value: user.member.FieloPLT__Points__c,
            expiring: {
                value: user.mapNextExpPoints.Point?.expirePoints || null,
                in: user.mapNextExpPoints.Point?.expireDate || null
            },
            action: {
                label: "Extrato de pontos",
                to: "/extrato-de-pontos",
            },
            definition: [
                {
                    message: "São acumulados através de vendas, são válidos por 24 meses e podem ser utilizados para resgate na loja de marketing e por produtos, serviços ou viagens.",
                    permission: [
                        "ACUMULE_PONTOS_VENDEDOR"
                    ],
                },
                {
                    message: "São acumulados através de compras, são válidos por 24 meses e podem ser utilizados para resgate na loja de marketing e por produtos, serviços ou viagens.",
                    permission: null,
                },
            ]
        }
    ];

    if(typeof user.member.Vmc__c !== "undefined" && CheckPermission("VMC")) {
        pointsMatrix.push(
            {
                id: "Vmc",
                name: "Pontos VMC",
                value: user.member.Vmc__c,
                expiring: {
                    value: user.mapNextExpPoints.VMC?.expirePoints || null,
                    in: user.mapNextExpPoints.VMC?.expireDate || null
                },
                action: {
                    label: "Extrato de pontos",
                    to: "/extrato-de-pontos",
                },
                definition: [
                    {
                        message: "Pontos extras creditados a cada trimestre caso seja atingida a meta da categoria. Seu uso é exclusivo para troca na Loja de Marketing.",
                        permission: null,
                    }
                ]
            }
        );
    }

    if(typeof user.member.Pontoscampanha__c !== "undefined" && CheckPermission("CARTAO_LIKE_MAIS")) {
        pointsMatrix.push(
            {
                id: "Pontos Campanha",
                name: "Pontos Campanha",
                value: user.member.Pontoscampanha__c,
                expiring: {
                    value: user.mapNextExpPoints["Pontos Campanha"]?.expirePoints || null,
                    in: user.mapNextExpPoints["Pontos Campanha"]?.expireDate || null
                },
                action: {
                    label: "Extrato de pontos",
                    to: "/extrato-de-pontos",
                },
                definition: [
                    {
                        message: "Ao participar de campanhas com cartão de crédito Intebras Pontua, você receberá a pontuação equivalente ao seu prêmio. Cada ponto equivale R$ 1,00 em créditos no seu cartão.",
                        permission: null,
                    }
                ]
            }
        );
    }


    return pointsMatrix;
}

export default Points;
