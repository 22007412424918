import {TextField, MenuItem, Skeleton, CircularProgress, IconButton, Drawer, Typography} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import PrepareFetch from "../helpers/PrepareFetch";
import PointsFormatUtil from "../helpers/PointFormatUtil";
import DateFormatUtil from "../helpers/DateFormatUtil";
import { FilterButton, FilterBlock } from "../atom/FilterButton";
import StyledButton from "../atom/Button";

import alertSkeleton from "../assets/icons/alertSkeleton.svg";
import plusSign from "../assets/icons/plusSign.svg";
import minusSign from "../assets/icons/minusSign.svg";
import MuiBlock from "../atom/MuiCustoms";
import Breakpoints from "../config/Breakpoints";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LinkArrowRight from '../assets/icons/linkArrowRight.svg';

const PointStatementSearchField = (props) => {
    const [value, setValue] = useState(props.defaultValue);

    const HandleChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <TextField
            placeholder="Buscar NF (nº ou CNPJ)"
            autoComplete="off"
            value={value}
            onKeyDown={props.onKeyDown} 
            onChange={HandleChange}
            size="small"
            sx={(theme) => ({
                "&": {
                    width: "100%"
                },

                "& .MuiOutlinedInput-root": {
                  paddingRight: "0",
                }
            })}
            InputProps={{
                endAdornment: (
                    <IconButton
                        onClick={() => {
                            if(props.defaultValue === '') {
                                props.onEveryChange({
                                    target: {
                                        value: value
                                    }
                                })
                            } else {
                                setValue("");
                                props.onEveryChange({
                                    target: {
                                        value: ''
                                    }
                                })
                            }
                            
                            
                        }}
                    >
                        {props.defaultValue === '' && (
                            <SearchOutlinedIcon />
                        )}
                        {props.defaultValue !== '' && (
                            <CloseOutlinedIcon/>
                        )}
                    </IconButton>
                )
            }}
        >
        </TextField>
    )
}

const PointStatementReport = ({className, pointType, mode, partnerId}) => {
    // TODO: Fazer carregamento das demais páginas
    if(typeof mode === "undefined") {
        mode = "statement";
    }
    const MonthFilterOptions = () => {
        return [
            {
                name: "3 meses",
                value: 3,
                default: true,
            },
            {
                name: "6 meses",
                value: 6,
                default: false,
            },
            {
                name: "1 ano",
                value: 12,
                default: false,
            },
            {
                name: "2 anos",
                value: 24,
                default: false,
            },
        ]
    }

    const orderOptions = [
        {
            label: "Mais recentes primeiro",
            value: "CreatedDate desc"
        },
        {
            label: "Mais antigos primeiro",
            value: "CreatedDate asc"
        },
    ]
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [ drawerOpened, openPartnerDrawer ] = useState(false);
    const [ drawerTitle, setDrawerTitle ] = useState('');
    const [ currentPartnerId, setCurrentPartnerId ] = useState(null);
    
    const [query, setQuery] = useState({
        mode: mode,
        page: 1,
        orderBy: "CreatedDate desc",
        lastNMonth: MonthFilterOptions().filter((item) => {
                        if(item.default) {
                            return true;
                        }

                        return false;
                    })[0].value,
        pageSize: 20,
        pointType: pointType,
        searchQuery: "",
        searchText: "",
        reset: true,
    });
    const orderBy = query.orderBy;

    const updateQuery = (newObj) => {
        setQuery((prevQuery, props) => {
            return {
                ...prevQuery,
                ...newObj
            }
        });
    }

    const MonthFilterButtons = () => {
        let returnedOptions = MonthFilterOptions().map((item, index) => {
            return (
                <FilterButton key={"filterbutton_"+pointType+"_"+index} onClick={() => updateQuery({reset: true, page: 1, lastNMonth: item.value})} selected={(query.lastNMonth === item.value)}>{item.name}</FilterButton>
            )
        })
        
        return returnedOptions;
    }

    const HandleOrderBy = (e) => {
        setQuery((prevQuery, props) => {
            return {
                ...prevQuery,
                orderBy: e.target.value,
                page: 1,
                reset: true
            }
        })
    }

    const HandleSearch = (e) => {
        let localQuery = e.target.value;

        if(query.searchText !== localQuery) {
            setQuery((prevQuery, nprops) => {
                return {
                    ...prevQuery,
                    searchQuery: localQuery.replace(/\.|-|\//g,""),
                    searchText: localQuery,
                    page: 1,
                    reset: true
                }
            });
        }
    }

    const HandleSearchPress = (e) => {
        if(e.key === "Enter") {
            HandleSearch(e);
        }
    }

    const StatementItemsRender = () => {
        return data.map((item, index) => {
            let params = {
                key: "",
                date: "",
                points: "",
                description: ""
            }

            if(mode !== "expiring") {
                params.key = "statement_item_"+index;
                params.date = item.createdDate;
                params.points = item.totalPoints;
                params.description = item.description;
                params.partnerExtract = item.showPartnerExtract ? item.originId : null;
                params.invoice = {
                    number: item.numeroNf,
                    date: item.dataEmissao,
                    cnpjEmitente: item.cnpjEmitente,
                    cnpjRevenda: item.cnpjRevenda,
                }
            } else {
                params.key = "expiring_item_"+index;
                params.date = item.expireDate;
                params.points = item.expirePoints;
                params.description = "Pontos a expirar";
                params.partnerExtract = null;
            }

            return (
                <PointStateItem key={params.key} date={params.date} points={params.points} description={params.description} invoice={params.invoice} partnerExtract={params.partnerExtract} openPartnerDrawer={openPartnerDrawer} setCurrentPartnerId={setCurrentPartnerId} setDrawerTitle={setDrawerTitle} />
            )
        });
    }

    useEffect(() => {
        let fetchEndpoint = "/proxy/services/apexrest/api/v1/points?";
        if(query.mode === 'expiring') {
            fetchEndpoint = "/proxy/services/apexrest/api/v1/points/expire?";
        } else if(query.mode === 'partner') {
            fetchEndpoint = `/proxy/services/apexrest/api/v1/points/partner/${partnerId}?`
        }

        fetchEndpoint += `pointType=${query.pointType}&`;
        fetchEndpoint += `page=${query.page}&pageSize=${query.pageSize}&`;
        fetchEndpoint += `lastNMonth=${query.lastNMonth}&`;
        fetchEndpoint += `orderBy=${query.orderBy}`;
        if(query.searchQuery !== "") {
            fetchEndpoint += `&searchQuery=${query.searchQuery}`;
        }

        let fetchData = PrepareFetch(fetchEndpoint,"GET",{
            "cache-by": "user"
        });

        setLoading(true);

        (async () => {
            await fetch(fetchData.url, fetchData.options)
                    .then(response => {
                        if(response.status === 200) {
                            return response.json();
                        }

                        throw new Error(response);
                    })
                    .then(json => {
                        setLoading(false);

                        if(query.reset) {
                            setData(json);
                        } else {
                            setData((prevData,props) => {
                                return [
                                    ...prevData,
                                    ...json
                                ]
                            });
                        }
                        setHasNextPage((json.length === query.pageSize));
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);

                        console.warn("Erro ao carregar o extrato");
                        if(process.env.VERCEL_ENV !== "production") {
                            console.log(error);
                        }
                    });
        })();

    },[query])

    useEffect(() => {
        console.log(drawerOpened);
    },[drawerOpened])

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 1;
    `

    const ReportBlock = styled.div`
        padding: .5rem;
        border-radius: .5rem;
        border: 1px solid var(--color-lightGray);
        flex-grow: 1;

        @media ${Breakpoints.md} {
            padding: 1.5rem;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .notFound {
            color: var(--color-blueGrayDark);
        }

        .showMore {
            padding: 2rem;
            text-align: center;
        }
    `

    return (
        <div style={{flexGrow: 1, display: "flex"}}> 
            {error && (
                <div className="warningBlock">
                    <img src={alertSkeleton} alt="" />
                    Não foi possível carregar o conteúdo.
                </div>
            )}
            {!error && (
                <>
                    <Container className={className}>
                        <FilterBlock>
                            <div className="filterButtons">
                                { MonthFilterButtons() }
                            </div>
                            {mode === "statement" && (
                                <div className="searchField">
                                    <PointStatementSearchField 
                                        defaultValue={query.searchText} 
                                        onKeyDown={HandleSearchPress} 
                                        onEveryChange={HandleSearch}
                                    ></PointStatementSearchField>
                                        
                                </div>
                            )}
                            <div className="filterFields">
                                <MuiBlock>
                                    <TextField
                                        select
                                        label="Ordenar por"
                                        onChange={HandleOrderBy}
                                        value={orderBy}
                                        size="small"
                                        sx={{width: "100%"}}
                                    >
                                        {orderOptions.map((option, index) => (
                                            <MenuItem key={"orderBy_"+index} value={option.value} sx={{display: "flex !important", paddingTop: ".5rem !important", paddingBottom: ".5rem !important"}}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </MuiBlock>
                            </div>
                        </FilterBlock>
                        {(loading && query.page === 1) && (
                            <Skeleton
                                sx={{
                                    aspectRatio: "16/5",
                                    borderRadius: ".5rem",
                                    height: "auto",
                                    width: "100%"
                                }}
                                variant="rectangular"
                                width="100%"
                            />
                        )}
                        {((!loading || (loading && query.page > 1)) && !error) && (
                            <ReportBlock>
                                {data.length === 0 && (
                                    <div className="notFound">
                                        Não há itens para o critério selecionado.
                                    </div>
                                )}
                                {data.length > 0 && (
                                    <ul>
                                        { StatementItemsRender() }
                                    </ul>
                                )}
                                {hasNextPage && (
                                    <div className="showMore">
                                        <StyledButton className="secondary small" disabled={(loading && query.page > 1)} onClick={() => { updateQuery({reset: false, page: (query.page+1)})}}>
                                            Mostrar mais
                                            {(loading && query.page > 1) && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: "var(--color-brand)",
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        margin: "-12px 0 0 -12px"
                                                    }}
                                                />
                                            )}
                                        </StyledButton>
                                    </div>
                                )}
                            </ReportBlock>
                        )}
                    </Container> 
                    {mode === 'statement' && (
                        <PartnerStatementReport title={drawerTitle} opened={drawerOpened} partnerId={currentPartnerId} setOpened={openPartnerDrawer} pointType={pointType} />
                    )}
                </>
            )}
        </div>
    )
}

const PointStateItem = ({className, date, points, description, invoice, partnerExtract, openPartnerDrawer, setDrawerTitle, setCurrentPartnerId}) => {
    let PointItemBlock = styled.li`
        padding: 1rem;
        display: flex;
        flex-direction: row;
        border-radius: .25rem;
        align-items: center;
        gap: 1rem;
        justify-content: stretch;

        .symbolBlock {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            gap: .5rem;
            align-items: center;

            div {
                padding: .5rem;
                border-radius: 99px;
                background: var(--color-xLightGray);
                line-height: 1.5rem;
                width: 2.5rem;
                height: 2.5rem;
                box-sizing: border-box;
            }

            span {
                flex-grow: 1;
                display: inline-block;
                width: 1px;
                min-height: .5rem;
                background: var(--color-lightGray);
            }
        }

        .infoBlock {
            flex-grow: 1;
            align-self: flex-start;
            padding-top: .5rem;

            strong {
                display: block;
                color: var(--color-black);

                &.hasLink {
                    display: flex;
                    align-items: center;
                    color: var(--color-brand);
                    gap: .5rem;
                }
            }

            small {
                font-size: var(--font-size-xs);
                color: var(--color-blueGrayDark);
            }

            span {
                display: block;
                font-size: var(--font-size-sm);
            }
        }

        .pointBlock {
            font-weight: var(--font-weight-bold);
            color: var(--color-brand);
        }

        &:nth-child(even) {
            background: var(--color-xLightGray);

            .symbolBlock div {
                background: var(--color-white);
            }
        }

        &:last-child {
            .symbolBlock span {
                display: none;
            }
        }
    `

    const [invoiceData, setInvoiceData] = useState("");

    useEffect(() => {
        if(typeof invoice === "undefined") {
            return;
        }

        let invoicePhrase = [];
        if(invoice.number !== null) {
            invoicePhrase.push(`NF nº ${invoice.number}`);
        }
        if(invoice.date !== null) {
            invoicePhrase.push(` - Emitida em ${DateFormatUtil(invoice.date, {day: "2-digit", month: "2-digit", year: "numeric"})}`);
        }
        if(invoicePhrase.length > 0) {
            invoicePhrase.push('<br>');
        }
        if(invoice.cnpjEmitente !== null) {
            let cnpjEmitente = invoice.cnpjEmitente.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            invoicePhrase.push(`Emitente: ${cnpjEmitente}`);
        }
        if(invoice.cnpjRevenda !== null) {
            let cnpjRevenda = invoice.cnpjRevenda.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            invoicePhrase.push(` - Cliente: ${cnpjRevenda}`);
        }
        

        setInvoiceData(() => invoicePhrase.join(" "));
    },[setInvoiceData, invoice])

    function openDrawer(id) {
        setDrawerTitle(`Extrato detalhado: ${(description.replace('Transferência de saldo de ', ''))}`)
        setCurrentPartnerId(id);
        openPartnerDrawer(true);
    }

    return (
        <PointItemBlock className={className}>
            <div className="symbolBlock">
                <div><img src={(parseInt(points) > 0 ? plusSign : minusSign)} alt="" /></div>
                <span></span>
            </div>
            <div className="infoBlock">
                {partnerExtract !== null && (
                    <strong className='hasLink' onClick={() => openDrawer(partnerExtract)} style={{cursor: 'pointer'}}>
                        {description}
                        <img src={LinkArrowRight} alt="" />
                    </strong>
                )}
                {partnerExtract === null && (
                    <strong>{description}</strong>
                )}
                <small>em {DateFormatUtil(date, {day: "2-digit", month: "2-digit", year: "numeric", hour: "numeric", minute: "numeric"})}</small>
                {invoiceData !== "" && (
                    <span dangerouslySetInnerHTML={{__html: invoiceData}}></span>
                )}
            </div>
            <div className="pointBlock">
                {PointsFormatUtil(points)}
            </div>
        </PointItemBlock>
    )
}

const PartnerStatementReport = ({title, opened, partnerId, pointType, setOpened}) => {
    return (
        <Drawer 
            open={opened} 
            onClose={() => setOpened(false)} 
            variant='temporary' 
            anchor='right'
            PaperProps={{
                sx: {
                    width: {
                        xs: '100%',
                        md: '700px'
                    },
                    boxSizing: 'border-box',
                    padding: '.5rem 2rem 2rem'
                }
            }}
        >
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem'}}>
                <IconButton aria-label="fechar" onClick={() => setOpened(false)}>
                    <CloseOutlinedIcon />
                </IconButton>
                <Typography variant="h6" sx={{flex: '1'}}>
                    {title}
                </Typography>
            </div>
            {partnerId !== null && (
                <PointStatementReport pointType={pointType} mode='partner' partnerId={partnerId} />
            )}
        </Drawer>
    )
}

export default PointStatementReport;