import bannerFontes from "../assets/images/static/a1EDo000000VOWRMA4.webp";
import bannerParceria from "../assets/images/static/a1EDo000001bdUkMAI.webp";
import bannerVmc from "../assets/images/static/a1EDo000001lU1HMAU.webp";
import bannerVendeuGanhou5x from "../assets/images/static/a1EU60000000KbRMAU.webp";
import bannerVendeuGanhouProrrogado from "../assets/images/static/a1EU60000000Kd3MAE.webp";
import bannerNatal from "../assets/images/static/a1EU60000000ZqbMAE.webp";
import regulamento from "../assets/images/static/a03Do000009Co4IIAS.pdf";

const ResourceStaticReplaceUtil = (resourceUrl) => {
    let rtn = resourceUrl;
    let staticReplacementMap = [
        {
            searchFor: 'ids=068U6000000SUF4',
            newUrl: bannerNatal
        },
        {
            searchFor: 'ids=068U6000000SX9S',
            newUrl: bannerVendeuGanhou5x
        },
        {
            searchFor: 'ids=068Do00000B83IL',
            newUrl: bannerParceria
        },
        {
            searchFor: 'ids=068U6000000SVLs',
            newUrl: bannerVendeuGanhouProrrogado
        },
        {
            searchFor: 'ids=068U6000000Sb6I',
            newUrl: bannerFontes
        },
        {
            searchFor: 'ids=068U6000000SVhO',
            newUrl: bannerVmc
        },
        {
            searchFor: '/a/Do0000008ZRt',
            newUrl: regulamento
        },
        { // test
            searchFor: 'ids=068DL000002clB6&',
            newUrl: bannerVendeuGanhou5x
        },
        { // test
            searchFor: '/a/DL000000HDYM',
            newUrl: regulamento
        }
    ];

    staticReplacementMap.forEach((item) => {
        if(resourceUrl.indexOf(item.searchFor) > -1) {
            rtn = item.newUrl;
            return false;
        }
    });

    return rtn;
}

export default ResourceStaticReplaceUtil;