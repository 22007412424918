import styled from "styled-components";
import { Link } from "react-router-dom";

import FaqList from "../atom/Faq";
import Breakpoints from "../config/Breakpoints";
import arrowRight from "../assets/icons/linkArrowRightWhite.svg";

const FaqHighlightBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;
    flex: 1;

    .titleBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: .5rem;

        @media ${Breakpoints.sm} {
            flex-direction: row;
        }
    }

    h2 {
        margin: 0;
        font-size: var(--font-size-lg);
        line-height: 1em;
        color: var(--color-black);

        @media ${Breakpoints.sm} {
            font-size: var(--font-size-xl);
        }
    }

    .button {
        display: inline-flex;
        width: fit-content;
        height: fit-content;
        padding: .5rem 1rem;
        border-radius: 99px;
        background: var(--color-brand);
        gap: .5rem;
        color: var(--color-white);
        text-decoration: none;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-sm);
        align-items: center;

        img {
            width: 1rem;
        }
    }

    .faqBlock {
        padding: 1rem 1rem 1.5rem;
        border-radius: .5rem;
        background: var(--color-xLightGray);
    }

    .linkToFaq {
        display: inline-flex;
        width: fit-content;
        margin: 1rem 0;
        gap: .5rem;
        font-size: var(--font-size-sm);
        color: var(--color-gray);

        a {
            color: var(--color-brand);
            font-weight: var(--font-weight-bold);
            text-decoration: none;
        }

        @media ${Breakpoints.only.xs} {
            flex-direction: column;
            gap: .25rem;
        }
    }

    @media ${Breakpoints.md} {
        .titleBlock,
        .faqBlock {
            padding-left: 4.75rem;
        }

        .faqBlock {
            padding-top: .5rem;
            padding-right: 4.75rem;
        }
    }
`

const FaqHighlight = ({className, title, container}) => {
    if(typeof title === "undefined") {
        title = "Verifique as dúvidas mais frequentes:";
    }
    return (
        <FaqHighlightBlock className={className}>
            <div className="titleBlock">
                <h2>{title}</h2>
                <Link to="/duvidas-frequentes" className="button">
                    Saiba mais
                    <img src={arrowRight} alt="" />
                </Link>
            </div>
            <div className="faqBlock">
                <FaqList container={container} />
                <span className="linkToFaq">
                    Não encontrou o que procurava?
                    <Link className="linkToFaq__link" to="/fale-conosco">
                        Entre em contato conosco
                    </Link>
                </span>
            </div>
        </FaqHighlightBlock>
    )
}

export default FaqHighlight;