import styled from "styled-components";
import { Tab, Skeleton, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, useEffect } from "react";

import CheckPermission from "../helpers/FeaturePermission";
import PrepareFetch from "../helpers/PrepareFetch";
import PointsFormatUtil from "../helpers/PointFormatUtil";
import DateFormatUtil from "../helpers/DateFormatUtil";
import MuiBlock from "../atom/MuiCustoms";
import Breakpoints from "../config/Breakpoints";

import { FilterButton, FilterBlock } from "../atom/FilterButton";
import RedemptionStatus from "../atom/RedemptionStatus";
import StyledButton from "../atom/Button";

import noImage from "../assets/images/store-noPicture.png";
import alertSkeleton from "../assets/icons/alertSkeleton.svg";

const RedemptionTabs = ({className}) => {
    const [tab, setTab] = useState("LOJA_MARKETING");
    const hasVmc = CheckPermission("CARTAO_LIKE_MAIS");

    const handleTab = (event, newValue) => {
        setTab(newValue);
    };

    const StyledTab = styled(Tab)`
        text-transform: none !important;
        font-weight: var(--font-weight-bold) !important;
        font-size: 1rem;
        color: var(--color-blueGrayDark);

        
        &.MuiButtonBase-root {
            padding-left: 1rem;
            padding-right: 1rem;
            color: var(--blueGrayDark);
        }
        
        &.Mui-selected {
            color: var(--color-brand);
        }

        &:first-child {
            margin-left: 1rem;
        }
    `

    const StyledPanel = styled(TabPanel)`
        padding: 0 !important;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        &[hidden] {
            display: none;
        }
    `

  return (
    <MuiBlock style={{flexGrow: 1, width: "100%", display: "flex", flexDirection: "column"}}>
      <TabContext value={tab}>
        <TabList onChange={handleTab} aria-label="" variant="scrollable" scrollButtons={false}>
          <StyledTab label="Loja de Marketing" value="LOJA_MARKETING" />
          <StyledTab label="Catálogo de prêmios" value="PREMMIAR" />
          {hasVmc && (
            <StyledTab label="Cartão de Crédito Intelbras Pontua" value="CARTAO_CREDITO" />
          )}
        </TabList>
        <StyledPanel value="LOJA_MARKETING">
            <RedemptionBlock container="LOJA_MARKETING" />
        </StyledPanel>
        <StyledPanel value="PREMMIAR">
        <RedemptionBlock container="PREMMIAR" />
        </StyledPanel>
        {hasVmc && (
            <StyledPanel value="CARTAO_CREDITO">
                <RedemptionBlock container="CARTAO_CREDITO" />
            </StyledPanel>
        )}
      </TabContext>
    </MuiBlock>
  )
}

const RedemptionBlock = ({className, style, container}) => {
    if(typeof container === "undefined" || container === "") {
        container = "";
    } 

    const MonthFilterOptions = () => {
        return [
            {
                name: "3 meses",
                value: 3,
                default: true,
            },
            {
                name: "6 meses",
                value: 6,
                default: false,
            },
            {
                name: "1 ano",
                value: 12,
                default: false,
            },
            {
                name: "2 anos",
                value: 24,
                default: false,
            },
        ]
    }

    const Container = styled.div`
        .redemptionContainer {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    `

    const RedemptionsContainer = styled.div`
        padding: 2rem 1.5rem;
        border-radius: .5rem;
        border: 1px solid var(--color-lightGray);

        .showMore {
            padding: 2rem;
            text-align: center;
        }
    `

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [query, setQuery] = useState({
        container: container,
        page: 1,
        pageSize: 10,
        lastNMonth: MonthFilterOptions().filter((item) => {
            if(item.default) {
                return item.value
            } 
            return false;
        })[0].value,
        reset: true,
    });

    const updateQuery = (newObj) => {
        if(newObj.reset) {
            newObj.page = 1;
        }

        setQuery({
            ...query,
            ...newObj
        })
    }

    const MonthFilterButtons = () => {
        let returnedOptions = MonthFilterOptions().map((item, index) => {
            return (
                <FilterButton key={"filterbutton_"+index} onClick={() => updateQuery({lastNMonth: item.value, reset: true})} selected={(query.lastNMonth === item.value)}>{item.name}</FilterButton>
            )
        })
        
        return returnedOptions;
    }
    
    
    useEffect(() => {
        let fetchParams = PrepareFetch(`/proxy/services/apexrest/api/v1/redemptions?page=${query.page}&pageSize=${query.pageSize}&lastNMonth=${query.lastNMonth}&container=${container}`,"GET",{
            "cache-by": "user"
        });

        (async () => {
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                          return response.json();
                        }
      
                        throw new Error(response);
                    })
                    .then(json => {
                        setLoading(false);
                        if(query.reset) {
                            setData(json);
                        } else {
                            setData((prevState,props) => {
                                return [
                                    ...prevState,
                                    ...json
                                ]
                            })
                        }
                        setHasNextPage(json.length === query.pageSize);
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
          })();
    },[query, container])

    return (
        <Container className={className} style={style}>
            {(loading && query.page === 1) && (
                <Skeleton
                    sx={{
                        aspectRatio: "16/5",
                        borderRadius: ".5rem",
                        height: "auto",
                        width: "100%"
                    }}
                    variant="rectangular"
                    width="100%"
                />
            )}
            {(!loading || (loading && query.page > 1)) && !error && (
                <section className="redemptionContainer">
                    <FilterBlock>
                        <div className="filterButtons">
                            {MonthFilterButtons()}
                        </div>
                    </FilterBlock>
                    <RedemptionsContainer>
                        {data.length === 0 && (
                            <>
                                Não há pedidos registrados nos últimos
                                {" " + MonthFilterOptions().filter((item) => {
                                    if(item.value === query.lastNMonth) {
                                        return true;
                                    }
                                    return false;
                                })[0].name}
                            </>
                        )}
                        {data.length > 0 && data.map((item) => {
                            return (
                                <RedemptionItem key={`redemption_${item.id}`} redemptionData={item} />
                            )
                        })}
                        {hasNextPage && (
                            <div className="showMore">
                                <StyledButton className="secondary small" disabled={(loading && query.page > 1)} onClick={() => { updateQuery({reset: false, page: (query.page+1)})}}>
                                    Mostrar mais
                                    {(loading && query.page > 1) && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "var(--color-brand)",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                margin: "-12px 0 0 -12px"
                                            }}
                                        />
                                    )}
                                </StyledButton>
                            </div>
                        )}
                    </RedemptionsContainer>
                </section>
            )}
            {error && (
                <div className="warningBlock">
                    <img src={alertSkeleton} alt="" />
                    Não foi possível carregar o conteúdo.
                </div>
            )}
            
        </Container>
    )
}

const RedemptionItem = ({className, redemptionData}) => {
    const Container = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid var(--color-lightGray);

        .statusId {
            @media ${Breakpoints.max.xs} {
                position: absolute;
                display: inline-block;
                top: 1.5rem;
                right: 0;
                width: 5rem;
                text-align: right;

                span {
                    display: block;
                }
            }
        }

        &:first-child {
            padding-top: 0;

            .statusId {
                @media ${Breakpoints.max.xs} {
                    top: 0;
                }
            }
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        & > .thumb {
            position: relative;
            width: 5rem;

            img {
                width: 100%;
            }

            & > .hasMore {
                position: absolute;
                bottom: 0;
                right: 0;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 99px;
                text-align: center;
                line-height: 1.5rem;
                font-size: var(--font-size-xs);
                font-weight: var(--font-weight-bold);
                color: var(--color-white);
                background: var(--color-brand);
            }
        }

        & > .titleBlock {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            font-size: var(--font-size-md);
            color: var(--color-black);

            small {
                font-size: var(--font-size-sm);
                color: var(--color-gray);
            }

            strong sup {
                display: inline-flex;
                background: var(--color-xLightGray);
                color: var(--color-blueGrayDark);
                margin-left: .5rem;
                padding: .125rem .25rem;
                border-radius: 99px;
                vertical-align: middle;
            }
        }

        & > .infoBlock {
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            align-items: center;

            font-size: var(--font-size-sm);
            white-space: nowrap;

            @media ${Breakpoints.max.sm} {
                flex-wrap: wrap;
                
                .btn {
                    width: 100%;
                }
            }
            
        }

        & .statusBlock {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            font-size: var(--font-size-sm);
            color: var(--color-gray);
        }

        

        @media ${Breakpoints.md} {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    `
    const image = redemptionData.items[0].image !== null ? redemptionData.items[0].image : noImage;
    const link = window.location.href.indexOf("/loja-de-marketing") > -1 ? `/loja-de-marketing/pedidos/${redemptionData.id}` : `/pedidos/${redemptionData.id}`;

    return (
        <Container>
            <div className="thumb">
                <img src={image} alt="" />
                {redemptionData.items.length > 1 && (
                    <span className="hasMore">+{redemptionData.items.length - 1}</span>
                )}
            </div>
            <div className="titleBlock" style={{flex: "1 1 0"}}>
                <div className="statusBlock">
                    <strong className="statusId">Pedido <span>{redemptionData.name}</span></strong>
                    <RedemptionStatus status={redemptionData.status} />
                    <span>
                        em {DateFormatUtil(redemptionData.closedDate)}
                    </span>
                </div>
                <div>
                    {redemptionData.items.length > 1 && (
                        <>
                            {redemptionData.items.map((item,index) => {
                                let totalListedItens = 2;
                                if(index > (totalListedItens - 1)) {
                                    return false;
                                }

                                return (
                                    <div key={`product_${redemptionData.id}_${item.id}`}>
                                        – <strong>
                                            {item.name}
                                            {index === (totalListedItens - 1) && redemptionData.items.length > totalListedItens && (
                                                <sup>+ {`${(redemptionData.items.length - totalListedItens)} ${(redemptionData.items.length - totalListedItens) === 1 ? " item" : " itens"}`}</sup>
                                            )}
                                        </strong>
                                        
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {redemptionData.items.length === 1 && (
                        <div>
                            <strong>{redemptionData.items[0].name}</strong>
                        </div>
                    )}
                    
                    
                </div>
            </div>
            <div className="infoBlock">
                <div>
                    <strong>Valor:</strong> {PointsFormatUtil(redemptionData.total)} pontos
                </div>
                <StyledButton component={Link} to={link} className="secondary small btn" style={{borderRadius: ".25rem"}}>Ver detalhes</StyledButton>
            </div>
        </Container>
    )
}


export { RedemptionTabs, RedemptionBlock };