import { TextField, MenuItem, CircularProgress, Snackbar, Alert } from "@mui/material";
import styled from "styled-components";
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask'
import { useNavigate } from "react-router-dom";

import { NumberExport } from '../helpers/PhoneNumberUtils';
import PrepareFetch from "../helpers/PrepareFetch";
import ParseApexError from "../helpers/ParseApexErrorMessage";

import MuiBlock from "../atom/MuiCustoms";
import Breakpoints from "../config/Breakpoints";
import StyledButton from "../atom/Button";
import { ShoppingCartItem } from "../components/ShoppingCartItems";
import PointsFormatUtil from "../helpers/PointFormatUtil";

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1.5rem;
    border-radius: .5rem;
    border: 1px solid var(--color-lightGray);

    .formRow {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media ${Breakpoints.sm} {
            flex-direction: row;
            align-items: center;
        }
    }

    .formHelper {
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-bold);
    }

    &.review {
        gap: 2rem;
    }

    .productList {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .totalBlock {
        background: var(--color-xLightGray);
        border-radius: .5rem;
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: var(--font-size-sm);
        color: var(--color-gray);

        @media ${Breakpoints.sm} {
            flex-direction: row;
            align-items: center;
        }

        span {
            flex: 1 1 0;
        }

        strong {
            color: var(--color-black);
            font-size: var(--font-size-lg);
        }
    }

    .pointsUse {
        & > strong {
            font-size: var(--font-size-sm);
            color: var(--color-blueGrayDark);
        }

        & > ul {
            margin: .25rem 0 0;
            padding: 0;
            list-style: none;

            li {
                display: flex;
                flex-direction: row;
                padding: .75rem 0 .75rem .5rem;
                border-bottom: 1px solid var(--color-lightGray);
                font-size: var(--font-size-sm);

                &:before {
                    content: '•';
                    display: inline-block;
                    margin-right: .25rem;
                }

                &:last-child {
                    border-bottom: none;
                }

                span {
                    flex: 1 1 0;
                }
            }
        }
    }
`

const FormStepHeader = ({children, opened, action}) => {
    const Container = styled.div`
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        color: var(--color-brand);
        padding: 1rem 1.25rem;
        border-radius: .5rem;
        background: var(--color-xLightGray);

        strong {
            font-size: var(--font-size-xl);
            line-height: 1em;
            flex: 1 1 0;
        }

        .action {
            cursor: pointer;
            font-size: var(--font-size-sm);
            font-weight: var(--font-weight-bold);
        }

        &.opened {
            background: none;
            color: var(--color-blueGrayDark);

            .action {
                display: none;
            }
        }

        &.disallowed {
            color: #BBCAD2;

            .action {
                display: none;
            }
        }
    `
    return (
        <Container className={opened === null ? 'disallowed' : (opened === true ? 'opened' : '')}>
            <strong>
                {children}
            </strong>
            <span className="action" onClick={() => {
                action.apply();
            }}>editar</span>
        </Container>
    )
}

const FormFields = ({className, opened, changeStep, setData}) => {
    const {register, control, formState: { errors }, handleSubmit, setValue} = useForm({mode: "onBlur"});
    const [zipcodeLoading, setZipcodeLoading] = useState(false);

    const submitCheckout = (data) => {
        let sendData = {
            address: {
                postalCode: data.postalCode,
                street: data.street,
                streetNumber: data.streetNumber + "",
                streetComplement: data.streetComplement,
                reference: data.reference,
                district: data.district,
                city: data.city,
                state: data.state,
                mobile: NumberExport(data.mobile)
            }
        };
      
       setData(sendData);
       changeStep(prevStepData => {
            return {
                ...prevStepData,
                currentStep: 2,
                stepAllowed: [
                    1, 2
                ]
            }
       });
    }

    const checkPostalCode = (e) => {
        let zipcode = e.target.value.replace(/_|-/g,"");

        if(zipcode.length === 8) {
            (async () => {
              try {
                setZipcodeLoading(true);

                await fetch("https://viacep.com.br/ws/" + zipcode + "/json")
                        .then(response => {
                            setZipcodeLoading(false);
                            if(response.status === 200) {
                                return response.json();
                            }
        
                            throw new Error(response);
                        })
                        .then(json => {
                            if(typeof json.erro === "undefined") {
                                setValue('district',json.bairro,{shouldValidate: true, shouldDirty: true});
                                setValue('city',json.localidade,{shouldValidate: true});
                                setValue('street',json.logradouro,{shouldValidate: true, shouldDirty: true});
                                setValue('state',json.uf,{shouldValidate: true});
                            }
                        })
              } catch (error) {
                setZipcodeLoading(false);
                console.warn("Erro ao carregar dados do CEP");
              }
            })();
          }
    }

    const editStep = () => {
        changeStep(actualStep => {
            return {
                ...actualStep,
                currentStep: 1
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(submitCheckout)} className={className}>
            <FormStepHeader opened={opened} action={() => editStep()}>
                Endereço para entrega
            </FormStepHeader>
            {opened && (
                <FormContainer>
                    <Controller
                        name="postalCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value} }) => (
                            <InputMask 
                                mask="99999-999" 
                                maskChar="_"
                                onChange={(e) => {
                                    onChange(e);
                                    checkPostalCode(e);
                                }}
                                onBlur={onBlur}
                                value={value || ""}
                            >
                                {inputProps => (
                                    <TextField
                                            label="CEP"
                                            type="tel"
                                            fullWidth
                                            variant="outlined"
                                            style={{width: "12.5rem"}}
                                            error={!!errors.postalCode}
                                            required
                                            {...inputProps}
                                        ></TextField>
                                )}
                            </InputMask>
                        )}
                    />
                    
                    <Controller
                        name="street"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ 
                            field: { onChange, onBlur, value}
                        }) => (
                            <TextField
                                label="Endereço"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required
                                fullWidth
                                variant="outlined"
                                disabled={zipcodeLoading}
                                error={!!errors.street}
                            />
                        )}
                    />

                    <div className="formRow">
                        <TextField
                            {...register('streetNumber', {
                                required: true, maxLength: 6
                            })}
                            type="text"
                            label="Número"
                            required
                            maxLength={6}
                            fullWidth
                            variant="outlined"
                            error={!!errors.streetNumber}
                        />

                        <TextField
                            {...register('streetComplement')}
                            label="Complemento"
                            fullWidth
                            variant="outlined"
                            error={!!errors.streetComplement}
                        />

                        <TextField
                            {...register('reference')}
                            label="Referência"
                            fullWidth
                            variant="outlined"
                            error={!!errors.reference}
                        />
                        
                    </div>
                    
                    <Controller
                        name="district"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ 
                            field: { onChange, onBlur, value}
                        }) => (
                            <TextField
                                label="Bairro"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required
                                fullWidth
                                variant="outlined"
                                disabled={zipcodeLoading}
                                error={!!errors.district}
                            />
                        )}
                    />

                    <div className="formRow">
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ 
                                field: { onChange, onBlur, value}
                            }) => (
                                <TextField
                                    label="Cidade"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    disabled={zipcodeLoading}
                                    error={!!errors.city}
                                />
                            )}
                        />
                        
                        <Controller
                            name="state"
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ field: { onChange, onBlur, value} }) => (
                                <TextField
                                    label="Estado"
                                    value={value || ""}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    disabled={zipcodeLoading}
                                    error={!!errors.state}
                                    select
                                >
                                    <MenuItem value="AC">Acre</MenuItem>
                                    <MenuItem value="AL">Alagoas</MenuItem>
                                    <MenuItem value="AP">Amapá</MenuItem>
                                    <MenuItem value="AM">Amazonas</MenuItem>
                                    <MenuItem value="BA">Bahia</MenuItem>
                                    <MenuItem value="CE">Ceará</MenuItem>
                                    <MenuItem value="DF">Distrito Federal</MenuItem>
                                    <MenuItem value="ES">Espírito Santo</MenuItem>
                                    <MenuItem value="GO">Goiás</MenuItem>
                                    <MenuItem value="MA">Maranhão</MenuItem>
                                    <MenuItem value="MT">Mato Grosso</MenuItem>
                                    <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                    <MenuItem value="MG">Minas Gerais</MenuItem>
                                    <MenuItem value="PA">Pará</MenuItem>
                                    <MenuItem value="PB">Paraíba</MenuItem>
                                    <MenuItem value="PR">Paraná</MenuItem>
                                    <MenuItem value="PE">Pernambuco</MenuItem>
                                    <MenuItem value="PI">Piauí</MenuItem>
                                    <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                    <MenuItem value="RN">Rio de Grande do Norte</MenuItem>
                                    <MenuItem value="RS">Rio de Grande do Sul</MenuItem>
                                    <MenuItem value="RO">Rondônia</MenuItem>
                                    <MenuItem value="RR">Roraima</MenuItem>
                                    <MenuItem value="SC">Santa Catarina</MenuItem>
                                    <MenuItem value="SP">São Paulo</MenuItem>
                                    <MenuItem value="SE">Sergipe</MenuItem>
                                    <MenuItem value="TO">Tocantins</MenuItem>
                                </TextField>
                            )}
                        />
                        
                    </div>

                    <div className="formRow">
                        <Controller
                            name="mobile"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value} }) => (
                                <InputMask 
                                    mask="(99) 99999.9999" 
                                    maskChar="_"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value || ""}
                                >
                                    {inputProps => (
                                        <TextField
                                                label="Telefone celular"
                                                type="tel"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.mobile}
                                                required
                                                {...inputProps}
                                                style={{width: "18rem"}}
                                            ></TextField>
                                    )}
                                </InputMask>
                            )}
                        />
                        <div className="formHelper">
                            Para acompanhamento e rastreamento do pedido através de SMS.
                        </div>
                    </div>

                    <StyledButton type="submit" style={{width: "100%", borderRadius: ".5rem"}}>
                        Continuar
                    </StyledButton>
                </FormContainer>
            )}
        </form>
    )
}

const FormConfirmation = ({className, opened, changeStep, loading, submit, total, products, expendedPoints}) => {
    const editStep = () => {
        changeStep(actualStep => {
            return {
                ...actualStep,
                currentStep: 2
            }
        })
    }

    return (
        <div>
            <FormStepHeader opened={opened} action={() => editStep()}>
                Revisão e pagamento
            </FormStepHeader>
            {opened && (
                <FormContainer className="review">
                    <div className="productList">
                        {products.map((item, index) => {
                            return (
                                <ShoppingCartItem key={`shoppingCartItem_${index}`} productData={item} editable={false} />
                            )
                        })}
                    </div>
                    <div className="totalBlock">
                        <span>Total de pontos a serem debitados</span>
                        <strong>{PointsFormatUtil(total)} pontos</strong>
                    </div>
                    <div className="pointsUse">
                        <strong>Resgatados por meio de:</strong>
                        <ul>
                        {(expendedPoints.vmcExpended !== null && expendedPoints.vmcExpended > 0)&& (
                            <li>
                                <span>Pontos VMC</span>
                                <strong>
                                    {PointsFormatUtil(expendedPoints.vmcExpended)} pontos
                                </strong>
                            </li>
                        )}
                        {(expendedPoints.pointsExpended !== null && expendedPoints.pointsExpended > 0) && (
                            <li>
                                <span>Pontos</span>
                                <strong>
                                    {PointsFormatUtil(expendedPoints.pointsExpended)} pontos
                                </strong>
                            </li>
                        )}
                        </ul>
                    </div>
                    <StyledButton onClick={() => submit(true)} disabled={loading} style={{width: "100%", borderRadius: ".5rem"}}>
                        Concluir resgate
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "var(--color-white)",
                                }}
                            />
                        )}
                    </StyledButton>
                </FormContainer>
            )}
        </div>
    )
}

const CheckoutForm = ({total, products, expendedPoints}) => {
    const [step, setStep] = useState({
        currentStep: 1,
        stepAllowed: [
            1
        ]
    });
    const [data, setData] = useState({});
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("Houve um problema ao efetuar o resgate.");
    const navigate = useNavigate();

    useEffect(() => {
        if(submit) {
            const fetchData = PrepareFetch("/proxy/services/apexrest/api/v1/redemptions/checkout","POST",{
                "Content-Type": "application/json"
            },data);
    
            try {
                setError(false);
                setLoading(true);
    
                fetch(fetchData.url, fetchData.options)
                .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    } else {
                        return response.text().then((text) => {
                            throw Error(text)
                        });
                        // throw new Error(response);
                    }
                })
                .then(data => {
                    console.log(data);
                    if(data.message !== null && data.message !== '') {
                        sessionStorage.setItem('cupomFeedbackMessage',data.message);
                    }

                    navigate("/loja-de-marketing/checkout/sucesso");
                })
                .catch(error => {
                    setErrorMessage(ParseApexError(error, 'VN_CouponService.CouponServiceException', 'Erro ao processar o pedido de resgate'));

                    setLoading(false);
                    setError(true);
    
                    console.log("Erro ao processar o pedido de resgate");
                    if(process.env.VERCEL_ENV !== "production") {
                        console.log(error);
                    }
                });
            } catch (error) {
                setErrorMessage(ParseApexError(error, 'VN_CouponService.CouponServiceException', 'Erro ao processar o pedido de resgate'));
                setError(true);
                setLoading(false);
                console.log("Erro ao processar o pedido de resgate");
            }
        }
        // eslint-disable-next-line
    },[submit]);

    return (
        <MuiBlock style={
            {flex: "1 1 0", 
            display: "flex", 
            flexDirection: "column", 
            gap: "2rem",
            marginBottom: "3rem"
        }}>
            <FormFields 
                opened={(step.currentStep === 1 ? true : (!step.stepAllowed.includes(1) ? null : false))} 
                changeStep={setStep} 
                setData={setData} />
            <FormConfirmation 
                opened={(step.currentStep === 2 ? true : (!step.stepAllowed.includes(2) ? null : false))} 
                loading={loading} 
                changeStep={setStep} 
                total={total}
                products={products.items}
                expendedPoints={expendedPoints}
                submit={setSubmit} />

            { error && (
                <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </MuiBlock>
    )
}

export default CheckoutForm;