import styled from "styled-components";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import PrepareFetch from "../helpers/PrepareFetch";
import BannersFacades from "../helpers/BannersFacades";
import Breakpoints from "../config/Breakpoints";
import CardsData from "../config/HomeCards";
import { HighlightCarousel } from "../atom/Carousel";
import AppDownload from "../atom/AppDownload";
import HighlightTroca from "../atom/BoxTroca";
import FaqHighlight from "../atom/FaqHighlight";
import { ContactTitle, ContactItem, ContactBlock } from "../atom/ContactBlocks";
import HighlightCards from "../components/HighlightCardBlock";
import chatDollar from "../assets/icons/chatDollar.svg";
import alertSkeleton from "../assets/icons/alertSkeleton.svg";
import bgSquares from "../assets/images/bg-squares--right.webp";
import ninaAvatar from "../assets/images/ninaAvatar.png";
import iconMessage from "../assets/icons/message.svg";

const HighlightsBlock = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 1.5rem;

    h1 {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        font-size: var(--font-size-xxl);
        color: var(--color-black);
        font-weight: var(--font-weight-bold);
        line-height: 1em;
        margin: 0;

        strong {
            color: var(--color-brand);
        }

        img {
            width: 2.25rem;
            height: 2.25rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .warningBlock {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        height: auto;
        border-radius: .5rem;
        background: var(--color-lightGray);
        color: var(--color-gray);
        justify-content: center;
        align-items: center;
        aspect-ratio: 16/9;
    }

    @media ${Breakpoints.md} {
        .content {
            &.noGap {
                flex-direction: row;
                gap: 0;
                margin: 5rem 0;
            }
        }
    }

    @media ${Breakpoints.lg} {
        padding: 0;
        margin: 0 3rem 2rem;

        h1 {
            align-items: center;
        }

        .content {
            flex-direction: row;
            justify-content: stretch;
        }

        .column {
            flex-grow: 1;
            width: 50%;
        }
    }
`

const HomeAppDownload = styled(AppDownload)`
    flex-direction: column !important;
    background: url(${bgSquares}) top right no-repeat;
    border-top: 1px solid var(--color-lightGray);
    padding: 2.5rem 1rem;

    .titleBlock {
        flex-direction: column;
        justify-content: flex-start;
    }

    strong {
        color: var(--color-black);
    }

    h2 {
        font-size: var(--font-size-xl);
    }

    @media ${Breakpoints.only.sm} {
        padding: 1.5rem 12rem;

        .titleBlock {
            flex-direction: column;
        }
    }

    @media ${Breakpoints.md} {
        box-sizing: border-box;
        padding: 1.5rem 5rem;
        gap: 1.5rem;
        width: 50%;
        border-bottom: 1px solid var(--color-lightGray);

        h2 {
            font-size: var(--font-size-xxl);
        }
    }

    @media ${Breakpoints.lg} {
        padding: 1.5rem 6rem;
    }
`

const Highlights = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [carouselData, setCarouselData] = useState(null);

    useEffect(() => {
        (async () => {
            let fetchParams = PrepareFetch("/proxy/services/apexrest/api/v1/banners?container=Home","GET",{
                "cache-by": "user"
            });
            await fetch(fetchParams.url, fetchParams.options)
                    .then(response => {
                        if(response.status === 200) {
                        return response.json();
                        }

                        throw new Error(response);
                    })
                    .then(json => {
                        setLoading(false);
                        setCarouselData(BannersFacades(json))
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(true);
                    });
        })();
    },[]);

    return (
        <HighlightsBlock>
            <h1>
                <img src={chatDollar} alt="" />
                <span>
                    Troque seus <strong>pontos</strong> por <strong>produtos</strong> e <strong>serviços</strong> incríveis!
                </span>
            </h1>
            <div className="content">
                <div className="column">
                    {(!error && loading) && (
                        <Skeleton
                            sx={{
                                aspectRatio: "16/9",
                                borderRadius: ".5rem",
                                height: "auto",
                                width: "100%"
                            }}
                            variant="rectangular"
                            width="100%"
                        />
                    )}
                    {error && (
                        <div className="warningBlock">
                            <img src={alertSkeleton} alt="" />
                            Não foi possível carregar o conteúdo.
                        </div>
                    )}
                    {!error && !loading && (
                        <HighlightCarousel items={carouselData} />
                    )}
                </div>
                <div className="column">
                    <HighlightCards items={CardsData} />
                </div>
            </div>
            <div className="content noGap">
                <HomeAppDownload headline="Baixe o app SouParceiro" title="Acesse seu saldo e troque seus pontos de forma fácil e rápida" />
                <HighlightTroca />
            </div>
            <div className="content">
                <FaqHighlight container="home" />
            </div>
            <div className="content">
                <ContactBlock>
                    <ContactTitle />
                    <div className="contactItemsBlock">
                        <ContactItem toggleChatbot={true} image={ninaAvatar} headline="Fale com a Nina,">
                            a assistente virtual da Intelbras
                        </ContactItem>
                        <ContactItem link="/fale-conosco" image={iconMessage} headline="Envie sua mensagem">
                            através da área de contato
                        </ContactItem>
                    </div>
                </ContactBlock>
            </div>
        </HighlightsBlock>
    )
}

export default Highlights;
