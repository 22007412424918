import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import { LogoImg } from "../atom/Logo";
import UserBlock from "../atom/UserBlock";
import ExitButton from "../atom/ExitButton";

const BlockHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: var(--color-xLightGray);
  margin: 0 0 2.5rem;

  @media ${Breakpoints.md} {
    margin: 1.5rem 3rem 2.5rem;
    padding: 2rem 2.5rem;
    border-radius: .5rem;
  }

  .userBlock {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
`

const AcceptHeader = () => {
  return (
    <BlockHeader>
      <LogoImg/> 
      <div className="userBlock">
        <UserBlock className="slim" />
        <ExitButton />
      </div>
    </BlockHeader>
  );
};

export default AcceptHeader;
