const FaqFacades = (json) => {
    return json.map(item => {
        return {
            id: item.Id || null,
            question: item.V_Question__c || null,
            answer: item.V_Answer__c || null
        }
    });
}

export default FaqFacades;