import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import { SocialMenu } from "../config/Menu";

const SocialItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--color-blueGrayDark);
  line-height: 1.16em;
  align-items: flex-start;
  width: 5.25rem;

  .icons {
    display: flex;
    gap: .75rem;

    a {
      display: inline-block;
      height: 1.5rem;
    }

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  @media ${Breakpoints.md} {
    line-height: 1.5rem;
    flex-direction: row;
    width: auto;
    align-items: center;
  }
`

const Social = ({className}) => {
    let socialItemsRender = () => {
        return SocialMenu.map(
            (item, index) => {
                return (
                    <Tooltip key={'socialItem_' + index} title={item.name} placement="top">
                        <Link to={item.href} target="_blank" rel="noopener noreferrer">
                            <img
                            src={item.icon}
                            alt={item.name}
                            />
                        </Link>
                    </Tooltip>
                )
            }
        )
    }

    return (
        <SocialItems className={className}>
            <strong>Siga-nos nas redes sociais</strong>
            <div className="icons">
                {socialItemsRender()}
            </div>
        </SocialItems>
    )
}

export default Social;