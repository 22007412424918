import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import Points from "../config/Points";
import UserBlock from "../atom/UserBlock";
import Truck from "../assets/icons/truck.svg";
import PointsDashboardAfter from "../assets/images/pointsDashboardAfter.svg";
import PointCard from "./PointCard";

const PointsDashboardBlock = styled.section`
    padding: 1.5rem;
    background: var(--color-gradient);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;

    .pointsArea {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    @media ${Breakpoints.sm} {
        .pointsArea {
            display: flex;
        }
    }

    @media ${Breakpoints.md} {
        position: relative;
        margin: 0 3rem 5rem;
        padding: 2rem;
        flex-direction: row;
        justify-content: space-between;
        border-radius: .5rem;

        &:after {
            content: '';
            position: absolute;
            bottom: -2.043125rem;
            display: inline-block;
            pointer-events: none;
            width: 23rem;
            height: 8.6rem;
            background: url(${PointsDashboardAfter}) top left no-repeat;
            background-size: 100% auto;
        }

        .userArea {
            padding-top: 1.5rem;
        }

        .userArea,
        .pointsArea {
            position: relative;
            z-index: 2;
        }

        .pointsArea {
            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: flex-end;
        }
    }
`

const PointsDashboard = () => {
    let PointsRender = () => {
        return Points().map(
            (item, index) =>
            <PointCard key={"pointcard_" + index} label={item.name} value={item.value} expiring={item.expiring} action={item.action} definition={item.definition} />
        )
    }

    let Requests = () => {
        let action = {
            label: "Ver pedidos",
            to: "/pedidos"
        }

        return(
            <PointCard icon={Truck} action={action} />
        )
    }

    return (
        <PointsDashboardBlock>
            <div className="userArea">
                <UserBlock />
            </div>
            <div className="pointsArea">
                {PointsRender()}
                {Requests()}
            </div>
        </PointsDashboardBlock>
    );
  };

export default PointsDashboard;
