import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";

const PageContent = ({className, children}) => {
    const PageContentBlock = styled.div`
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 2rem 1.5rem;
        justify-content: space-between;

        h3 {
            font-size: var(--font-size-xxl);
            font-weight: var(--font-weight-bold);
            color: var(--color-black);
            line-height: 1em;
            margin: 0;
        }

        p {
            font-size: var(--font-size-md);
            margin: 0;
            color: var(--color-blueGrayDark);
        }

        .imgArea {
            order: 1;

            img {
                width: 100%;
                border-radius: .5rem;
            }
        }

        .contentArea {
            order: 2;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        .asideArea {
            flex: 1;
            order: 3;
            align-self: flex-start;
            width: 100%;
        }

        @media ${Breakpoints.md} {
            flex-direction: row;
            margin: 3rem 3rem 1rem;
            gap: 2.5rem;
            align-items: center;

            &.singleColumn--md {
                flex-direction: column;
            }

            h3 {
                font-size: 1.75rem;
                letter-spacing: -.5px;
                line-height: 1.875rem;
            }

            .imgArea {
                width: 50%;
                order: 2;
            }
    
            .contentArea {
                flex: 1;
                order: 1;
                padding: 1rem 3rem;

                &.full {
                    width: 100%;
                }
            }

            .asideArea {
                padding-top: 1rem;
            }

            
        }

        @media ${Breakpoints.lg} {
            .contentArea {
                padding-left: 4.5rem;
                padding-right: 4.5rem;
            }
        }
    `

    return (
        <PageContentBlock className={className}>
            {children}
        </PageContentBlock>
    )
}

export default PageContent;