import styled from "styled-components";
import { Link } from "react-router-dom";
import Breakpoints from "../config/Breakpoints";

import { LogoImg } from "../atom/Logo";
import arrow from "../assets/icons/carouselArrow.svg";

const CheckoutHeader = ({backButton, backButtonLink}) => {
    if(typeof backButton === "undefined") {
        backButton = "Voltar para a loja";
    }
    if(typeof backButtonLink === "undefined") {
        backButtonLink = "/loja-de-marketing";
    }

    const Container = styled.header`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 1.5rem;
        background-color: var(--color-xLightGray);
        margin: 0 0 1.5rem;

        @media ${Breakpoints.max.sm} {
            gap: 2rem; 
            
            & > img {
                order: 2;
            }

            & > a {
                order: 1;
            }
        }

        @media ${Breakpoints.sm} {
            justify-content: space-between;
        }
    
        @media ${Breakpoints.md} {
            padding: 2rem 2.5rem;
            border-radius: .5rem;
        }
    `

    const ButtonBack = styled(Link)`
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: .625rem;
        color: var(--black);
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-bold);
        text-decoration: none;

        img {
            width: 1.5rem;
            transform: rotate(180deg);
            transform-origin: center center;
        }

        @media ${Breakpoints.max.xs} {
            span {
                display: none;
            }
        }
    `

    return (
        <Container>
            <LogoImg />
            
            <ButtonBack to={backButtonLink}>
                <img src={arrow} alt="" />
                <span>{backButton}</span>
            </ButtonBack>
        </Container>
    )
}

export default CheckoutHeader;